<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/topic')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Topic</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Topic Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="user_name"
                              class="form-control"
                              placeholder="Topic Name"
                              v-model="detail.topic_name"
                              rules="required:topic name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="user_name"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                          <label for="inputRole">Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="name_role"
                            v-model="detail.type"
                            :validateOnInput="true"
                            rules="required:role"
                          >
                            <option value="">Select Role</option>
                            <option value="DEALS">Deals</option>
                            <option value="INTERESTING_BITES">
                              Intresting Bites & Commentaries
                            </option>
                          </Field>
                          <ErrorMessage name="name_role" class="text-danger" />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "Add Topic",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      roleList: [],
      detail: {},
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/topic/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.getRoleList();
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "null");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/topic",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/topic",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.password = this.password;
      obj.id = this.id;
      this.$api
        .putAPI({
          _action: "/change/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then(() => {
          this.$refs.textModal.closeModal();
        })
        .catch(() => {});
    },
  },
};
</script>
