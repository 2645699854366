<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="row col-lg-6 col-sm-6 my-auto">
              <h4 class="mb-0 lg-bld">My Profile</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save" ref="myProfileForm">
                      <div class="row">
                        <div
                          class="col-lg-12 col-md-12"
                          style="
                            text-align: center;
                            align-items: center;
                            display: block ruby;
                          "
                        >
                          <ImagePicker
                            :src="details.image"
                            :modelValue="details.image"
                            @removeImage="removeImage()"
                            ref="ImagePicker"
                          />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Name"
                              v-model="details.admin_name"
                              rules="required:name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Phone</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="mobile_number"
                              class="form-control"
                              placeholder="Phone no."
                              v-model="details.admin_contact"
                              rules="required:phone number|phone"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="mobile_number"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="details.admin_email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                          </div>
                        </div>
                        <div
                          class="col-lg-12 col-sm-12 mt-2 d-flex justify-content-end"
                        >
                          <button
                            type="button"
                            id="forgot-button"
                            style="width: 161px"
                            class="save-btn"
                            v-on:click="showChangePasswordPopup()"
                          >
                            Change Password
                          </button>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Old Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="_password"
              class="form-control"
              placeholder="Old Password"
              v-model="old_password"
              rules="required:password"
              :validateOnInput="true"
            />
            <ErrorMessage name="_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="name_password"
              class="form-control"
              placeholder="New Password"
              v-model="new_password"
              rules="required:password|minLength:6"
              :validateOnInput="true"
            />
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true"
            />
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="change-password-button"
            style="width: 20%; border-radius: 5px"
          >
            Save
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px"
          >
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal";
import ImagePicker from "@/components/ImagePicker.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    ImagePicker,
    TextModal,
  },
  name: "MyProfile",
  data() {
    return {
      details: {},
      password: "",
      confirm_password: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/users/me",
        })
        .then((res) => {
          this.details = res;
        })
        .catch(() => {});
    },
    removeImage() {
      if (this.details.id) {
        this.$api
          .postAPI({
            _action: "/remove-admin-image/" + this.details.id,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getDetail();
          })
          .catch(() => {});
      } else {
        this.detail.executive_logo = "";
        // // console.log("this.detail.user_images after", index);
      }
    },
    save() {
      this.$api
        .putAPI({
          _action: "/admin",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (!this.$refs.ImagePicker.file) {
            this.$notify({
              type: "success",
              text: res.message,
            });
          } else {
            this.uploadImage(res.id);
          }
        })
        .catch(() => {});
    },

    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/admin/photo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        });
    },

    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      (obj.old_password = this.old_password),
        (obj.new_password = this.new_password);
      this.$api
        .putAPI({
          _action: "/reset/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          localStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
  },
};
</script>
