<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/region')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Region</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Region Name</label>
                            <Field
                              type="text"
                              name="user_name"
                              class="form-control"
                              placeholder="Region Name"
                              disabled
                              v-model="detail.region_name"
                            />
                          </div>
                        </div>
                        <div class="col-12 form-group">
                          <label>Country</label>
                          <Field
                            type="text"
                            name="country_name"
                            placeholder="Select country"
                            v-model="detail.country_ids"
                          >
                            <Multiselect
                              mode="tags"
                              trackBy="country_name"
                              label="country_name"
                              valueProp="id"
                              placeholder="Select Country"
                              v-model="detail.country_ids"
                              :options="countryList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
export default {
  name: "Add Region",
  components: {
    Form,
    Field,
    Multiselect,
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        country: [],
        country_ids: [],
      },
      countryList: [],
      loader: false,
    };
  },
  mounted() {
    this.CountryList();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/region/detail/" + this.id,
        })
        .then((res) => {
          if (res.country.length > 0) {
            var array = [];
            for (var i in res.country) {
              array.push(res.country[i].id);
            }
          }
          res.country_ids = array;
          this.detail = res;

          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/region",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/region",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
  },
};
</script>