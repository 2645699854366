<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Request Demo</h4>
          </div>
          <div class="col-6 text-right"></div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('id', 'id-name')"
                          >
                            Sr No
                          </th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('first_name', 'id-name')"
                          >
                            Name
                          </th>
                          <th
                            class="sorting"
                            id="id-email"
                            v-on:click="sorting('email', 'id-email')"
                          >
                            Email
                          </th>
                          <th
                            class="sorting"
                            id="id-note"
                            v-on:click="sorting('note', 'id-note')"
                          >
                            Note
                          </th>
                          <th
                            class="sorting"
                            id="id-mobile"
                            v-on:click="sorting('request_status', 'id-mobile')"
                          >
                            Request Status
                          </th>
                          <th
                            v-show="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>{{ data.first_name }} {{ data.last_name }}</td>
                          <td>{{ data.email }}</td>
                          <td
                            style="word-wrap: break-word; white-space: normal"
                          >
                            {{ data.note }}
                          </td>
                          <td>
                            {{ data.request_status.replaceAll("_", " ") }}
                          </td>
                          <td
                            v-show="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            <a
                              v-show="
                                accessObject?.is_edit == 1 &&
                                data.request_status != 'PAID'
                              "
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showChangePasswordPopup(data.id)"
                              >Update Status</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Update Status</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="changePasswordForm" @submit="updateStatus">
      <div class="col-lg-12 col-sm-12 mt-3 mb-3">
        <label>Update Status</label>
        <Field
          as="select"
          class="form-control"
          id="inputRole"
          name="industry"
          rules="required: status"
          v-model="detail.status"
        >
          <option value="">Select Status</option>
          <option value="DEMO">Demo</option>
          <option value="INTRESTED">Intrested</option>
          <option value="NOT_INTRESTED">Not Intrested</option>
          <option value="PAID">Paid</option>
        </Field>
        <ErrorMessage name="industry" class="text-danger" />
      </div>
      <div class="col-lg-12 col-sm-12 mt-3 mb-3" v-if="detail.status == 'PAID'">
        <label>Package</label>
        <Field
          as="select"
          class="form-control"
          name="package_id"
          v-model="detail.package_id"
          rules="required: package"
          :validateOnInput="true"
        >
          <option value="">Select package</option>
          <option
            v-for="(data, index) in packageList"
            :key="index"
            :value="data.id"
          >
            {{ data.package_name }}
          </option>
        </Field>
        <ErrorMessage name="package_id" class="text-danger" />
      </div>
      <div class="modal-footer pb-0">
        <button
          class="fill-btn"
          id="update-status-button"
          style="width: 20%; border-radius: 5px"
        >
          Save
        </button>
        <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";

export default {
  name: "Logs",
  components: {
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Demo"),
      loader: true,
      searchObj: {},
      detail: {
        status: "",
      },
      offset: -1,
      list: [],
      packageList: [],
    };
  },
  mounted() {
    this.getList(0);
    this.getPackageList();
  },
  methods: {
    getPackageList() {
      this.$api
        .getAPI({
          _action: "/package/list",
        })
        .then((res) => {
          this.packageList = res;
        })
        .catch(() => {});
    },
    showChangePasswordPopup(id) {
      this.detail.id = id;
      this.detail.status = "";
      this.detail.package_id = "";
      this.$refs.textModal.showModal();
      // this.$refs.updatestatus.resetForm();
    },
    updateStatus() {
      this.$api
        .putAPI({
          _action: "/demo",
          _body: this.detail,
          _buttonId: "update-status-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/demo/request/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          setTimeout(() => {
            this.accessObject =
              this.$helperService.getAccessLevel("Manage Demo");
            console.log("object", this.accessObject);
            this.loader = false;
            this.list = res.list;
            this.offset = res.next_offset;

            if (page == 0) {
              if (this.$refs.listPagination) {
                if (res.count != null && res.count > 0) {
                  this.$refs.listPagination.setTotalCount(res.count);
                } else {
                  this.$refs.listPagination.setTotalCount(0);
                }
              }
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "No data available");
              this.$refs.errorComponent.updateListLoader(false);
            }
          }, 2000);
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Log",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/user/" + detail.uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>
