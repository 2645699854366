<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/deals-transaction')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "Edit" : "Add" }} Deals Transaction
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <!-- <div class="col-lg-4 col-sm-6"> -->
                        <div class="col-8 form-group">
                          <label> News URL </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="source"
                            class="form-control"
                            placeholder="Source"
                            v-model="detail.news_url"
                            rules="required:source|website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="source" class="text-danger" />
                        </div>
                        <div class="col-4" style="margin-top: 39px">
                          <a
                            type="button"
                            class="fill-btn"
                            v-on:click="getTitleFromUrl(detail.news_url)"
                          >
                            Get Title
                          </a>
                        </div>
                        <div class="col-6 form-group">
                          <label>Headline</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="Headline"
                            class="form-control"
                            placeholder="Headline"
                            v-model="detail.headline"
                            rules="required:headline"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Headline" class="text-danger" />
                        </div>
                        <div class="col-6 form-group">
                          <label> Published Date </label>

                          <Field
                            type="text"
                            name="Published"
                            class="form-control"
                            placeholder="Published Date"
                            v-model="detail.published_date"
                            rules="required:published date"
                            :validateOnInput="true"
                          >
                            <Datepicker
                              autoApply
                              v-model="detail.published_date"
                              inputFormat="dd-MM-yyyy"
                              format="dd-MM-yyyy"
                            >
                            </Datepicker>
                          </Field>
                          <ErrorMessage name="Published" class="text-danger" />
                        </div>

                        <div class="col-6 form-group">
                          <label for="inputRole">Primary Company</label>
                          <!-- <span class="text-danger">*</span> -->
                          <Multiselect
                            mode="single"
                            trackBy="company_name"
                            label="company_name"
                            valueProp="id"
                            placeholder="Select Company"
                            v-model="detail.deals_primary_company"
                            :options="companyList"
                            searchable="true"
                            @search-change="searchCompanyName($event)"
                          >
                            <!-- @select="
                              getCompanyType(detail.deals_primary_company)
                            " -->
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.company_logo
                                    ? option.company_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.company_name }}
                            </template>
                          </Multiselect>
                        </div>
                        <div class="col-6 form-group">
                          <label>Deal Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="topic_id"
                            placeholder="Select Topic"
                            :disabled="type == 'VIEW'"
                            rules="required: deal type"
                            v-model="detail.topic_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="topic_name"
                              label="topic_name"
                              valueProp="id"
                              placeholder="Select Topic"
                              :disabled="type == 'VIEW'"
                              v-model="detail.topic_id"
                              :options="topicList"
                              v-bind="field"
                              searchable="true"
                            />
                          </Field>
                          <ErrorMessage name="topic_id" class="text-danger" />
                        </div>
                        <div class="col-6 form-group">
                          <label for="inputRole">Mentioned Company</label>
                          <!-- <span class="text-danger">*</span> -->

                          <Multiselect
                            mode="tags"
                            trackBy="company_name"
                            label="company_name"
                            valueProp="id"
                            placeholder="Select Mentioned Company"
                            object="true"
                            v-model="deals_mention_company"
                            :options="companyList"
                            searchable="true"
                            @search-change="searchCompanyName($event)"
                          >
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.company_logo
                                    ? option.company_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.company_name }}
                            </template>
                          </Multiselect>
                        </div>
                        <div class="col-6 form-group">
                          <label for="inputRole">Mentioned Executive</label>
                          <!-- <span class="text-danger">*</span> -->

                          <Multiselect
                            mode="tags"
                            trackBy="executive_name"
                            label="executive_name"
                            valueProp="id"
                            placeholder="Select Mentioned Executive"
                            v-model="deals_mention_executives"
                            object="true"
                            :options="executiveList"
                            searchable="true"
                            @search-change="
                              searchexecutiveName($event, 'FALSE')
                            "
                          >
                            <template v-slot:option="{ option }">
                              <div class="col-12 row" style="padding: 0">
                                <div class="col-11">
                                  <img
                                    class="option-icon"
                                    :src="
                                      option.executive_logo
                                        ? option.executive_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                  {{ option.executive_name }}
                                </div>
                                <div class="col-1">
                                  <img
                                    class="option-icon2"
                                    :src="
                                      option.new_company &&
                                      option.new_company.company_logo
                                        ? option.new_company.company_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                </div>
                              </div>
                            </template>
                          </Multiselect>
                        </div>
                        <!-- v-if="company_type == 'private'" -->
                        <div class="col-6 form-group">
                          <label for="inputRole">Investor Company</label>
                          <!-- <span class="text-danger">*</span> -->

                          <Multiselect
                            mode="tags"
                            trackBy="company_name"
                            label="company_name"
                            valueProp="id"
                            placeholder="Select Invested Company"
                            object="true"
                            v-model="deals_invested_company"
                            :options="companyList"
                            searchable="true"
                            @search-change="searchCompanyName($event)"
                          >
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.company_logo
                                    ? option.company_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.company_name }}
                            </template>
                          </Multiselect>
                        </div>
                        <!-- v-if="company_type == 'private'" -->
                        <div class="col-6 form-group">
                          <label for="inputRole">Investor Executive</label>
                          <!-- <span class="text-danger">*</span> -->

                          <Multiselect
                            mode="tags"
                            trackBy="executive_name"
                            label="executive_name"
                            valueProp="id"
                            placeholder="Select Invested Executive"
                            v-model="deals_invested_executives"
                            object="true"
                            :options="executiveList"
                            searchable="true"
                            @search-change="
                              searchexecutiveName($event, 'FALSE')
                            "
                          >
                            <template v-slot:option="{ option }">
                              <div class="col-12 row" style="padding: 0">
                                <div class="col-11">
                                  <img
                                    class="option-icon"
                                    :src="
                                      option.executive_logo
                                        ? option.executive_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                  {{ option.executive_name }}
                                </div>
                                <div class="col-1">
                                  <img
                                    class="option-icon2"
                                    :src="
                                      option.new_company &&
                                      option.new_company.company_logo
                                        ? option.new_company.company_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                </div>
                              </div>
                            </template>
                          </Multiselect>
                        </div>
                        <!-- <div class="col-6 form-group">
                          <label>City</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="city_id"
                            placeholder="Select city"
                            rules="required: city"
                            v-model="detail.city_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="city_name"
                              label="city_name"
                              valueProp="id"
                              v-model="detail.city_id"
                              :options="cityList"
                              searchable="true"
                              placeholder="Select City"
                              @search-change="searchCityName($event)"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="city_id" class="text-danger" />
                        </div> -->

                        <!-- <div class="col-6 form-group">
                          <label>Country</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="country_name"
                            placeholder="Select country"
                            rules="required: country"
                            v-model="detail.country_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="country_name"
                              label="country_name"
                              valueProp="id"
                              placeholder="Select Country"
                              v-model="detail.country_id"
                              :options="countryList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage
                            name="country_name"
                            class="text-danger"
                          />
                        </div> -->
                        <div class="col-6 form-group row">
                          <div class="col-8">
                            <label>Publication Name </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="publication_id"
                              placeholder="Select Publication"
                              rules="required: publication"
                              v-model="detail.publication_id"
                              :validateOnInput="true"
                              v-slot="{ field }"
                            >
                              <Multiselect
                                mode="single"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                placeholder="Select Publication"
                                v-model="detail.publication_id"
                                :options="publicationList"
                                searchable="true"
                                @search-change="searchpublicationName($event)"
                                v-bind="field"
                              />
                            </Field>
                            <ErrorMessage
                              name="publication_id"
                              class="text-danger"
                            />
                          </div>
                          <div class="col-4" style="margin-top: 39px">
                            <a
                              type="button"
                              class="fill-btn"
                              v-on:click="showPublicationPopup()"
                            >
                              Add New
                            </a>
                          </div>
                        </div>
                        <div class="col-6 form-group" style="margin-left: 28px">
                          <label> Add Company Logo</label>
                          <div>
                            <input
                              type="checkbox"
                              id="is_company_logo"
                              name="is_company_logo"
                              style="height: 52px; width: 27px"
                              true-value="1"
                              false-value="0"
                              v-model="detail.is_company_logo"
                            />
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Add Publication</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form itemref="addPubliction" @submit="addPubliction()">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label>Publication Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="user_name"
            class="form-control"
            placeholder="Publication Name"
            v-model="detail.name"
            rules="required: publication name"
            :validateOnInput="true"
          />
          <ErrorMessage name="user_name" class="text-danger" />
        </div>
        <div class="col-6 form-group">
          <label>Website Url</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="website_url"
            class="form-control"
            placeholder="Website Url"
            v-model="detail.website_url"
            rules="required: website url |website"
            :validateOnInput="true"
          />
          <ErrorMessage name="website_url" class="text-danger" />
        </div>
        <div class="col-6 mt-3 mb-3">
          <label>Publication Logo</label>
          <div class="text-left">
            <ImagePicker
              :src="detail.publication_logo"
              :modelValue="detail.publication_logo"
              ref="ImagePicker"
            />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button id="publication-button" class="save-btn px-4 ml-3">
            Save
          </button>
          <button
            type="button"
            class="fil-btn px-4 ml-3"
            v-on:click="$refs.textModal.closeModal"
            id="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import TextModal from "@/components/TextModal";
import ImagePicker from "@/components/ImagePicker.vue";

import moment from "moment";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    Datepicker,
    ErrorMessage,
    Multiselect,
    ImagePicker,
    TextModal,
    ErrorComponent,
  },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      detail: {
        is_company_logo: 0,
        deals_primary_company: "",
      },
      companyList: [],
      topicList: [],
      deals_mention_executives: [],
      deals_mention_company: [],
      deals_invested_executives: [],
      deals_invested_company: [],
      countryList: [],
      cityList: [],
      commentType: [],
      commentTypeKeyword: "",
      company_type: "",
      publicationList: [],
      company_ids: [],
      executive_ids: [],
      companyKeyword: "",
      executiveList: [],
      executiveKeyword: "",
      publicationKeyword: "",
    };
  },
  mounted() {
    if (!this.id) {
      this.executivelist("TRUE");
    } else {
      this.executivelist();
    }
    this.companylist();
    this.CountryList();
    this.publicationlist();
    this.gettopiclist();
    this.CityList();
    this.getCommentlist();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    // getCompanyType(id) {
    //   for (var i in this.companyList) {
    //     if (id == this.companyList[i].id) {
    //       if (this.companyList[i].company_type) {
    //         this.company_type = this.companyList[i].company_type;
    //       }
    //       console.log("dsad", this.company_type);
    //     }
    //   }
    // },
    getTitleFromUrl(url) {
      if (url) {
        this.$refs.errorComponent.updateFormLoader(true);
        var obj = {};
        obj.url = url;
        this.$api
          .getAPI({
            _action: "/get/title/url",
            _body: obj,
          })
          .then((res) => {
            this.detail.headline = res.title;
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      } else {
        this.$notify({
          type: "error",
          text: "Please Enter News Url First.",
        });
      }
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchexecutiveName(e, type) {
      this.executiveKeyword = e;
      this.executivelist(type);
    },
    searchpublicationName(e) {
      this.publicationKeyword = e;
      this.publicationlist();
    },
    searchCommrntTypeName(e) {
      this.commentTypeKeyword = e;
      this.getCommentlist();
    },
    getCommentlist() {
      this.$api
        .getAPI({
          _action: "/comment/type/list",
        })
        .then((res) => {
          this.commentType = res;
        })
        .catch(() => {});
    },
    publicationlist() {
      var obj = {};
      if (this.publicationKeyword) {
        obj.keyword = this.publicationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/publications/list",
          _body: obj,
        })
        .then((res) => {
          this.publicationList = res;
        })
        .catch(() => {});
    },
    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/city/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/deals/transacction/detail/" + this.id,
        })
        .then((res) => {
          this.company_ids = [];
          this.detail = res;
          if (this.detail.primary_company) {
            this.detail.deals_primary_company =
              this.detail.primary_company.company_id;
            this.company_ids.push(this.detail.primary_company.company_id);
          }
          if (this.detail.deals_mention_executives.length > 0) {
            this.deals_mention_executives;
            for (var i in this.detail.deals_mention_executives) {
              this.executive_ids.push(
                this.detail.deals_mention_executives[i].executives_id
              );
              this.deals_mention_executives.push({
                id: this.detail.deals_mention_executives[i].executives_id,
              });
            }
          }
          if (this.detail.deals_mention_company.length > 0) {
            this.deals_mention_company;
            for (var k in this.detail.deals_mention_company) {
              this.deals_mention_company.push({
                id: this.detail.deals_mention_company[k].company_id,
              });
              this.company_ids.push(
                this.detail.deals_mention_company[k].company_id
              );
            }
          }

          if (this.detail.deals_invested_company.length > 0) {
            this.deals_invested_company;
            for (var l in this.detail.deals_invested_company) {
              this.deals_invested_company.push({
                id: this.detail.deals_invested_company[l].company_id,
              });
              this.company_ids.push(
                this.detail.deals_invested_company[l].company_id
              );
            }
          }
          if (this.detail.deals_invested_executives.length > 0) {
            this.deals_invested_executives;
            for (var m in this.detail.deals_invested_executives) {
              this.executive_ids.push(
                this.detail.deals_invested_executives[m].executives_id
              );
              this.deals_invested_executives.push({
                id: this.detail.deals_invested_executives[m].executives_id,
              });
            }
          }
          this.searchCompanyName();
          this.searchexecutiveName(null, "TRUE");
        })
        .catch(() => {});
    },
    gettopiclist() {
      this.$api
        .getAPI({
          _action: "/deals-type/list",
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    executivelist(type) {
      if (!type || type == "TRUE") {
        this.$refs.errorComponent.updateFormLoader(true);
      }
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      if (this.executive_ids.length > 0) {
        obj.executive_ids = this.executive_ids;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList = res;

          this.executive_ids = [];
          if (type) {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    companylist() {
      this.companyList = [];
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          this.company_ids = [];
        })
        .catch(() => {});
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.detail.published_date) {
        this.detail.published_date = moment(this.detail.published_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.deals_mention_company.length > 0) {
        this.detail.deals_mention_company = this.deals_mention_company;
      } else {
        this.detail.deals_mention_company = [];
      }
      if (this.deals_mention_executives.length > 0) {
        this.detail.deals_mention_executives = this.deals_mention_executives;
      } else {
        this.detail.deals_mention_executives = [];
      }

      if (this.deals_invested_executives.length > 0) {
        this.detail.deals_invested_executives = this.deals_invested_executives;
      } else {
        this.detail.deals_invested_executives = [];
      }

      if (this.deals_invested_company.length > 0) {
        this.detail.deals_invested_company = this.deals_invested_company;
      } else {
        this.detail.deals_invested_company = [];
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/deals/transacction",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/deals/transacction",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showPublicationPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    addPubliction() {
      this.detail.package_group_type = "web";
      this.$api
        .postAPI({
          _action: "/publications",
          _body: this.detail,
          _buttonId: "publication-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          if (!this.$refs.ImagePicker.file) {
            this.publicationlist();
            this.detail.publication_id = res.id;
            this.$refs.textModal.closeModal();
          } else {
            this.uploadImage(res.id);
          }
        })
        .catch(() => {});
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.publicationlist();
          this.detail.publication_id = res.id;
          this.$refs.textModal.closeModal();
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon2 {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-left: 20px;
}
</style>
