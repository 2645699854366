<template>
  <div>
    <div>
      <div class="page-wrapper">
        <div class="page-content-wrapper">
          <div class="shadow-btm">
            <div class="row">
              <div
                class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
              >
                <a
                  class="pr-2 text-dark cursor-pointer"
                  v-on:click="$router.push('/custom-search-web')"
                  ><span class="font-24 mr-2"
                    ><img src="/images/back-arrow.svg" width="18"
                  /></span>
                </a>
                <h4 class="mb-0 lg-bld">
                  {{ id ? "Edit" : "Add" }} Custom Search Web
                </h4>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="card-body py-0 px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-10 float-none">
                  <div class="card border mb-0 radius-15">
                    <div class="card-body">
                      <Form itemref="adminForm" @submit="save()">
                        <div class="">
                          <div class="col-lg-4 col-sm-6">
                            <div class="form-group" v-if="detail.user_id">
                              <label>User First Name</label>
                              <Field
                                type="text"
                                name="first_name"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.user.first_name"
                              />
                            </div>
                            <div class="form-group" v-else>
                              <label>User First Name</label>
                              <Field
                                type="text"
                                name="first_name"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.first_name"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6">
                            <div class="form-group" v-if="detail.user_id">
                              <label>User Last Name</label>
                              <Field
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.user.last_name"
                              />
                            </div>
                            <div class="form-group" v-else>
                              <label>User Last Name</label>
                              <Field
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.last_name"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                              <label>Company Name</label>
                              <Field
                                type="text"
                                name="company_name"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.company"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                              <label>Experience</label>
                              <Field
                                type="text"
                                name="Experience"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.age_range"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                              <label>Notes</label>
                              <Field
                                type="text"
                                name="notes"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.notes"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                              <label>Requested Date </label>
                              <Field
                                type="text"
                                name="created_date"
                                class="form-control"
                                placeholder="Name"
                                disabled
                                v-model="detail.created_date"
                              />
                            </div>
                          </div>
                          <!-- <div class="col-lg-12 col-sm-12 text-center mt-2">
                            <button id="save-button" class="save-btn px-4 ml-3">
                              Save
                            </button>
                            <button
                              type="button"
                              class="fil-btn px-4 ml-3"
                              v-on:click="$router.go(-1)"
                              id="cancel-button"
                            >
                              Cancel
                            </button>
                          </div> -->
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";

export default {
  name: "Edit Customer",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      id: this.$route.params.id,
      currentTab: "BASIC_INFORMATION",
      detail: {
        user: {},
      },
      list: [],
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "BASIC_INFORMATION") {
        this.getDetail();
      } else {
        this.getList();
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/custom/search/web/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/customer",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/customer",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    getList() {},
  },
};
</script>
