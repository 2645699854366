<template>
  <div>
    <div class="login-bg" style="background: #303030">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-lg-5 col-sm-6 fl-none">
            <div class="posAbs">
              <router-link to="/otp">
                <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
              </router-link>
            </div>
            <!-- <div class="text-center">
              <img src="/static/img/mid-logo.png" alt="" />
            </div> -->
            <div class="text-center">
              <img src="/img/reset-password.png" alt="" />
            </div>
            <div style="margin-bottom: 15px" class="forgot-ps pb-0">
              SET NEW PASSWORD
            </div>

            <div class="enter-your">Please enter new password.</div>
            <Form @submit="verifyPassword()">
              <div class="form-group">
                <Field
                  class="inputfield"
                  placeholder="New Password"
                  type="password"
                  name="name_password"
                   v-model="password"
                  rules="required:password|minLength:6"
                  :validateOnInput="true"
                />
                <ErrorMessage name="name_password" class="text-danger" />
              </div>
              <div class="form-group">
                <Field
                  type="password"
                  class="inputfield"
                  placeholder="Confirm Password"
                  name="name_ccpassword"
                  rules="required:confirm password|confirmed:@name_password"
                  :validateOnInput="true"
                />
                <ErrorMessage name="name_ccpassword" class="text-danger" />
              </div>
              <div class="text-center mt-4">
                <button
                  class="send-btn"
                  id="set-password-button"
                >
                  Verify
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import {
        Form,
        Field,
        ErrorMessage
    } from "vee-validate";
    export default {
        name: "ResetPassword",
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data() {
            return {
                password: "",
            };
        },
        mounted() {},
        methods: {
            verifyPassword() {
                var obj = {};
                obj.email = localStorage.getItem("email");
                obj.token = localStorage.getItem("token");
                obj.password = this.password;
                this.$api
                    .putAPI({
                        _action: "/set/password",
                        _body: obj,
                        _buttonId: "set-password-button",
                    })
                    .then((res) => {
                        localStorage.clear()
                        this.$notify({
                            type: "success",
                            text: res.message,
                        });
                        this.$router.push("/login");
                    })
                    .catch(() => {});
            },
        },
    };
</script>