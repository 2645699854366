<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/admin')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Admin</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save()">
                      <div class="col-12 row">
                        <div
                          class="col-lg-12 col-md-12"
                          style="
                            text-align: center;
                            align-items: center;
                            display: block ruby;
                          "
                        >
                          <ImagePicker
                            :src="detail.image"
                            :modelValue="detail.image"
                            ref="ImagePicker"
                          />
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <label for="inputRole">Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputRole"
                            name="name_role"
                            v-model="detail.admin_type"
                            :validateOnInput="true"
                            rules="required:role"
                          >
                            <option value="">Select Role</option>
                            <option value="owner">Owner</option>
                            <option value="admin">Admin</option>
                            <option value="quality_analyst">
                              Quality Analyst
                            </option>
                            <option value="data_entry_analyst">Analyst</option>
                          </Field>
                          <ErrorMessage name="name_role" class="text-danger" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Admin Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="user_name"
                              class="form-control"
                              placeholder="Name"
                              v-model="detail.admin_name"
                              rules="required:name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="user_name"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Admin Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="detail.admin_email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                            <a
                              v-if="id"
                              href="#"
                              v-on:click="showChangePasswordPopup()"
                              >Change Password</a
                            >
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Admin contact no.</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="mobile_number"
                              class="form-control"
                              placeholder="Mobile no"
                              v-model="detail.admin_contact"
                              rules="required:contact number|phone"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="mobile_number"
                              class="text-danger"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Password </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="password"
                              name="password"
                              class="form-control"
                              placeholder="Password"
                              v-model="detail.admin_password"
                              rules="required:password|minLength:6"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="password" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="password"
                              placeholder="Confirm Password"
                              name="name_ccpassword"
                              class="form-control"
                              rules="required:confirm password|confirmed:@password"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="name_ccpassword"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-12">
                          <label for="inputRole"> Access</label>
                          <span class="text-danger">*</span>

                          <Field
                            type="text"
                            name="access"
                            placeholder="Select Access"
                            rules="required: access"
                            v-model="detail.access"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="tags"
                              trackBy="name"
                              label="name"
                              valueProp="name"
                              placeholder="Select Access"
                              v-model="detail.access"
                              :options="access"
                              searchable="true"
                              object="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="access" class="text-danger" />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Change Password</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="changePasswordForm" @submit="changePassword">
      <div class="col-lg-12 col-sm-12">
        <div class="form-group">
          <label>Password</label>
          <span class="text-danger">*</span>
          <Field
            type="password"
            name="_password"
            class="form-control"
            placeholder="Password"
            v-model="passwordObj.password"
            rules="required:password|minLength:6"
            :validateOnInput="true"
          />
          <ErrorMessage name="_password" class="text-danger" />
        </div>
      </div>
      <!-- <div class="col-lg-12 col-sm-12">
        <div class="form-group">
          <label>New Password</label>
          <span class="text-danger">*</span>
          <Field
            type="password"
            name="name_password"
            class="form-control"
            placeholder="New Password"
            v-model="new_password"
            rules="required:password|minLength:6"
            :validateOnInput="true"
          />
          <ErrorMessage name="name_password" class="text-danger" />
        </div>
      </div> -->
      <div class="col-lg-12 col-sm-12">
        <div class="form-group">
          <label>Confirm Password</label>
          <span class="text-danger">*</span>
          <Field
            type="password"
            placeholder="Confirm Password"
            name="name_ccpassword"
            class="form-control"
            rules="required:confirm password|confirmed:@_password"
            :validateOnInput="true"
          />
          <ErrorMessage name="name_ccpassword" class="text-danger" />
        </div>
      </div>
      <div class="modal-footer pb-0">
        <button
          class="fill-btn"
          id="change-password-button"
          style="width: 20%; border-radius: 5px"
        >
          Save
        </button>
        <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import Multiselect from "@vueform/multiselect";
import ImagePicker from "@/components/ImagePicker.vue";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    Multiselect,
    ErrorMessage,
    TextModal,
    ImagePicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        role_id: "",
        access: [],
      },
      access: [
        {
          name: "Manage Admins",
          url: "admin",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Roles",
          url: "role",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Company",
          url: "company",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Executive",
          url: "executive",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Executive Adv.",
          url: "executives",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage News",
          url: "news",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Job Title",
          url: "news",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Custom Search App",
          url: "custom-search-app",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Industry",
          url: "industry",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Publication",
          url: "publication",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage University",
          url: "university",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Intresting Bites",
          url: "intresting-bites",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Deals & Transaction",
          url: "deals-transaction",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Package",
          url: "package",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },

        // {
        //   name: "Manage rss",
        //   url: "rss",
        //   is_all: 0,
        //   is_view: 0,
        //   is_add: 0,
        //   is_edit: 0,
        //   is_delete: 0,
        //   is_publish: 0,
        // },
        {
          name: "Manage Customer",
          url: "customer",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Cluster",
          url: "cluster",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Commentry",
          url: "commentry",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Function",
          url: "function",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        // {
        //   name: "Manage Cities",
        //   url: "cities",
        //   is_all: 0,
        //   is_view: 0,
        //   is_add: 0,
        //   is_edit: 0,
        //   is_delete: 0,
        //   is_publish: 0,
        // },
          {
          name: "Manage Countries",
          url: "countries",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Topic",
          url: "topic",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Package Group",
          url: "package-group",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Custom Search Web",
          url: "custom-search-web",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Logs",
          url: "logs",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Settings",
          url: "settings",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Region",
          url: "region",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage QA",
          url: "qa",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
            name: "Manage Demo",
            url: "demo",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
        },
        {
          name: "Manage Open Position",
          url: "open-position",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Deal Stages",
          url: "funding_series",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
      ],
      password: "",
      confirm_password: "",
      passwordObj: {},
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    } else {
      this.getRoleList();
    }
  },
  methods: {
    getRoleList() {
      this.$api
        .getAPI({
          _action: "/role/list",
        })
        .then((res) => {
          this.roleList = res;
          if (this.detail.role_id != null) {
            this.role_id = this.detail.role_id;
          }
        })
        .catch(() => {});
    },
    removeImage() {
      if (this.id) {
        this.$api
          .postAPI({
            _action: "/remove-admin-image/" + this.id,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getDetail();
          })
          .catch(() => {});
      } else {
        this.detail.executive_logo = "";
        // // console.log("this.detail.user_images after", index);
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/admin/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.getRoleList();
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.detail.admin_password = "";
        this.$api
          .putAPI({
            _action: "/admin",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.uploadImage(res.id, this.$refs.ImagePicker.file);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/admin",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.uploadImage(res.id, this.$refs.ImagePicker.file);
          })
          .catch(() => {});
      }
    },
    uploadImage(id, imageFile) {
      if (imageFile) {
        this.$api
          .uploadImageAPI({
            _action: "/admin/photo",
            _key: "image",
            _file: imageFile,
            _body: {
              id: id,
            },
          })
          .then(() => {
            this.$router.go(-1);
          });
      } else {
        this.$router.go(-1);
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      this.$api
        .putAPI({
          _action: "/change/password/" + this.id,
          _body: this.passwordObj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          console.log();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.textModal.closeModal();
        })
        .catch(() => {});
    },
  },
};
</script>