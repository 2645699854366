<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/cities')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Topic</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>City Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="city_name"
                              disabled
                              class="form-control"
                              placeholder="City Name"
                              v-model="detail.city_name"
                            />
                          </div>
                          <div class="form-group">
                            <label>City</label>
                            <span class="text-danger">*</span>
                            <Multiselect
                              mode="tags"
                              trackBy="city_name"
                              label="city_name"
                              valueProp="id"
                              v-model="detail.merge_city_id"
                              :options="cityList"
                              searchable="true"
                              placeholder="Select City"
                              @search-change="searchCityName($event)"
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
export default {
  name: "Merge Cities",
  components: {
    Form,
    Field,
    Multiselect,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {},
      cityList: [],
      cityKeyword: "",
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
      this.CityList();
    }
  },
  methods: {
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/city/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/city/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    save() {
      this.detail.id = this.id;
      this.$api
        .putAPI({
          _action: "/merge/city",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>