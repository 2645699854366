<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/job-title')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Job Title</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="Title"
                              class="form-control"
                              placeholder="Title"
                              v-model="detail.title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="Title" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <label>Job Function </label>
                          <!-- <span class="text-danger">*</span> -->
                          <Field
                            type="text"
                            placeholder="Select"
                            name="job_function_id"
                            v-model="detail.job_function_id"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="job_function_name"
                              label="job_function_name"
                              valueProp="id"
                              placeholder="Select"
                              v-model="detail.job_function_id"
                              :options="jobFunctionList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <!-- <ErrorMessage
                            name="job_function_id"
                            class="text-danger"
                          /> -->
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <label>Seniority Level</label>
                          <span class="text-danger">*</span>

                          <Field
                            type="text"
                            name="Seniority"
                            placeholder="Select"
                            v-model="detail.seniority_level_id"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="seniority_name"
                              label="seniority_name"
                              valueProp="id"
                              placeholder="Select"
                              v-model="detail.seniority_level_id"
                              :options="senioritylevelList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <!-- <ErrorMessage name="Seniority" class="text-danger" /> -->
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      senioritylevelList: [],
      jobFunctionList: [],
      loader: false,
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
    this.jobFunctionlist();
    this.seniorityList();
  },
  methods: {
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/job/title/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, " ");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    seniorityList() {
      this.$api
        .getAPI({
          _action: "/seniority/list",
        })
        .then((res) => {
          this.senioritylevelList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/job/title",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/job/title",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>