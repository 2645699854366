<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/commentry')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld" v-if="!type">
                {{ id ? "Edit" : "Add" }} Commentary
              </h4>
              <h4 class="mb-0 lg-bld" v-if="type">View Commentary</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <!-- <div class="col-lg-4 col-sm-6"> -->
                        <div class="col-6 form-group">
                          <label> News URL </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="source"
                            class="form-control"
                            :disabled="type == 'VIEW'"
                            placeholder="Source"
                            v-model="detail.source"
                            rules="required:source|website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="source" class="text-danger" />
                        </div>
                        <!-- <div class="col-4" style="margin-top: 39px">
                          <a
                            type="button"
                            class="fill-btn"
                            v-on:click="getTitleFromUrl(detail.source)"
                          >
                            Get Title
                          </a>
                        </div> -->
                        <!-- <div class="col-6 form-group">
                          <label>Headline</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="Headline"
                            class="form-control"
                            placeholder="Headline"
                            v-model="detail.headline"
                            rules="required:headline"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Headline" class="text-danger" />
                        </div> -->
                        <div class="col-6 form-group">
                          <label> Published Date </label>

                          <Field
                            type="text"
                            name="Published"
                            class="form-control"
                            placeholder="Published Date"
                            :disabled="type == 'VIEW'"
                            v-model="detail.published_date"
                            rules="required:published date"
                            :validateOnInput="true"
                          >
                            <Datepicker
                              autoApply
                              :disabled="type == 'VIEW'"
                              v-model="detail.published_date"
                              inputFormat="dd-MM-yyyy"
                              format="dd-MM-yyyy"
                            >
                            </Datepicker>
                          </Field>
                          <ErrorMessage name="Published" class="text-danger" />
                        </div>

                        <!-- <div class="col-6 form-group">
                          <label> Job Title </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="job_title"
                            class="form-control"
                            placeholder="job_title"
                            :disabled="type == 'VIEW'"
                            v-model="detail.job_title"
                            rules="required:source"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="job_title" class="text-danger" />
                        </div>     -->
                        <div class="col-6 form-group">
                          <label for="inputRole1">Comment Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="topic_id"
                            placeholder="Select Topic"
                            :disabled="type == 'VIEW'"
                            rules="required: comment type"
                            v-model="detail.topic_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="topic_name"
                              label="topic_name"
                              valueProp="id"
                              placeholder="Select Topic"
                              :disabled="type == 'VIEW'"
                              v-model="detail.topic_id"
                              :options="commentType"
                              v-bind="field"
                              searchable="true"
                            />
                          </Field>
                          <ErrorMessage name="topic_id" class="text-danger" />
                        </div>

                        <!-- <div class="col-6 form-group">
                          <label>Comment Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="comment_type"
                            placeholder="Select Comment Type"
                            rules="required: comment type"
                            v-model="detail.comment_type"
                            :disabled="type == 'VIEW'"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="topic_name"
                              label="topic_name"
                              valueProp="type"
                              placeholder="Select Comment Type"
                              v-model="detail.comment_type"
                              :disabled="type == 'VIEW'"
                              :options="commentType"
                              searchable="true"
                              v-bind="field"
                              @search-change="searchCommrntTypeName($event)"
                            />
                          </Field>
                          <ErrorMessage
                            name="comment_type"
                            class="text-danger"
                          />
                        </div> -->
                        <div class="col-6 form-group">
                          <label>Executive Name </label>
                          <span class="text-danger">*</span>

                          <Field
                            type="text"
                            name="executive_name"
                            placeholder="Select Executive Name"
                            :disabled="type == 'VIEW'"
                            rules="required: executive name"
                            v-model="detail.executive_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="executive_name"
                              label="name"
                              :disabled="type == 'VIEW'"
                              valueProp="id"
                              placeholder="Select Executive Name"
                              v-model="detail.executive_id"
                              :options="executiveList"
                              searchable="true"
                              v-on:change="geteExecutivedetail($event)"
                              @search-change="
                                searchexecutiveName($event, 'FALSE')
                              "
                              v-bind="field"
                            >
                              <template v-slot:option="{ option }">
                                <div class="col-12 row" style="padding: 0">
                                  <div class="col-11">
                                    <img
                                      class="option-icon"
                                      :src="
                                        option.executive_logo
                                          ? option.executive_logo
                                          : '/img/user-pic.png'
                                      "
                                    />
                                    {{ option.executive_name }}
                                  </div>
                                  <div class="col-1">
                                    <img
                                      class="option-icon2"
                                      :src="
                                        option.new_company &&
                                        option.new_company.company_logo
                                          ? option.new_company.company_logo
                                          : '/img/user-pic.png'
                                      "
                                    />
                                  </div>
                                </div>
                              </template>
                            </Multiselect>
                          </Field>
                          <ErrorMessage
                            name="executive_name"
                            class="text-danger"
                          />
                        </div>

                        <!-- <div class="col-6 form-group">
                          <label for="inputRole">Company Name</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="company_name"
                            :disabled="type == 'VIEW'"
                            placeholder="Select Previous Company"
                            v-model="detail.company_id"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="id"
                              label="company_name"
                              valueProp="id"
                              placeholder="Select Company"
                              v-model="detail.company_id"
                              disabled
                              :options="companyList"
                              searchable="true"
                              v-bind="field"
                              @search-change="searchCompanyName($event)"
                            >
                              <template v-slot:option="{ option }">
                                <img
                                  class="option-icon"
                                  :src="
                                    option.company_logo
                                      ? option.company_logo
                                      : '/img/user-pic.png'
                                  "
                                />
                                {{ option.company_name }}
                              </template>
                            </Multiselect>
                          </Field>
                          <ErrorMessage
                            name="company_name"
                            class="text-danger"
                          />
                        </div> -->
                        <div class="col-4 form-group">
                          <label>Publication Name </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="publication_id"
                            :disabled="type == 'VIEW'"
                            placeholder="Select Publication"
                            rules="required: publication"
                            v-model="detail.publication_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="name"
                              label="name"
                              valueProp="id"
                              placeholder="Select Publication"
                              :disabled="type == 'VIEW'"
                              v-model="detail.publication_id"
                              :options="publicationList"
                              searchable="true"
                              @search-change="searchpublicationName($event)"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage
                            name="publication_id"
                            class="text-danger"
                          />
                        </div>
                        <div class="col-2" v-show="!type">
                          <label></label>
                          <button
                            type="button"
                            style="margin-top: 37px"
                            class="save-btn px-4 ml-3"
                            v-on:click="showPublicationPopup()"
                          >
                            Add New
                          </button>
                        </div>
                        <div class="col-2">
                          <span> Add Company Logo</span>
                          <div>
                            <input
                              type="checkbox"
                              id="is_company_logo"
                              name="is_company_logo"
                              :disabled="type == 'VIEW'"
                              style="height: 52px; width: 27px"
                              true-value="1"
                              false-value="0"
                              v-model="detail.is_company_logo"
                            />
                          </div>
                        </div>
                        <div class="col-3">
                          <span> Publish</span>
                          <div>
                            <input
                              type="checkbox"
                              id="publish"
                              name="publish"
                              :disabled="type == 'VIEW'"
                              style="height: 52px; width: 27px"
                              true-value="Y"
                              false-value="N"
                              v-model="detail.publish"
                            />
                          </div>
                        </div>
                        <!-- </div> -->
                        <div class="col-12 form-group">
                          <label>Comment</label>
                          <!-- <span class="text-danger">*</span> -->
                          <textarea
                            type="text"
                            name="Comment"
                            class="form-control"
                            placeholder="Comment"
                            :disabled="type == 'VIEW'"
                            v-model="detail.comment"
                            rules="required:comment"
                            :validateOnInput="true"
                          >
                          </textarea>
                          <ErrorMessage name="Comment" class="text-danger" />
                        </div>
                        <div
                          class="col-lg-12 col-sm-12 text-center mt-2"
                          v-show="!type"
                        >
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Add Publication</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form itemref="addPubliction" @submit="addPubliction()">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label>Publication Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="user_name"
            class="form-control"
            placeholder="Publication Name"
            v-model="detail.name"
            rules="required: publication name"
            :validateOnInput="true"
          />
          <ErrorMessage name="user_name" class="text-danger" />
        </div>
        <div class="col-6 form-group">
          <label>Website Url</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="website_url"
            class="form-control"
            placeholder="Website Url"
            v-model="detail.website_url"
            rules="required: website url |website"
            :validateOnInput="true"
          />
          <ErrorMessage name="website_url" class="text-danger" />
        </div>
        <div class="col-6 mt-3 mb-3">
          <label>Publication Logo</label>
          <div class="text-left">
            <ImagePicker
              :src="detail.publication_logo"
              :modelValue="detail.publication_logo"
              ref="ImagePicker"
            />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button id="publication-button" class="save-btn px-4 ml-3">Save</button>
          <button
            type="button"
            class="fil-btn px-4 ml-3"
            v-on:click="$refs.textModal.closeModal"
            id="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import ImagePicker from "@/components/ImagePicker.vue";
import TextModal from "@/components/TextModal";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    Datepicker,
    ImagePicker,
    ErrorMessage,
    Multiselect,
    ErrorComponent,
    TextModal,
  },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      type: this.$route.params.type,
      detail: {
        company_id: "",
        is_company_logo: 0,
      },
      companyList: [],
      company_ids: [],
      executive_ids: [],
      topicList: [],
      executiveList: [],
      publicationList: [],
      commentType: [],
      companyKeyword: "",
      commentTypeKeyword: "",
      executiveKeyword: "",
      publicationKeyword: "",
    };
  },
  mounted() {
    if (!this.id) {
      this.getexecutivelist("TRUE");
    } else {
      this.getexecutivelist();
    }
    this.companylist();
    this.publicationlist();
    this.topiclist();
    this.getCommentlist();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getTitleFromUrl(url) {
      if (url) {
        this.$refs.errorComponent.updateFormLoader(true);
        var obj = {};
        obj.url = url;
        this.$api
          .getAPI({
            _action: "/get/title/url",
            _body: obj,
          })
          .then((res) => {
            this.detail.headline = res.title;
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      } else {
        this.$notify({
          type: "error",
          text: "Please Enter News Url First.",
        });
      }
    },
    searchCommrntTypeName(e) {
      this.commentTypeKeyword = e;
      this.getCommentlist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchexecutiveName(e, type) {
      this.executiveKeyword = e;
      this.getexecutivelist(type);
    },
    searchpublicationName(e) {
      this.publicationKeyword = e;
      this.publicationlist();
    },
    geteExecutivedetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/executive/detail/" + id,
          })

          .then((res) => {
            this.detail.company_id = res.new_company_id;
          })
          .catch(() => {});
      }
    },

    getCommentlist() {
      this.$api
        .getAPI({
          _action: "/comment/type/list",
        })
        .then((res) => {
          this.commentType = res;
        })
        .catch(() => {});
    },
    publicationlist() {
      var obj = {};
      if (this.publicationKeyword) {
        obj.keyword = this.publicationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/publications/list",
          _body: obj,
        })
        .then((res) => {
          this.publicationList = res;
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/commentary/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.company_ids.push(this.detail.company_id);
          this.executive_ids.push(this.detail.executive_id);
          this.searchCompanyName();
          this.searchexecutiveName(null, "TRUE");
        })
        .catch(() => {});
    },
    topiclist() {
      this.$api
        .getAPI({
          _action: "/topic/list",
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    getexecutivelist(type) {
      if (!type || type == "TRUE") {
        this.$refs.errorComponent.updateFormLoader(true);
      }
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      if (this.executive_ids.length > 0) {
        obj.executive_ids = this.executive_ids;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList = res;
          this.executive_ids = [];
          for (var i in this.executiveList) {
            if (this.executiveList[i].executive_name) {
              this.executiveList[i].name = this.executiveList[i].executive_name ;
            }
            if (this.executiveList[i].new_job_title) {
              this.executiveList[i].name +=
                
                this.$helperService.isStringOnlyAlphabet(
                  this.executiveList[i].new_job_title_id
                )
                  ?  ", " + this.executiveList[i].new_job_title_id
                  : this.executiveList[i].new_job_title
                  ?  ", " + this.executiveList[i].new_job_title.title
                  : "";
            }
            if (this.executiveList[i].new_company) {
              this.executiveList[i].name +=
                ", " + this.executiveList[i].new_company.company_name;
            }
          }
          if (type) {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          this.company_ids = [];
        })
        .catch(() => {});
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.detail.published_date) {
        this.detail.published_date = moment(this.detail.published_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/commentary",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/commentary",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },

    showPublicationPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    addPubliction() {
      this.detail.package_group_type = "web";
      this.$api
        .postAPI({
          _action: "/publications",
          _body: this.detail,
          _buttonId: "publication-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          if (!this.$refs.ImagePicker.file) {
            this.publicationlist();
            this.detail.publication_id = res.id;
            this.$refs.textModal.closeModal();
          } else {
            this.uploadImage(res.id);
          }
        })
        .catch(() => {});
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.publicationlist();
          this.detail.publication_id = res.id;
          this.$refs.textModal.closeModal();
        });
    },
    onChange(e) {
      let files = e.target.files;

      let reader = new FileReader();

      reader.readAsDataURL(files[0]);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          var width = img.width;
          var height = img.height;
          if (width > 500 || height > 500) {
            alert("Image should be of 500 x 500 Pixcel");
            return;
          } else {
            if (
              files[0].type.toLowerCase() != "image/jpeg" &&
              files[0].type.toLowerCase() != "image/png"
            ) {
              alert("Invalid file formate, please use jpeg or png file.");
              return;
            }
            // const size = (files[0].size / 1024 / 1024).toFixed(2);
            // if (size > 10) {
            //   alert("File must be less then 10 MB");
            //   return;
            // }
            var tmppath = URL.createObjectURL(files[0]);
            this.file = files[0];
            document.getElementById("preview").src = tmppath;
          }
        };

        img.src = evt.target.result;
      };
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
.option-icon2 {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-left: 20px;
}
.form-control:disabled,
.form-control[readonly] {
  opacity: 1 !important;
  background-color: #fff !important;
}
</style>