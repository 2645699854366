<template>
  <div class="row" style="margin: 0px">
    <div
      class="spx-country-select col-3"
      style="border-radius: 5px 0 0 5px"
      :class="{ disabled: disabled }"
    >
      <div
        :id="id"
        class="dropdown"
        @click="toggleDropdown"
        v-click-outside="clickedOutside"
        :class="{ open: open }"
        @keydown="keyboardNav"
        tabindex="0"
        @keydown.esc="reset"
      >
        <span class="current">
          <div
            v-if="enabledFlags"
            class="vti__flag"
            :class="activeCountry.iso2.toLowerCase()"
          ></div>
          <span v-if="enabledCountryCode" class="country-code"
            >+{{ activeCountry.dialCode }}</span
          >
          <span class="dropdown-arrow">{{ open ? "▲" : "▼" }}</span>
        </span>
        <ul v-show="open" ref="list" class="dropdown-list">
          <li
            class="dropdown-item"
            v-for="(pb, index) in sortedCountries"
            :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
            @click="choose(pb)"
            :class="getItemClass(index, pb.iso2)"
            @mousemove="selectedIndex = index"
          >
            <div
              class="vti__flag"
              v-if="enabledFlags"
              :class="pb.iso2.toLowerCase()"
            ></div>
            <strong>{{ pb.name }}</strong>
            <span>+{{ pb.dialCode }}</span>
          </li>
        </ul>
      </div>
    </div>
    <input
      type="text"
      name="phone"
      v-model="mobileNumber"
      class="form-control col-9"
 style="margin-left: -1px; border-radius: 0px 5px 5px 0px;height: 50px;"
      :placeholder="placeholder"
      v-on:change=" this.$emit('update:modelValue', mobileNumber);"
      @keypress="$helperService.allowOnlyNumericValue($event)"
    />
  </div>
</template>
<script>
import allCountries from "../PhoneCodePicker/AllCountry";
import getCountry from "../PhoneCodePicker/DefaultCountry";

export default {
  name: "PhoneCode",
  props: {
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    disabledFetchingCountry: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      default: "IN",
    },
    enabledCountryCode: {
      type: Boolean,
      default: true,
    },
    enabledFlags: {
      type: Boolean,
      default: true,
    },
    preferredCountries: {
      type: Array,
      default: () => [],
    },
    onlyCountries: {
      type: Array,
      default: () => [],
    },
    ignoredCountries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeCountry: { iso2: "" },
      open: false,
      manualTrigger: false,
      selectedIndex: null,
      typeToFindInput: "",
      typeToFindTimer: null,
      mobileNumber: this.modelValue,
    };
  },
  mounted() {
    this.initializeCountry();
  },
  computed: {
    filteredCountries() {
      // List countries after filtered
      if (this.onlyCountries.length) {
        return this.getCountries(this.onlyCountries);
      }
      if (this.ignoredCountries.length) {
        return allCountries.filter(
          ({ iso2 }) =>
            !this.ignoredCountries.includes(iso2.toUpperCase()) &&
            !this.ignoredCountries.includes(iso2.toLowerCase())
        );
      }
      return allCountries;
    },
    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries).map(
        (country) => ({ ...country, preferred: true })
      );
      return [...preferredCountries, ...this.filteredCountries];
    },
  },
  watch: {
    countryCode(newVal) {
      if (newVal) {
        const countryCode = this.findCountry(newVal);
        this.choose(countryCode);
      }
    },
    modelValue() {
      this.mobileNumber = this.modelValue;
    },
  },
  methods: {
    initializeCountry() {
      if (this.countryCode) {
        const defaultCountry = this.findCountry(this.countryCode);
        if (defaultCountry) {
          this.choose(defaultCountry);
          return;
        }
      }

      this.activeCountry =
        this.findCountry(this.preferredCountries[0]) ||
        this.filteredCountries[0];
      if (!this.disabledFetchingCountry) {
        getCountry().then((res) => {
          this.choose(this.findCountry(res) || this.activeCountry);
        });
      }
    },
    getCountries(list = []) {
      return list
        .map((countryCode) => this.findCountry(countryCode))
        .filter(Boolean);
    },
    findCountry(iso = "") {
      return allCountries.find((country) => country.iso2 === iso.toUpperCase());
    },
    getItemClass(index, iso2) {
      const highlighted = this.selectedIndex === index;
      const lastPreferred = index === this.preferredCountries.length - 1;
      const preferred = !!~this.preferredCountries
        .map((c) => c.toUpperCase())
        .indexOf(iso2);
      return {
        highlighted,
        "last-preferred": lastPreferred,
        preferred,
      };
    },
    choose(country) {
      this.activeCountry = country;
      this.$emit("update:countryCode", this.activeCountry.iso2.toLowerCase());
      this.$emit("update:dialCode", "+" + this.activeCountry.dialCode);
    },
    toggleDropdown() {
      if (this.disabled) {
        return;
      }
      this.open = !this.open;
    },
    manualDropdown() {
      if (this.disabled) {
        return;
      }
      this.manualTrigger = true;
      this.open = true;
    },
    clickedOutside() {
      if (this.manualTrigger) {
        this.manualTrigger = false;
        return;
      }
      this.open = false;
    },
    keyboardNav(e) {
      if (e.keyCode === 40) {
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(
            this.sortedCountries.length - 1,
            this.selectedIndex + 1
          );
        }
        let selEle = this.$refs.list.children[this.selectedIndex];
        if (
          selEle.offsetTop + selEle.clientHeight >
          this.$refs.list.scrollTop + this.$refs.list.clientHeight
        )
          this.$refs.list.scrollTop =
            selEle.offsetTop -
            this.$refs.list.clientHeight +
            selEle.clientHeight;
      } else if (e.keyCode === 38) {
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.sortedCountries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        let selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop < this.$refs.list.scrollTop)
          this.$refs.list.scrollTop = selEle.offsetTop;
      } else if (e.keyCode === 13) {
        if (this.selectedIndex !== null) {
          this.choose(this.sortedCountries[this.selectedIndex]);
        }
        this.open = !this.open;
      } else {
        this.typeToFindInput += e.key;
        clearTimeout(this.typeToFindTimer);
        this.typeToFindTimer = setTimeout(() => {
          this.typeToFindInput = "";
        }, 700);
        let typedCountryI = this.sortedCountries
          .slice(this.preferredCountries.length)
          .findIndex((c) =>
            c.name.toLowerCase().startsWith(this.typeToFindInput)
          );
        if (~typedCountryI) {
          this.selectedIndex = this.preferredCountries.length + typedCountryI;
          let selEle = this.$refs.list.children[this.selectedIndex];
          if (
            selEle.offsetTop < this.$refs.list.scrollTop ||
            selEle.offsetTop + selEle.clientHeight >
              this.$refs.list.scrollTop + this.$refs.list.clientHeight
          ) {
            this.$refs.list.scrollTop =
              selEle.offsetTop - this.$refs.list.clientHeight / 2;
          }
        }
      }
    },
    reset() {
      this.selectedIndex = this.sortedCountries
        .map((c) => c.iso2)
        .indexOf(this.activeCountry.iso2);
      this.open = false;
    },
  },
};
</script>

<style src="../PhoneCodePicker/phone.css">
</style>