<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/open-position')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "Edit" : "Add" }} Open Position
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="Add()">
                      <div class="col-12 row">
                        <div class="col-8 form-group">
                          <label> Job Title </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="title"
                            class="form-control"
                            placeholder="Title"
                            v-model="detail.title"
                            rules="required:title"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="title" class="text-danger" />
                        </div>

                        <div class="col-8 form-group">
                          <label>Company Name</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="new_company_id"
                            placeholder="Select Company"
                            rules="required: company"
                            v-model="detail.new_company_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="company_name"
                              label="company_name"
                              valueProp="id"
                              placeholder="Select Company"
                              v-model="detail.new_company_id"
                              :options="companyList"
                              searchable="true"
                              v-bind="field"
                              v-on:change="companyDetail(detail.new_company_id)"
                              @search-change="
                                searchCompanyName($event, 'FALSE')
                              "
                            >
                              <template v-slot:option="{ option }">
                                <img
                                  class="option-icon"
                                  :src="
                                    option.company_logo
                                      ? option.company_logo
                                      : '/img/user-pic.png'
                                  "
                                />
                                {{ option.company_name }}
                              </template>
                            </Multiselect>
                          </Field>
                          <ErrorMessage
                            name="new_company_id"
                            class="text-danger"
                          />
                        </div>
                        <div
                          class="col-4 form-group"
                          v-show="!loader"
                          style="
                            background: white;
                            border-radius: 0 0 16px 16px;
                          "
                        >
                          <img
                            src="/img/loader.gif"
                            style="
                              padding-top: 40px;
                              width: 36px;
                              padding-left: 1px;
                            "
                          />
                        </div>
                        <div class="col-5 form-group">
                          <label> Company Type </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="type"
                            class="form-control"
                            placeholder="Company Type"
                            v-model="this.detail.companyDetails.company_type"
                            style="background-color: light; cursor: not-allowed"
                            disabled
                          >
                          </Field>
                          <ErrorMessage name="type  " class="text-danger" />
                        </div>

                        <div class="col-4 form-group">
                          <label for="inputRole">Company Size</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="size"
                            class="form-control"
                            placeholder="Company Size"
                            v-model="this.detail.companyDetails.company_size"
                            style="background-color: light; cursor: not-allowed"
                            disabled
                          >
                          </Field>
                          <ErrorMessage name="size  " class="text-danger" />
                        </div>
                        <div class="col-3 form-group">
                          <label for="inputRole"> Industry</label>
                          <span class="text-danger">*</span>

                          <Field
                            type="text"
                            name="industry"
                            class="form-control"
                            placeholder="Industry"
                            v-model="
                              this.detail.companyDetails.industry.industry_name
                            "
                            style="background-color: light; cursor: not-allowed"
                            disabled
                          >
                          </Field>
                          <ErrorMessage name="industry  " class="text-danger" />
                        </div>
                        <div class="col-4 form-group">
                          <label>Seniority</label>
                          <span class="text-danger">*</span>

                          <Field
                            type="text"
                            name="Seniority"
                            placeholder="Select Seniority"
                            rules="required:seniority"
                            :validateOnInput="true"
                            v-model="detail.seniority_id"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="seniority_name"
                              label="seniority_name"
                              valueProp="id"
                              placeholder="Select Seniority"
                              v-model="detail.seniority_id"
                              :options="senioritylevelList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="Seniority" class="text-danger" />
                        </div>
                        <div class="col-5 form-group">
                          <label> Function </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            placeholder="Select Function"
                            name="job_function_new"
                            v-model="detail.function_id"
                            rules="required:  job function"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="job_function_name"
                              label="job_function_name"
                              valueProp="id"
                              placeholder="Select Function"
                              v-model="detail.function_id"
                              :options="jobFunctionList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage
                            name="job_function_new"
                            class="text-danger"
                          />
                        </div>

                        <div class="col-4 form-group">
                          <label>Location Country</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            placeholder="Select Country"
                            name="Country"
                            rules="required:Country"
                            :validateOnInput="true"
                            v-model="detail.country_id"
                            v-on:change="
                              getsameCountry(detail.country_id)
                            "
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="country_name"
                              label="country_name"
                              valueProp="id"
                              placeholder="Select Country"
                              v-model="detail.country_id"
                              :options="countryList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="Country" class="text-danger" />
                        </div>
                        <div class="col-5 form-group">
                          <label> Region</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            placeholder="Select Region"
                            name="Region"
                            v-model="detail.region_id"
                            v-on:change="getsameRegion(detail.region_id)"
                            rules="required:Region"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="region_name"
                              label="region_name"
                              valueProp="id"
                              placeholder="Select Region"
                              v-model="detail.region_id"
                              :options="regionList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="Region" class="text-danger" />
                        </div>

                        <div class="col-8 form-group">
                          <label> Job Description URL</label>

                          <Field
                            type="text"
                            name="Job-Url"
                            class="form-control"
                            placeholder="Job-Description-URL"
                            v-model="detail.job_description_url"
                            rules="website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Job-Url" class="text-danger" />
                        </div>
                        <div class="col-8 form-group">
                          <label> Career Page URL</label>

                          <Field
                            type="text"
                            name="Career-page-Url"
                            class="form-control"
                            placeholder="Career-Page-URL"
                            v-model="detail.career_page_url"
                            rules="website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            name="Career-page-Url"
                            class="text-danger"
                          />
                        </div>
                        <div class="col-8 form-group">
                          <label> Linkedin URL</label>

                          <Field
                            type="text"
                            name="Linkdin-Url"
                            class="form-control"
                            placeholder="Linkedin-URL"
                            v-model="detail.linkdin_url"
                            rules="website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            name="Linkdin-Url"
                            class="text-danger"
                          />
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";

import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "Open Postion",
  components: {
    Form,
    Field,

    ErrorMessage,
    Multiselect,

    ErrorComponent,
  },
  data() {
    return {
      loader: true,
      id: this.$route.params.id,
      detail: {
        region_id: "",
        new_company_id: "",
        title: "",
        seniority_id: "",

        function_id: "",
        country_id: "",
        job_description_url: "",
        career_page_url: "",
        linkdin_url: "",

        companyDetails: {
          industry: {},
        },
      },
      companyList: [],

      countryList: [],
      regionList: [],
      senioritylevelList: [],
      jobFunctionList: [],
      industryList: [],
      company_type: "",
      company_ids: [],
      companyKeyword: "",
    };
  },
  mounted() {
    this.CountryList();
    this.listRegion();
    this.seniorityList();
    this.jobFunctionlist();
    this.industrylist();
    if (this.id) {
      this.getDetail();
    } else {
      this.companylist();
    }
  },
  methods: {
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    getsameCountry(id) {
     
      for (var j in this.countryList) {
        if (id == this.countryList[j].id) {
          
         
            this.detail.region_id = this.countryList[j].region_id;
          
        }
      }
     
    },

    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    listRegion() {
      this.$api
        .getAPI({
          _action: "/region/list",
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    seniorityList(keyword) {
      var obj = {};
      if (keyword) {
        obj.keyword = keyword;
      }
      this.$api
        .getAPI({
          _action: "/seniority/list",
          _body: obj,
        })
        .then((res) => {
          this.senioritylevelList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist(keyword) {
      var obj = {};
      if (keyword) {
        obj.keyword = keyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
          _body: obj,
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },

    companylist() {
      this.companyList = [];
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids.toString();
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          console.log(this.companyList);
          this.company_ids = [];
        })
        .catch(() => {});
    },
    companyDetail() {
      if (this.detail.new_company_id) {
        this.loader = false;
        this.$api
          .getAPI({
            _action:
              "/open_position/companyDetails/" + this.detail.new_company_id,
          })
          .then((res) => {
            this.loader = true;
            this.detail.companyDetails = res.result;
          })
          .catch(() => {
            this.loader = true;
          });
      }
    },
    Add() {
      if (this.id) {
        this.$api
          .postAPI({
            _action: "/open_position/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/open_position/add",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/open_position/detail/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          this.detail.title = res.result.title;
          this.detail.seniority_id = res.result.seniority_id;
          this.detail.function_id = res.result.function_id;
          this.detail.country_id = res.result.country_id;
          this.detail.new_company_id = res.result.company_id;
          this.company_ids.push(res.result.company_id);
          this.detail.region_id = res.result.Region_id;
          this.detail.job_description_url = res.result.job_description_url;
          this.detail.career_page_url = res.result.career_page_url;
          this.detail.linkdin_url = res.result.linkdin_url;
          this.companylist();
          this.companyDetail();
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
  },
  // computed: {
  //   companyType: {
  //     get() {
  //       return this.detail.companyDetails.company_type;
  //     },
  //     set(value) {
  //       // Capitalize the first letter of the value
  //       this.detail.companyDetails.company_type = value.charAt(0).toUpperCase() + value.slice(1);
  //     }
  //   }
  // }
   watch: {
    'detail.companyDetails.company_type': function(newVal) {
      if (newVal) {
        this.detail.companyDetails.company_type = newVal.charAt(0).toUpperCase() + newVal.slice(1);
      }
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon2 {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-left: 20px;
}
</style>
