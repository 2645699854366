<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div
            class="row col-lg-6 col-sm-6 my-auto d-flex align-items-center mb-4"
          >
            <a
              class="pr-2 text-dark cursor-pointer"
              v-on:click="$router.push('/role')"
              ><span class="font-24 mr-2"
                ><img src="/images/back-arrow.svg" width="18"
              /></span>
            </a>
            <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Role</h4>
          </div>
        </div>
        <Form itemref="adminForm" @submit="save()">
          <div class="page-content">
            <div class="card-body py-0 px-0">
              <div class="row">
                <div
                  class="col-lg-4 col-sm-4 form-group row"
                  style="margin-left: 3px"
                >
                  <label>Role Name</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    name="role_name"
                    class="form-control"
                    placeholder="Role Name"
                    v-model="detail.name"
                    rules="required: role name"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="role_name" class="text-danger" />
                </div>
                <!-- <div
                class="col-lg-8 col-sm-4 form-group row"
                style="margin-left: 12px"
              >
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  class="form-control"
                  placeholder="Description"
                  v-model="detail.description"
                />
              </div> -->
              </div>
              <div class="card border mb-0 radius-15">
                <div class="card-body p-0 pb-4">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table
                        class="table table-striped table-bordered dataTable"
                        style="width: 100%"
                        role="grid"
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr>
                            <th>Access to</th>
                            <!-- <th>All</th> -->
                            <th>View</th>
                            <th>Add</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>Publish</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in detail.access"
                            :key="index"
                          >
                            <td>{{ data.name }}</td>
                            <!-- <td><input type="checkbox"  v-model="data.is_all" true-value="1" false-value="0"/></td> -->
                            <td>
                              <input
                                type="checkbox"
                                v-model="data.is_view"
                                true-value="1"
                                false-value="0"
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="data.is_add"
                                true-value="1"
                                false-value="0"
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="data.is_edit"
                                true-value="1"
                                false-value="0"
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="data.is_delete"
                                true-value="1"
                                false-value="0"
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="data.is_publish"
                                true-value="1"
                                false-value="0"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-lg-12 col-sm-12 text-center mt-2">
                      <button id="save-button" class="save-btn px-4 ml-3">
                        Save
                      </button>
                      <button
                        type="button"
                        class="fil-btn px-4 ml-3"
                        v-on:click="$router.go(-1)"
                        id="cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddRole",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      searchObj: {
        keyword: "",
      },
      detail: {
        name: "",
        access: [
          {
            name: "Manage Admins",
            url: "admin",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Roles",
            url: "role",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Company",
            url: "company",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Executive",
            url: "executive",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Executive Adv.",
            url: "executives",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage News",
            url: "news",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Custom Search App",
            url: "custom-search-app",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Industry",
            url: "industry",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Publication",
            url: "publication",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage University",
            url: "university",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Intresting Bites",
            url: "intresting-bites",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Deals & Transaction",
            url: "deals-transaction",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Package",
            url: "package",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },

          // {
          //   name: "Manage rss",
          //   url: "rss",
          //   is_all: 0,
          //   is_view: 0,
          //   is_add: 0,
          //   is_edit: 0,
          //   is_delete: 0,
          //   is_publish: 0,
          // },
          {
            name: "Manage Customer",
            url: "customer",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Cluster",
            url: "cluster",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Commentry",
            url: "commentry",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Function",
            url: "function",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          // {
          //   name: "Manage Cities",
          //   url: "cities",
          //   is_all: 0,
          //   is_view: 0,
          //   is_add: 0,
          //   is_edit: 0,
          //   is_delete: 0,
          //   is_publish: 0,
          // },
          {
            name: "Manage Countries",
            url: "countries",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Topic",
            url: "topic",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Package Group",
            url: "package-group",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Custom Search Web",
            url: "custom-search-web",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Logs",
            url: "logs",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Settings",
            url: "settings",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
          {
            name: "Manage Demo",
            url: "demo",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0,
            is_publish: 0,
          },
        ],
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/role/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/role",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/role",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
  },
};
</script>