<template>
  <div>
    <div class="ml-3">
      <a
        v-show="modelValue || image.src"
        v-on:click="removeImage()"
        class="remove-img"
        style="cursor: pointer; z-index: 9"
        ><img src="/img/close.png" width="16" style=""
      /></a>

      <label
        :for="'input_' + compoentId"
        class="card mb-3 shadow-sm card-hover live-event-card text-center border-primary h-100 justify-content-center align-items-center"
        style="flex-direction: inherit; height: 160px !important; width: 160px"
      >
        <img
          style="
            flex-direction: inherit;
            height: 150px !important;
            width: 150px;
          "
          v-show="url"
          :src="url"
          :id="compoentId"
          class="mg-fluid thumbnail-img"
          height="270"
          width="285"
        />
        <input
          type="file"
          name="img[]"
          class="file3"
          :id="'input_' + compoentId"
          @change="onChange($event)"
          accept="image/*"
          style="display: none"
        />
        <a v-show="!url" class="font-18"
          ><i class="fa fa-plus" aria-hidden="true"></i
        ></a>
      </label>
      <!-- <label class="validation-msg font-6" v-show="!this.file"
        >Upload jpg/png image of size less than 1 MB</label
      > -->
    </div>

    <TextModal ref="imageCrooperModal">
      <div class="modal-header border-0 d-block text-center">
        <button
          type="button"
          class="close text-danger col-12"
          style="text-align: right; margin-top: -45px"
          v-on:click="$refs.imageCrooperModal.closeModal()"
        >
          <img src="/images/close.svg" width="18" />
        </button>
        <cropper
          ref="cropper1"
          style="
            width: 450px;
            height: 300px !important;
            transition: opacity 300ms ease 0s;
            pointer-events: all;
          "
          :src="image.src"
          :auto-zoom="true"
          :stencil-props="{
            handlers: {},
            movable: false,
            scalable: false,
          }"
          :stencil-size="{
            width: 500,
            height: 500,
          }"
        />
        <button
          type="button"
          class="fill-btn btn-brand-1 col-6 mt-3"
          v-on:click="cropImage()"
        >
          Crop
        </button>
      </div>
    </TextModal>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import TextModal from "@/components/TextModal.vue";
export default {
  name: "ImagePicker",
  components: {
    Cropper,
    TextModal,
  },
  props: {
    id: {
      type: String,
      default: "spx_img_1",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.compoentId == "spx_img_1") {
      this.compoentId = "img_" + Math.floor(Math.random() * 1000);
    }
  },
  computed: {
    url() {
      return this.image.src ? this.image.src : this.modelValue;
    },
  },
  data() {
    return {
      compoentId: this.id,
      selectFile: "",
      image: {
        src: "",
      },
      file: undefined,
      obj: {},
    };
  },
  methods: {
    cropImage() {
      const result = this.$refs.cropper1.getResult();
      this.image.src = result.canvas.toDataURL(this.image.type);
      this.file = this.dataURLtoFile(
        result.canvas.toDataURL(this.image.type),
        this.selectFile.name
      );
      console.log(" this.selectFile", this.selectFile);
      this.$refs.imageCrooperModal.closeModal();
    },
    onChange(e) {
      let files = e.target.files;
      this.selectFile = files[0];
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }

      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      // this.selectFile = URL.createObjectURL(files[0]);
      // this.file = files[0];
      var reader = new FileReader();
      const blob = URL.createObjectURL(files[0]);

      this.image = {
        src: blob,
        type: files[0].type,
      };
      reader.readAsDataURL(e.target.files[0]);
      this.showCrooperPopup();
    },
    onUploadImage() {
      this.$emit("image", this.obj);
    },
    showCrooperPopup() {
      // this.$store.state.faqObj = {};
      this.$refs.imageCrooperModal.showModal();
      // this.$refs.faqForm.resetForm();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    removeImage() {
      this.image = {};
      this.file = "";
      this.$emit("update:modelValue", "");
      this.$emit("remove-image");
    },
  },
};
</script>

<style>
.remove-img {
  cursor: pointer;
  z-index: 9;
  text-align: end;
  display: flex;
  justify-content: end;
  position: absolute;
  left: 171px;
  top: 3px;
}
</style>
