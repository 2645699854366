<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/publication')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Publication</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <div class="col-6 form-group">
                          <label>Publication Name</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="user_name"
                            class="form-control"
                            placeholder="Publication Name"
                            v-model="detail.name"
                            rules="required: publication name"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="user_name" class="text-danger" />
                        </div>
                        <div class="col-6 form-group">
                          <label>Website Url</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="website_url"
                            class="form-control"
                            placeholder="Website Url"
                            v-model="detail.website_url"
                            rules="required: website url |website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            name="website_url"
                            class="text-danger"
                          />
                        </div>
                        <div class="col-6 mt-3 mb-3">
                          <label>Publication Logo</label>
                          <ImagePicker
                              :src="detail.publication_logo"
                              :modelValue="detail.publication_logo"
                              ref="ImagePicker"
                            />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import ImagePicker from "@/components/ImagePicker.vue";
export default {
  name: "Add Publication",
  components: {
    Form,
    Field,
    ImagePicker,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      loader: false,
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/publications/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, " ");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },

    save() {
      this.detail.package_group_type = "web";
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/publications",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (!this.$refs.ImagePicker.file) {
              this.$router.go(-1);
            } else {
              this.uploadImage(res.id);
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/publications",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (!this.$refs.ImagePicker.file) {
              this.$router.go(-1);
            } else {
              this.uploadImage(res.id);
            }
          })
          .catch(() => {});
      }
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        });
    },
    onChange(e) {
      let files = e.target.files;

      let reader = new FileReader();

      reader.readAsDataURL(files[0]);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          var width = img.width;
          var height = img.height;
          if (width > 500 || height > 500) {
            alert("Image should be of 500 x 500 Pixcel");
            return;
          } else {
            if (
              files[0].type.toLowerCase() != "image/jpeg" &&
              files[0].type.toLowerCase() != "image/png"
            ) {
              alert("Invalid file formate, please use jpeg or png file.");
              return;
            }
            // const size = (files[0].size / 1024 / 1024).toFixed(2);
            // if (size > 10) {
            //   alert("File must be less then 10 MB");
            //   return;
            // }
            var tmppath = URL.createObjectURL(files[0]);
            this.file = files[0];
            document.getElementById("preview").src = tmppath;
          }
        };

        img.src = evt.target.result;
      };
    },
  },
};
</script>