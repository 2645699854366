<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="col-12 shadow-btm row">
        <div class="col-lg-9 col-sm-6 my-auto">
          <h4 class="mb-0 lg-bld">
            PDF Preview: {{ executive != null ? executive.executive_name : "" }}
          </h4>
        </div>
      </div>
      <div class="page-content">
        <div class="card-body py-0 px-0">
          <div class="px-0">
            <div class="row mb-3"></div>
            <div
              class="text-center"
              v-show="loader"
              style="background: white; border-radius: 0 0 16px 16px"
            >
              <img src="/img/loader.gif" />
            </div>
            <page size="A4" v-show="!loader">
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; background: white"
              >
                <tbody>
                  <tr>
                    <td colspan="2">
                      <img
                        src="/images/logo.png"
                        height="50"
                        style="height: 50px !important"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="59%" style="line-height: 20px">
                      <img
                        :src="executive.executive_logo"
                        style="
                          float: left;
                          height: 63px;
                          border-radius: 50%;
                          margin-right: 10px;
                        "
                      />
                      <strong>
                        {{
                          executive != null ? executive.executive_name : ""
                        }}</strong
                      ><br />
                      {{ executive != null ? executive.new_job_title_id : ""
                      }}<br />
                      {{
                        executive != null
                          ? executive.new_company.company_name
                          : ""
                      }}
                    </td>
                    <td width="41%" align="right">
                      <img
                        src="/images/linkedin.png"
                        style="
                          height: 33px;
                          border-radius: 50%;
                          margin-right: 10px;
                          margin-top: 14px;
                        "
                      />
                      <img
                        src="/images/twitter.png"
                        style="
                          height: 33px;
                          border-radius: 50%;
                          margin-right: 10px;
                          margin-top: 14px;
                        "
                      />
                      <img
                        src="/images/web.png"
                        style="
                          height: 33px;
                          border-radius: 50%;
                          margin-right: 10px;
                          margin-top: 14px;
                        "
                      />
                      <img
                        src="/images/men.jpg"
                        style="
                          height: 33px;
                          border-radius: 50%;
                          margin-right: 10px;
                          margin-top: 14px;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <strong><b>Biography</b></strong
                      ><br /><br />
                      {{
                        executive != null ? executive.executive_biography : ""
                      }}
                    </td>
                    <td>
                      <table width="100%" border="0">
                        <tbody>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                              width="80"
                            >
                              <strong><b>Age Range:</b></strong>
                            </td>
                            <td
                              width="184"
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null && executive.age_range != null
                                  ? executive.age_range.age_range_name
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Gender:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                capitalizeFirstLetter(
                                  executive != null ? executive.gender : ""
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Location:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null &&
                                executive.new_location != null
                                  ? executive.new_location.city_name
                                  : ""
                              }}

                              {{
                                executive != null && executive.country != null
                                  ? (executive != null &&
                                    executive.new_location != null
                                      ? ", "
                                      : "") + executive.country.country_name
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Industry:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null &&
                                executive.new_industry != null
                                  ? executive.new_industry.industry_name
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Function:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null &&
                                executive.new_function != null
                                  ? executive.new_function.job_function_name
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Role Since:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null ? executive.curr_month : ""
                              }}
                              {{ executive != null ? executive.curr_year : "" }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>E-mail:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null
                                  ? executive.executive_email_work
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="2"
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Current Responsibility:</b></strong
                              ><br />
                              {{
                                executive != null
                                  ? executive.current_responsibility
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Education:</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{
                                executive != null && executive.education != null
                                  ? executive.education.education_name
                                  : ""
                              }},
                              {{
                                executive != null &&
                                executive.university != null
                                  ? executive.university.name
                                  : ""
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong><b>Past Companies</b></strong
                      ><br /><br />
                      <div
                        v-for="(data, index) in executive.past_companies"
                        :key="index"
                      >
                        <img
                          :src="data.company_logo"
                          style="float: left; height: 33px"
                        />
                        <!-- <img src="img/past2.png" style="float: left;height: 33px;"/>
              <img src="img/past3.png" style="float: left;height: 33px;"/> -->
                      </div>
                    </td>
                    <td>
                      <strong><b>Awards & Recognition</b></strong
                      ><br /><br />
                      <div
                        v-for="(data, index) in executive.executive_clusters"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'award_' + data.id"
                          :name="'award_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickAwardAndRecognition(data.id)"
                        />
                        <!-- {{ executive!=null ?  data.name : "" }} -->
                        <a href="#">{{ executive != null ? data.name : "" }}</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong><b>Conferences & Event</b></strong> <br /><br />
                      <div
                        v-for="(data, index) in executive.conferences_event"
                        :key="index"
                      >
                        <img
                          :src="data.logo"
                          style="float: left; height: 33px"
                        />
                        <!-- <img src="img/event2.png" style="float: left;height: 33px;">
            <img src="img/event3.png" style="float: left;height: 33px;"> -->
                      </div>
                    </td>
                    <td>
                      <strong><b>Investments & Stakeholding</b></strong
                      ><br /><br />
                      <div
                        v-for="(
                          data, index
                        ) in executive.investment_stack_holding"
                        :key="index"
                      >
                        <img
                          :src="data.company_logo"
                          style="float: left; height: 33px"
                        />
                        <!-- <img src="img/investment1.png" style="float: left;height: 33px;"> -->
                      </div>
                    </td>
                  </tr>                  
                  <tr>
                    <td colspan="2">
                      <strong><b>Interesting Articles</b></strong> <br /><br />
                      <div
                        v-for="(data, index) in executive.interting_links"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'interestingarticles_' + data.id"
                          :name="'interestingarticles_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickInterestingArticles(data.id)"
                        />
                        <a href="#">
                          {{ executive != null ? data.headline : "" }}</a
                        ><br />
                        <!-- <a href="#">6 Global Employers On How To Improve Workplace Mental Health</a><br>
                          <a href="#">6 Global Employers On How To Improve Workplace Mental Health</a><br>
                          <a href="#">Gilead Sciences Appoints Flavius Martin, MD as Executive Vice President, Research</a><br>
                          <a href="#">Gilead Sciences Announces Changes to Senior Leadership Team</a><br>
                          <a href="#">Discussion With Elaine Arden</a><br> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <strong><b>Featured News</b></strong> <br /><br />
                      <div
                        v-for="(data, index) in executive.featured_news"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'featurednews_' + data.id"
                          :name="'featurednews_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickFeaturedNews(data.id)"
                        />
                        <a href="#">{{
                          executive != null ? data.news_title : ""
                        }}</a
                        ><br />
                        <!-- <a href="#">Gilead Sciences Appoints Flavius Martin, MD as Executive Vice President, Research</a><br> -->
                        <!-- <a href="#">Gilead Sciences Announces Changes to Senior Leadership Team</a> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <strong><b>Executive Videos</b></strong
                      ><br /><br />
                      <div
                        v-for="(data, index) in executive.videos"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'executivevideos_' + data.id"
                          :name="'executivevideos_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickExecutiveVideos(data.id)"
                        />
                        <a href="#">{{
                          executive != null ? data.title : ""
                        }}</a>
                        <!-- <a href="#"> Preserving the Promise of Cancer Immunotherapy</a> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="text-align: center; hieght: 50px">
                      <button
                        style="width: 200px"
                        type="button"
                        @click="downloadExecutivePdf()"
                        class="delete-btn px-4 ml-3"
                        id="btn-download"
                      >
                        Create PDF
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExecutivePdfPreview",
  components: {},
  data() {
    return {
      loader: true,
      executive_id: this.$route.params.id,
      featured_news: [],
      award_and_recognition: [],
      interesting_articles: [],
      executive_videos: [],
      executive: {
        executive_name: "",
        gender: "",
        new_company: {
          company_name: "",
        },
        age_range: {
          age_range_name: "",
        },
        new_location: {
          city_name: "",
        },
        country: {
          country_name: "",
        },
        new_industry: {
          industry_name: "",
        },
        new_function: {
          job_function_name: "",
        },
        education: {
          education_name: "",
        },
        university: {
          name: "",
        },
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      var obj = {};
      obj.id = this.executive_id;
      obj.type = "VIEW";
      obj.featured_news = this.featured_news;
      obj.award_and_recognition = this.award_and_recognition;
      obj.interesting_articles = this.interesting_articles;
      obj.executive_videos = this.executive_videos;
      this.$api
        .getAPI({
          _action: "/executive/getExecutivePdf",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.executive = res.result;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clickFeaturedNews(id) {
      if (this.featured_news.includes(id)) {
        this.featured_news.pop(id);
      } else {
        this.featured_news.push(id);
      }

      console.log(this.featured_news);
    },

    clickAwardAndRecognition(id) {
      if (this.award_and_recognition.includes(id)) {
        this.award_and_recognition.pop(id);
      } else {
        this.award_and_recognition.push(id);
      }

      console.log(this.award_and_recognition);
    },

    clickInterestingArticles(id) {
      if (this.interesting_articles.includes(id)) {
        this.interesting_articles.pop(id);
      } else {
        this.interesting_articles.push(id);
      }

      console.log(this.interesting_articles);
    },

    clickExecutiveVideos(id) {
      if (this.executive_videos.includes(id)) {
        this.executive_videos.pop(id);
      } else {
        this.executive_videos.push(id);
      }

      console.log(this.executive_videos);
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    downloadExecutivePdf() {
      var featured_news = this.featured_news.join(",");
      // var featured_news = this.featured_news.toString();
      var interesting_articles = this.interesting_articles.join(",");
      var executive_videos = this.executive_videos.join(",");
      var award_and_recognition = this.award_and_recognition.join(",");

      var url =
        this.$api.getBaseUrl() +
        "/executive/getExecutivePdf?type=DOWNLOAD&id=" +
        this.executive_id +
        "&featured_news=" +
        featured_news +
        "&award_and_recognition=" +
        award_and_recognition +
        "&interesting_articles=" +
        interesting_articles +
        "&executive_videos=" +
        executive_videos +
        "&type=DOWNLOAD";
      window.open(url);
      window.location.reload();
    },
  },
};
</script>

  <style>
body {
  background: rgb(204, 204, 204);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13.5px;
}

page {
  background: white;
  display: block;
  margin: 0 auto;
}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

page[size="A4"][layout="portrait"] {
  width: 29.7cm;
  height: 21cm;
}

@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 23px;
  padding-right: 23px;
}

a {
  color: #000;
}
</style>