<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/company')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Company</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <div class="col-6">
                          <div class="col-12 form-group mb-5">
                            <label>Company Name</label>
                            <span class="text-danger">*</span>

                            <Field
                              type="text"
                              name="company_name"
                              class="form-control"
                              placeholder="Comapny Name"
                              v-model="detail.company_name"
                              rules="required:company name"
                              :validateOnInput="true"
                              v-slot="{ field }"
                            >
                              <Multiselect
                                mode="single"
                                trackBy="company_name"
                                label="company_name"
                                valueProp="company_name"
                                placeholder="Select"
                                v-model="detail.company_name"
                                :options="companyList"
                                :create-option="true"
                                searchable="true"
                                v-bind="field"
                                @search-change="searchCompanyName($event)"
                              >
                                <template v-slot:option="{ option }">
                                  <img
                                    class="option-icon"
                                    :src="
                                      option.company_logo
                                        ? option.company_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                  {{ option.company_name }}
                                </template>
                              </Multiselect>
                            </Field>
                            <ErrorMessage
                              name="company_name"
                              class="text-danger"
                            />
                          </div>
                          <div class="col-12 form-group">
                            <label>Company Website</label>
                            <Field
                              type="text"
                              name="company_website"
                              class="form-control"
                              placeholder="Company Website"
                              v-model="detail.company_website"
                              rules="required:company website|website"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="company_website"
                              class="text-danger"
                            />
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="col-6">
                            <label>Company Logo</label>
                            <ImagePicker
                              :src="detail.company_logo"
                              :modelValue="detail.company_logo"
                              ref="ImagePicker"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 row" style="padding: 26px">
                        <div class="col-6 form-group">
                          <label>Description</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="textarea"
                            name="Description"
                            class="form-control"
                            placeholder="Description"
                            rules="required:description"
                            :validateOnInput="true"
                            v-model="detail.company_desc"
                            v-bind="field"
                          />
                          <ErrorMessage
                            name="Description"
                            class="text-danger"
                          />
                        </div>
                        <div class="col-6 form-group">
                          <label>Team Page Website</label>
                          <Field
                            type="text"
                            name="Team_Page_Website"
                            class="form-control"
                            placeholder="Team Page Website"
                            v-model="detail.team_page_url"
                            rules="website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            name="Team_Page_Website"
                            class="text-danger"
                          />
                        </div>

                        <div class="col-3 form-group">
                          <label for="inputRole">Industry Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="industry"
                            rules="required:industry"
                            :validateOnInput="true"
                            v-model="detail.industry_id"
                            v-on:change="handleIndustryChange"
                          >
                            <option value="">Select Industry</option>
                            <option
                              v-for="(data, index) in industryList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.industry_name }}
                            </option>
                          </Field>
                          <ErrorMessage name="industry" class="text-danger" />
                        </div>
                        <div class="col-3 form-group" v-if="showTechType">
                          <label for="inputTechFocus">Tech Focus</label>
                          <!-- <span class="text-danger">*</span> -->
                          <Field
                            as="select"
                            class="form-control"
                            id="inputTechFocus"
                            name="tech_focus"
                            :validateOnInput="true"
                            v-model="detail.subsector_id"
                          >
                            <option value="">Select Tech Focus</option>
                            <option
                              v-for="(data, index) in subsectorsList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.industry_name }}
                            </option>
                          </Field>
                          <ErrorMessage name="tech_type" class="text-danger" />
                        </div>
                        <div class="col-3">
                          <label>Company Size</label>
                          <Field
                            as="select"
                            class="form-control"
                            name="company_size"
                            v-model="detail.company_size"
                          >
                            <option value="">Select Company Size</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-200">51-200</option>
                            <option value="201-500">201-500</option>
                            <option value="501-1000">501-1000</option>
                            <option value="1001-5000">1001-5000</option>
                            <option value="5001-10000">5001-10000</option>
                            <option value="10000+">10000+</option>
                          </Field>
                        </div>

                        <div class="col-3 form-group">
                          <label for="inputRole">Revenue Range</label>
                          <!-- <span class="text-danger">*</span> -->
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="revenue"
                            v-model="detail.revenue_id"
                          >
                            <option value="">Select Revenue</option>
                            <option
                              v-for="(data, index) in revenueList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.range }}
                            </option>
                          </Field>
                        </div>
                        <div class="col-3">
                          <label>Currency</label>
                          <Field
                            as="select"
                            class="form-control"
                            name="currency"
                            v-model="detail.currency_id"
                          >
                            <option value="">Select Currency</option>
                            <option
                              v-for="(data, index) in currencyList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.currency_name }} (
                              {{ data.currency_code }} -{{
                                data.currency_symbol
                              }})
                            </option>
                          </Field>
                        </div>

                        <div class="col-6 form-group">
                          <label>Company Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            name="company_type"
                            v-model="detail.company_type"
                            rules="required:company type"
                            :validateOnInput="true"
                          >
                            <option value="">Select Company Type</option>
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                            <option value="education">Education</option>
                            <option value="government">Government</option>
                            <option value="not-for-profit">
                              Not for Profit
                            </option>
                          </Field>
                          <ErrorMessage
                            name="company_type"
                            class="text-danger"
                          />
                        </div>
                        <div
                          v-if="detail.company_type == 'public'"
                          class="col-6 form-group"
                        >
                          <label>Ticker</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="ticker"
                            class="form-control"
                            placeholder="Ticker"
                            v-model="detail.ticker"
                            rules="required:ticker"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="ticker" class="text-danger" />
                        </div>
                        <div class="col-3 form-group">
                          <label for="inputRole">Deal Stage</label>
                          <!-- <span class="text-danger">*</span> -->
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="deal_stage"
                            v-model="detail.deal_stage_id"
                          >
                            <option value="">Select Deal Stage</option>
                            <option
                              v-for="(data, index) in deaStageList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <div class="col-6 form-group"></div>
                        <hr />
                        <h3 class="col-10 sub-heading mt-4">Headquarters :-</h3>
                        <div class="col-6 form-group"></div>
                        <div class="col-8 form-group">
                          <label>Country</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="country_name"
                            placeholder="Select country"
                            rules="required: country"
                            :validateOnInput="true"
                            v-model="detail.country_id"
                            v-on:change="CityList(detail.country_id)"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="country_name"
                              label="country_name"
                              valueProp="id"
                              placeholder="Select Country"
                              v-model="detail.country_id"
                              :options="countryList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage
                            name="country_name"
                            class="text-danger"
                          />
                        </div>

                        <div class="col-4 form-group">
                          <label>City</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="city_id"
                            placeholder="Select city"
                            v-model="detail.city_id"
                            rules="required:city"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="city_name"
                              label="city_name"
                              valueProp="id"
                              v-model="detail.city_id"
                              :options="cityList"
                              searchable="true"
                              placeholder="Select City"
                              @search-change="searchCityName($event)"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="city_id" class="text-danger" />
                        </div>
                        <div class="col-4 form-group">
                          <label>Current Region</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            placeholder="Select"
                            name="NEW_REGION"
                            v-model="detail.region_id"
                            rules="required:  region"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="region_name"
                              label="region_name"
                              valueProp="id"
                              placeholder="Select"
                              v-model="detail.region_id"
                              :options="regionList"
                              searchable="true"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="NEW_REGION" class="text-danger" />
                        </div>
                        <div class="col-4 form-group">
                          <label>Company Contact Number</label>
                          <!-- rules="required:contact number|phone" -->
                          <Field
                            type="text"
                            name="phone"
                            placeholder="Company Mobile"
                            v-model="detail.company_mobile"
                            v-slot="{ field }"
                          >
                            <PhoneCode
                              id="mobile"
                              placeholder="Company Mobile"
                              v-model:countryCode="detail.country_code"
                              v-model="detail.company_mobile"
                              v-model:dialCode="detail.dial_code"
                              v-bind="field"
                            />
                          </Field>
                          <!-- <ErrorMessage name="phone" class="text-danger" /> -->
                        </div>
                        <div class="col-8 form-group"></div>

                        <hr />
                        <h3 class="col-8 sub-heading mt-4">Social Media :-</h3>
                        <div
                          v-for="(data, index) in detail.social_media"
                          :key="index"
                          class="col-12 row"
                        >
                          <div class="form-group col-3">
                            <Field
                              as="select"
                              class="form-control"
                              placeholder="Platform"
                              :name="'Platform_' + index"
                              v-model="data.platform_type"
                            >
                              <option value="">Select Platform</option>
                              <option value="LinkedIn">LinkedIn</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Twitter">Twitter</option>
                            </Field>
                          </div>
                          <div class="form-group col-3">
                            <Field
                              type="text"
                              class="form-control"
                              :name="'URL_' + index"
                              placeholder="URL"
                              v-model="data.url"
                              rules="website"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              :name="'URL_' + index"
                              class="text-danger"
                            />
                          </div>
                          <img
                            v-show="
                              index == detail.social_media.length - 1 &&
                              detail.social_media.length <= 2
                            "
                            v-on:click="addPlatform()"
                            src="/img/plus.png"
                            width="30"
                            height="30"
                          />
                          <img
                            v-show="
                              index != detail.social_media.length - 1 ||
                              index == 2
                            "
                            v-on:click="removePlatform(index)"
                            src="/img/cross.png"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="col-6"></div>

                        <hr />
                        <div class="col-12 row">
                          <div class="form-group col-2">
                            <label>Flag for Review</label>
                            <div>
                              <input
                                type="checkbox"
                                id="is_review"
                                name="is_review"
                                style="height: 52px; width: 27px"
                                true-value="1"
                                false-value="0"
                                v-model="detail.is_review"
                              />
                            </div>
                          </div>
                          <div class="form-group col-6">
                            <label></label>
                            <textarea
                              type="text"
                              name="Current_Responsibility"
                              class="form-control"
                              placeholder=""
                              v-model="detail.reason_for_flag"
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 text-center mt-2">
                        <button id="save-button" class="save-btn px-4 ml-3">
                          Save
                        </button>
                        <button
                          type="button"
                          class="fil-btn px-4 ml-3"
                          v-on:click="$router.go(-1)"
                          id="cancel-button"
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import ImagePicker from "@/components/ImagePicker.vue";
// import Datepicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import PhoneCode from "@/components/PhoneCodePicker/PhoneCode";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
    ImagePicker,
    Multiselect,
    ErrorComponent,
  },
  data() {
    return {
      loader: false,
      isShow: false,
      id: this.$route.params.id,
      detail: {
        deal_stage_id: "",
        company_size: "",
        city_id: "",
        company_type: "",
        is_pending: "N",
        social_media: [{}],
        interting_article: [
          {
            title: "",
            url: "",
            category_id: "",
          },
        ],
        interting_bite: [
          {
            news: "",
            source: "",
            category_id: "",
          },
        ],
      },
      showTechType: false,
      companyList: [],
      revenueList: [],
      currencyList: [],
      topicList: [],
      jobFunctionList: [],
      industryList: [],
      senioritylevelList: [],
      countryList: [],
      cityList: [],
      city_ids: [],
      expansionList: [],
      regionList: [],
      deaStageList: [],
      subsectorsList: [],
      cityKeyword: "A",
      companyKeyword: "",
    };
  },
  mounted() {
    this.industrylist();
    this.CountryList();
    this.topiclist();
    this.Expansionlist();
    this.getrevenuelist();
    this.getcurrencyList();
    this.listRegion();
    this.getdealStageList();
    // this.CityList();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    handleIndustryChange(event) {
      const selectedIndustryId = event.target.value;
      this.selectedIndustry = selectedIndustryId;

      const selectedIndustry = this.industryList.find(industry => industry.id == selectedIndustryId);
      if (selectedIndustry && selectedIndustry.sub_sector_count > 0) {
        this.showTechType = true;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.showTechType = false;
        this.detail.subsector_id = '';
      }
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    listRegion() {
      this.$api
        .getAPI({
          _action: "/region/list",
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    getcurrencyList() {
      this.$api
        .getAPI({
          _action: "/currency/list",
        })
        .then((res) => {
          this.currencyList = res;
        })
        .catch(() => {});
    },
    getrevenuelist() {
      this.$api
        .getAPI({
          _action: "/revenue/list",
        })
        .then((res) => {
          this.revenueList = res;
        })
        .catch(() => {});
    },
    getdealStageList() {
      this.$api
        .getAPI({
          _action: "/funding_series/list",
        })
        .then((res) => {
          this.deaStageList = res.list;
        })
        .catch(() => {});
    },
    addLinkObj() {
      this.detail.interting_article.push({});
    },
    removeLinkObj(index) {
      this.detail.interting_article.splice(index, 1);
    },
    addBitesObj() {
      this.detail.interting_bite.push({});
    },
    removeBitesObj(index) {
      this.detail.interting_bite.splice(index, 1);
    },
    addPlatform() {
      if (this.detail.social_media.length < 3) {
        this.detail.social_media.push({});
      }
    },
    removePlatform(index) {
      this.detail.social_media.splice(index, 1);
    },
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/company/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          if (this.detail.company_name) {
            this.companyKeyword = this.detail.company_name;
            this.companylist();
          }
          this.city_ids.push(this.detail.city_id);
          this.detail.subsector_id = res.subsector_id;
          this.detail.social_media = [];
          if (this.detail.facebook_url) {
            var obj1 = {
              platform_type: "Facebook",
              url: this.detail.facebook_url,
            };
            this.detail.social_media.push(obj1);
          }
          if (this.detail.twitter_url) {
            var obj2 = {
              platform_type: "Twitter",
              url: this.detail.twitter_url,
            };
            this.detail.social_media.push(obj2);
          }
          if (this.detail.linkedin_url) {
            var obj3 = {
              platform_type: "LinkedIn",
              url: this.detail.linkedin_url,
            };
            this.detail.social_media.push(obj3);
          }
          if (this.detail.social_media.length == 0) {
            this.detail.social_media.push({});
          }
          this.searchCityName();
          this.loader = false;
          if (this.detail.interting_article.length <= 0) {
            this.detail.interting_article.push({});
          }
          if (this.detail.interting_bite.length <= 0) {
            this.detail.interting_bite.push({});
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
          if (this.detail.industry_id) {
            this.handleIndustryChange({ target: { value: this.detail.industry_id } });
          }
          this.detail.deal_stage_id = res.funding_series_id;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    Expansionlist() {
      this.$api
        .getAPI({
          _action: "/artical/category/list",
        })
        .then((res) => {
          this.expansionList = res;
        })
        .catch(() => {});
    },
    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    CityList(country_id) {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      if (this.city_ids.length > 0) {
        obj.city_ids = this.city_ids;
      }
      if (country_id) {
        obj.country_id = country_id;
        for (var i in this.countryList) {
          if (country_id == this.countryList[i].id) {
            this.detail.country_code = this.countryList[i].country_code;
            this.detail.region_id = this.countryList[i].region_id;
          }
        }
      }
      if (this.detail.country_id) {
        obj.country_id = this.detail.country_id;
      }
      this.$api
        .getAPI({
          _action: "/city/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
          this.city_ids = [];
        })
        .catch(() => {});
    },
    seniorityList() {
      this.$api
        .getAPI({
          _action: "/seniority/list",
        })
        .then((res) => {
          this.senioritylevelList = res;
        });
    },
    jobFunctionlist() {
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/subsector-count/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorsList = res.list;
        })
        .catch(() => {});
    },
    topiclist() {
      this.$api
        .getAPI({
          _action: "/topic/list",
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    executivelist() {
      this.$api
        .getAPI({
          _action: "/executive/list",
        })
        .then((res) => {
          this.executiveList = res;
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          for (var a in this.companyList) {
            this.companyList[a].disabled = true;
          }
        })
        .catch(() => {});
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    save() {
      if (this.isShow) {
        return;
      }
      if (!this.$refs.ImagePicker.file && !this.detail.company_logo) {
        this.$notify({
          type: "error",
          text: "Please upload company logo",
        });
        return;
      }
      if (this.detail.social_media.length > 0) {
        this.detail.linkedin_url = "";
        this.detail.facebook_url = "";
        this.detail.twitter_url = "";
        for (var a in this.detail.social_media) {
          if (this.detail.social_media[a].platform_type == "LinkedIn") {
            this.detail.linkedin_url = this.detail.social_media[a].url;
          }
          if (this.detail.social_media[a].platform_type == "Facebook") {
            this.detail.facebook_url = this.detail.social_media[a].url;
          }
          if (this.detail.social_media[a].platform_type == "Twitter") {
            this.detail.twitter_url = this.detail.social_media[a].url;
          }
        }
      }
      if (
        this.detail.interting_article.length > 0 &&
        this.detail.interting_article[0].category_id == ""
      ) {
        this.detail.interting_article = [];
      }
      if (
        this.detail.interting_bite.length > 0 &&
        this.detail.interting_bite[0].category_id == ""
      ) {
        this.detail.interting_bite = [];
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/company",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (!this.$refs.ImagePicker.file) {
              this.$router.go(-1);
            } else {
              this.uploadImage(res.id);
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/company",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (!this.$refs.ImagePicker.file) {
              this.$router.go(-1);
            } else {
              this.uploadImage(res.id);
            }
          })
          .catch(() => {});
      }
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/company/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
