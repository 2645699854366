<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="col-12 shadow-btm row">
        <div class="col-lg-9 col-sm-6 my-auto">
          <h4 class="mb-0 lg-bld">PDF Preview: {{ company.company_name }}</h4>
        </div>
      </div>
      <div class="page-content">
        <div class="card-body py-0 px-0">
          <div class="px-0">
            <div class="row mb-3"></div>
            <div
              class="text-center"
              v-show="loader"
              style="background: white; border-radius: 0 0 16px 16px"
            >
              <img src="/img/loader.gif" />
            </div>
            <page size="A4" v-show="!loader">
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; background: white"
              >
                <tbody>
                  <tr>
                    <td colspan="2">
                      <img
                        src="/images/logo.png"
                        height="50"
                        style="height: 50px !important"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="59%"
                      style="line-height: 20px; vertical-align: middle"
                    >
                      <img
                        :src="company.company_logo"
                        style="
                          float: left;
                          height: 63px;
                          margin-right: 10px;
                          height: 30px;
                        "
                      />
                      <strong style="vertical-align: sub">
                        <b>{{ company.company_name }}</b></strong
                      ><br />
                    </td>
                    <td width="41%" align="right">
                      <img
                        v-if="company.linkedin_url != ''"
                        src="/images/linkedin.png"
                        style="
                          height: 33px;
                          margin-right: 5px;
                          border-radius: 50%;
                        "
                      />
                      <img
                        v-if="company.twitter_url != ''"
                        src="/images/twitter.png"
                        style="
                          height: 33px;
                          margin-right: 5px;
                          border-radius: 50%;
                        "
                      />
                     
                      <img
                        v-if="company.company_website != ''"
                        src="/images/web.png"
                        style="
                          height: 33px;
                          margin-right: 5px;
                          border-radius: 50%;
                        "
                      />
                       <img
                        v-if="company.facebook_url != ''"
                        src="/images/facebook.png"
                        style="
                          height: 33px;
                          margin-right: 5px;
                          border-radius: 50%;
                        "
                      />
                      <img
                        v-if="company.team_page_url != ''"
                        src="/images/men.jpg"
                        style="
                          height: 33px;
                          margin-right: 5px;
                          border-radius: 50%;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <strong><b>Company Profile</b></strong
                      ><br /><br />
                      <b>{{ company.company_desc }}</b>
                    </td>
                    <td style="vertical-align: top">
                      <table width="100%" border="0">
                        <tbody>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                              width="80"
                            >
                              <strong><b>Headquarters:&nbsp;</b></strong>
                            </td>
                            <td
                              width="184"
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{ company.country.country_name }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Industry:&nbsp;</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{ company.industry.industry_name }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Type:&nbsp;</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{ capitalizeFirstLetter(company.company_type) }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Employee Size:&nbsp;</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{ company.company_size }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              <strong><b>Phone:&nbsp;</b></strong>
                            </td>
                            <td
                              style="
                                text-align: left;
                                padding: 0px;
                                padding-bottom: 20px;
                              "
                            >
                              {{ company.dial_code }}
                              {{ company.company_mobile }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="line-height: 30px">
                      <strong><b>Executive Moves News</b></strong
                      ><br />

                      <div
                        v-for="(data, index) in company.movesnewsList"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'movesnewsList_' + data.id"
                          :name="'movesnewsList_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickMoveNews(data.id)"
                        />
                        <label
                          ><b>{{ data.news_title }} </b></label
                        >
                      </div>

                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="line-height: 30px">
                      <strong><b>Featured News</b></strong> <br />
                      <div
                        v-for="(data, index) in company.newsList"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'featurednews_' + data.id"
                          :name="'featurednews_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickFeaturedNews(data.id)"
                        />
                        <label
                          ><b>{{ data.news_title }}</b></label
                        >
                      </div>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="line-height: 30px">
                      <strong><b>Interesting Bites</b></strong> <br />
                      <div
                        v-for="(data, index) in company.interting_bite_limit"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'interestingbites_' + data.id"
                          :name="'interestingbites_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickInterestingBites(data.id)"
                        />
                        <label
                          ><b>{{ data.headline }}</b></label
                        >
                      </div>

                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td style="line-height: 30px">
                      <strong><b>Awards & Recognition</b></strong> <br />
                      <div
                        v-for="(data, index) in company.company_clusters"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="'award_' + data.id"
                          :name="'award_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickAwardAndRecognition(data.id)"
                        />
                        <label
                          ><b>{{ data.name }}</b></label
                        >
                      </div>
                      <br />
                    </td>
                    <td style="vertical-align: top">
                      <strong><b>Investments & Stakeholding</b></strong
                      ><br /><br />
                      <div
                        v-for="(
                          data, index
                        ) in company.investments_stakeholding"
                        :key="index"
                      >
                        <!-- <input
                          type="checkbox"
                          :id="'investments_stakeholding_' + data.id"
                          :name="'investments_stakeholding_' + data.id"
                          :value="data.id"
                        /> -->
                        <img
                          :src="data.logo"
                          style="
                            float: left;
                            height: 33px;
                            margin: 4px;
                            border-radius: 18px;
                          "
                        />
                        <!-- <label>{{ data.logo }} </label> -->
                      </div>
                      <!-- <img
                        src="img/past1.png"
                        style="float: left; height: 33px"
                      />
                      <img
                        src="img/past2.png"
                        style="float: left; height: 33px"
                      />
                      <img
                        src="img/past3.png"
                        style="float: left; height: 33px"
                      /> -->
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="line-height: 30px">
                      <strong><b>Executive Videos</b></strong> <br />
                      <div v-for="(data, index) in company.videos" :key="index">
                        <input
                          type="checkbox"
                          :id="'executivevideos_' + data.id"
                          :name="'executivevideos_' + data.id"
                          style="margin-right: 8px"
                          :value="data.id"
                          @click="clickExecutiveVideos(data.id)"
                        />
                        <label>{{ data.title }} </label>
                      </div>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="text-align: center; hieght: 50px">
                      <button
                        style="width: 200px"
                        type="button"
                        @click="downloadCompanyPdf()"
                        class="delete-btn px-4 ml-3"
                        id="btn-download"
                      >
                        Create PDF
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompanyPdfPreview",
  components: {},
  data() {
    return {
      loader: true,
      company_id: this.$route.params.id,
      move_news: [],
      featured_news: [],
      interesting_bites: [],
      award_and_recognition: [],
      executive_videos: [],
      company: {
        company_logo: "",
        company_name: "",
        country: {},
        industry: {},
        company_type: "",
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      var obj = {};
      obj.id = this.company_id;
      obj.type = "VIEW";
      obj.move_news = this.move_news;
      obj.interesting_bites = this.interesting_bites;
      obj.featured_news = this.featured_news;
      obj.award_and_recognition = this.award_and_recognition;
      obj.executive_videos = this.executive_videos;
      this.$api
        .getAPI({
          _action: "/company/getCompanyPdf",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.company = res.result;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clickMoveNews(id) {
      if (this.move_news.includes(id)) {
        this.move_news.pop(id);
      } else {
        this.move_news.push(id);
      }

      console.log(this.move_news);
    },
    clickFeaturedNews(id) {
      if (this.featured_news.includes(id)) {
        this.featured_news.pop(id);
      } else {
        this.featured_news.push(id);
      }

      console.log(this.featured_news);
    },
    clickInterestingBites(id) {
      if (this.interesting_bites.includes(id)) {
        this.interesting_bites.pop(id);
      } else {
        this.interesting_bites.push(id);
      }

      console.log(this.interesting_bites);
    },
    clickAwardAndRecognition(id) {
      if (this.award_and_recognition.includes(id)) {
        this.award_and_recognition.pop(id);
      } else {
        this.award_and_recognition.push(id);
      }

      console.log(this.award_and_recognition);
    },
    clickExecutiveVideos(id) {
      if (this.executive_videos.includes(id)) {
        this.executive_videos.pop(id);
      } else {
        this.executive_videos.push(id);
      }

      console.log(this.executive_videos);
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    downloadCompanyPdf() {
      var move_news = this.move_news.join(",");
      var featured_news = this.featured_news.join(",");
      var interesting_bites = this.interesting_bites.join(",");
      var award_and_recognition = this.award_and_recognition.join(",");
      var executive_videos = this.executive_videos.join(",");
      this.move_news = [];
      this.featured_news = [];
      this.interesting_bites = [];
      this.award_and_recognition = [];
      var url =
        this.$api.getBaseUrl() +
        "/company/getCompanyPdf?type=DOWNLOAD&id=" +
        this.company_id +
        "&move_news=" +
        move_news +
        "&featured_news=" +
        featured_news +
        "&interesting_bites=" +
        interesting_bites +
        "&award_and_recognition=" +
        award_and_recognition +
        "&executive_videos=" +
        executive_videos +
        "&type=DOWNLOAD";
      // console.log("url = " + url);

      window.open(url);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
body {
  background: rgb(204, 204, 204);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13.5px;
}
page {
  background: white;
  display: block;
  margin: 0 auto;
}
page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}
page[size="A4"][layout="portrait"] {
  width: 29.7cm;
  height: 21cm;
}
@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}
td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 23px;
  padding-right: 23px;
}
a {
  color: #000;
}
</style>
