import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import OTP from "@/views/Onboarding/otp.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import MyProfile from "@/views/Onboarding/my-profile.vue";
import Admin from "@/views/Admin/admin.vue";
import Settings from "@/views/Settings/settings.vue";

import PackageGroup from "@/views/Manage-Package-Group/package-group.vue";
import AddPackageGroup from "@/views/Manage-Package-Group/add-package-group.vue";

import Customer from "@/views/Manage-Customer/customer.vue";
import AddCustomer from "@/views/Manage-Customer/edit-customer.vue";

import Topic from "@/views/Manage-Topic/topic.vue";
import AddTopic from "@/views/Manage-Topic/add-topic.vue";

import Jobtitle from "@/views/Manage-Job-Title/job-title.vue";
import AddJobtitle from "@/views/Manage-Job-Title/add-job-title.vue";

import Function from "@/views/Manage-Function/function.vue";
import AddFunction from "@/views/Manage-Function/add-function.vue";

import Industry from "@/views/Manage-Industry/Industry.vue";
import AddIndustry from "@/views/Manage-Industry/add-Industry.vue";

import Logs from "@/views//Manage-Logs/logs.vue";
import Cities from "@/views/Manage-Cities/cities.vue";

import Countries from "@/views/Manage-Countries/countries.vue";
import ViewCities from "@/views/Manage-Countries/view-cities.vue";

import MergeCities from "@/views/Manage-Cities/merge-cities.vue";
import AddAdmin from "@/views/Admin/add-admin.vue";

import Commentry from "@/views/Manage-Commentry/commentry.vue";
import AddCommentry from "@/views/Manage-Commentry/add-commentry.vue";

import Positions from "@/views/Manage-Positions/positions.vue";
import AddPositions from "@/views/Manage-Positions/add-positions.vue";

import Package from "@/views/Manage-Package/package.vue";
import AddPackage from "@/views/Manage-Package/add-package.vue";

import Executive from "@/views/Manage-Executive/executive.vue";
import AddExecutive from "@/views/Manage-Executive/add-executive.vue";
import ExecutivePreviewPDF from "@/views/Manage-Executive/executive-pdf-preview.vue";

// import Executives from "@/views/Manage-Executives-Adv/executives.vue";
// import AddExecutives from "@/views/Manage-Executives-Adv/add-executives.vue";

import CustomSearchApp from "@/views/Custom-search-app/custom-search-app.vue";
import AddCustomSearchApp from "@/views/Custom-search-app/edit-custom-search-app.vue";

import CustomSearchWeb from "@/views/Custom-search-web/custom-search-web.vue";
import AddCustomSearchWeb from "@/views/Custom-search-web/edit-custom-search-web.vue";

import Company from "@/views/Manage-Company/company.vue";
import AddCompany from "@/views/Manage-Company/add-company.vue";
import CompanyPreviewPDF from "@/views/Manage-Company/company-pdf-preview.vue";

import News from "@/views/Manage-News/news.vue";
import AddNews from "@/views/Manage-News/add-news.vue";

import Role from "@/views/Role/role.vue";
import AddRole from "@/views/Role/add-role.vue";

import Rss from "@/views/Manage-Rss/rss.vue";
import AddRss from "@/views/Manage-Rss/add-rss.vue";

import Publication from "@/views/Manage-Publication/publication.vue";
import AddPublication from "@/views/Manage-Publication/add-publication.vue";

import Demo from "@/views/Manage-Demo/demo.vue";

import University from "@/views/Manage-University/university.vue";
import AddUniversity from "@/views/Manage-University/add-university.vue";

import IntrestingBites from "@/views/Manage-Intresting-Bites/intresting-bites.vue";
import AddIntrestingBites from "@/views/Manage-Intresting-Bites/add-intresting-bites.vue";

import DealsTransaction from "@/views/Manage-Deals-Transaction/deals-transaction.vue";
import AddDealsTransaction from "@/views/Manage-Deals-Transaction/add-deals-transaction.vue";


import Cluster from "@/views/Manage-Cluster/cluster.vue";
import AddCluster from "@/views/Manage-Cluster/add-cluster.vue";

import Region from "@/views/Manage-Region/region.vue";
import AddRegion from "@/views/Manage-Region/add-region.vue";

import OpenPosition from "@/views/Manage-open-position/open-position.vue";
import AddOpenPosition from "@/views/Manage-open-position/add-open-position.vue";

import FundingSeries from "@/views/Manage-Deal-Stage/funding_series.vue";
import AddFundingSeries from "@/views/Manage-Deal-Stage/add-funding_series.vue";

import QA from "@/views/Manage-QA/qa.vue";
import AddQA from "@/views/Manage-QA/add-qa.vue";

import MainView from "@/views/main.vue"

const routes = [{
    path: "",
    redirect: Login,
},
{
    path: "/login",
    name: "Login",
    component: Login,
},
{
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
},
{
    path: "/otp",
    name: "OTP",
    component: OTP,
},
{
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
},

{
    path: '',
    name: "Main",
    component: MainView,
    meta: {
        requiresAuth: true
    },
    children: [
        // =============== Admin ===============
        {
            path: "/admin",
            name: "Admin",
            component: Admin
        },
        {
            path: "/add-admin",
            name: "Add Admin",
            component: AddAdmin
        },
        {
            path: "/edit-admin/:id",
            name: "Edit Admin",
            component: AddAdmin
        },
        // =============== Reuested Demo ===============
        {
            path: "/demo",
            name: "Demo",
            component: Demo
        },
        // =============== Manage Company ===============
        {
            path: "/company",
            name: "Company",
            component: Company
        },
        {
            path: "/add-company",
            name: "Add Company",
            component: AddCompany
        },
        {
            path: "/edit-company/:id",
            name: "Edit Company",
            component: AddCompany
        },
        {
            path: "/preview-pdf/:id",
            name: "Company Preview PDF",
            component: CompanyPreviewPDF
        },
        // =============== Manage News ===============
        {
            path: "/news",
            name: "News",
            component: News
        },
        {
            path: "/add-news",
            name: "Add News",
            component: AddNews
        },
        {
            path: "/edit-news/:id",
            name: "Edit News",
            component: AddNews
        },
        //===========Manage Customer========
        {
            path: "/customer",
            name: "Customer",
            component: Customer,
        },
        {
            path: "/edit-customer/:id",
            name: "Edit Customer",
            component: AddCustomer,
        },
        //===========Manage RSS========
        {
            path: "/rss",
            name: "Rss",
            component: Rss,
        },
        {
            path: "/add-rss",
            name: "Add Rss",
            component: AddRss,
        },
        //===========Custom Search App========
        {
            path: "/custom-search-app",
            name: "Custom Search App",
            component: CustomSearchApp,
        },
        {
            path: "/edit-custom-search-app/:id",
            name: "Edit Custom Search App",
            component: AddCustomSearchApp,
        },
        //===========Custom Search Web========
        {
            path: "/custom-search-web",
            name: "Custom Search Web",
            component: CustomSearchWeb,
        },
        {
            path: "/edit-custom-search-web/:id",
            name: "Edit Custom Search Web",
            component: AddCustomSearchWeb,
        },
        //===========My profile========
        {
            path: "/my-profile",
            name: "MyProfile",
            component: MyProfile,
        },
        //===========Manage Settings========
        {
            path: "/settings",
            name: "Settings",
            component: Settings,
        },
        //===========Manage Logs========
        {
            path: "/logs",
            name: "Logs",
            component: Logs,
        },
        //===========Manage Cities========
        {
            path: "/cities",
            name: "Cities",
            component: Cities,
        },
        {
            path: "/merge-cities/:id",
            name: "Merge Cities",
            component: MergeCities,
        },
        //===========Manage Package Group========
        {
            path: "/package-group",
            name: "Package Group",
            component: PackageGroup,
        },
        {
            path: "/add-package-group",
            name: "Add Package Group",
            component: AddPackageGroup,
        },
        {
            path: "/edit-package-group/:id",
            name: "Edit Package Group",
            component: AddPackageGroup,
        },
        //===========Manage Industry========
        {
            path: "/industry",
            name: "Industry",
            component: Industry,
        },
        {
            path: "/add-industry",
            name: "Add Industry",
            component: AddIndustry,
        },
        {
            path: "/edit-industry/:id",
            name: "Edit Industry",
            component: AddIndustry,
        },
        //===========Manage Publication========
        {
            path: "/publication",
            name: "Publication",
            component: Publication,
        },
        {
            path: "/add-publication",
            name: "Add Publication",
            component: AddPublication,
        },
        {
            path: "/edit-publication/:id",
            name: "Edit Publication",
            component: AddPublication,
        },
        //===========Manage University========
        {
            path: "/university",
            name: "University",
            component: University,
        },
        {
            path: "/add-university",
            name: "Add University",
            component: AddUniversity,
        },
        {
            path: "/edit-university/:id",
            name: "Edit University",
            component: AddUniversity,
        },
        //===========Manage Intresting Bites========
        {
            path: "/intresting-bites",
            name: "IntrestingBites",
            component: IntrestingBites,
        },
        {
            path: "/add-intresting-bites",
            name: "Add IntrestingBites",
            component: AddIntrestingBites,
        },
        {
            path: "/edit-intresting-bites/:id",
            name: "Edit IntrestingBites",
            component: AddIntrestingBites,
        },
        //===========Manage Deals Transaction========
        {
            path: "/deals-transaction",
            name: "Deals Transaction",
            component: DealsTransaction,
        },
        {
            path: "/add-deals-transaction",
            name: "Add Deals Transaction",
            component: AddDealsTransaction,
        },
        {
            path: "/edit-deals-transaction/:id",
            name: "Edit Deals Transaction",
            component: AddDealsTransaction,
        },
        //===========Manage Cluster========
        {
            path: "/cluster",
            name: "cluster",
            component: Cluster,
        },
        {
            path: "/add-cluster",
            name: "Add cluster",
            component: AddCluster,
        },
        {
            path: "/edit-cluster/:id",
            name: "Edit Cluster",
            component: AddCluster,
        },
        //===========Manage Commentry========
        {
            path: "/commentry",
            name: "Commentry",
            component: Commentry,
        },
        {
            path: "/add-commentry",
            name: "Add Commentry",
            component: AddCommentry,
        },
        {
            path: "/edit-commentry/:id",
            name: "Edit Commentry",
            component: AddCommentry,
        },
        {
            path: "/edit-commentry/:id/:type",
            name: "Edit Commentry New",
            component: AddCommentry,
        },
        //===========Manage Positions========
        {
            path: "/positions",
            name: "Positions",
            component: Positions,
        },
        {
            path: "/add-positions",
            name: "Add Positions",
            component: AddPositions,
        },
        {
            path: "/edit-positions/:id",
            name: "Edit Positions",
            component: AddPositions,
        },
        //===========Manage Package========
        {
            path: "/package",
            name: "Package",
            component: Package,
        },
        {
            path: "/add-package",
            name: "Add Package",
            component: AddPackage,
        },
        {
            path: "/edit-Package/:id",
            name: "Edit Package",
            component: AddPackage,
        },
        //===========Manage Executive========
        {
            path: "/executive",
            name: "Executive",
            component: Executive,
        },
        {
            path: "/add-executive",
            name: "Add Executive",
            component: AddExecutive,
        },
        {
            path: "/edit-executive/:id",
            name: "Edit PacExecutivekage",
            component: AddExecutive,
        },
        {
            path: "/preview-pdf-executive/:id",
            name: "Executive Preview PDF",
            component: ExecutivePreviewPDF
        },
        
        //===========Manage Executives========
        // {
        //     path: "/executives",
        //     name: "Executives",
        //     component: Executives,
        // },
        // {
        //     path: "/add-executives",
        //     name: "Add Executives",
        //     component: AddExecutives,
        // },
        // {
        //     path: "/edit-executives/:id",
        //     name: "Edit Executives",
        //     component: AddExecutives,
        // },
        //===========Manage Industry========
        {
            path: "/function",
            name: "Function",
            component: Function,
        },
        {
            path: "/add-function",
            name: "Add Function",
            component: AddFunction,
        },
        {
            path: "/edit-function/:id",
            name: "Edit Function",
            component: AddFunction,
        },
        //===========Manage QA========
        {
            path: "/qa",
            name: "QA",
            component: QA,
        },
        {
            path: "/add-qa",
            name: "Add QA",
            component: AddQA,
        },
        {
            path: "/edit-qa/:id",
            name: "Edit QA",
            component: AddQA,
        },
        //===========Manage Region========
        {
            path: "/region",
            name: "Region",
            component: Region,
        },
        {
            path: "/add-region",
            name: "Add Region",
            component: AddRegion,
        },
        {
            path: "/edit-region/:id",
            name: "Edit Region",
            component: AddRegion,
        },
        //===========Manage Topic ========
        {
            path: "/topic",
            name: "Topic",
            component: Topic,
        },
        {
            path: "/add-topic",
            name: "Add Topic",
            component: AddTopic,
        },
        {
            path: "/edit-topic/:id",
            name: "Edit Topic",
            component: AddTopic,
        },
        //===========Manage Jobtitle ========
        {
            path: "/job-title",
            name: "Jobtitle",
            component: Jobtitle,
        },
        {
            path: "/add-job-title",
            name: "Add Jobtitle",
            component: AddJobtitle,
        },
        {
            path: "/edit-job-title/:id",
            name: "Edit Jobtitle",
            component: AddJobtitle,
        },
        //===========Manage-open-position========

        {
            path: "/open-position",
            name: "OpenPosition",
            component: OpenPosition,
        },
        {
            path: "/add-open-position",
            name: "Add Open Position",
            component: AddOpenPosition,
        },
        {
            path: "/edit-open-position/:id",
            name: "Edit Open Position",
            component: AddOpenPosition,
        },
        //===========Manage Country s ========
        {
            path: "/countries",
            name: "Countries",
            component: Countries,
        },
        {
            path: "/view-countries/:id",
            name: "ViewCities",
            component: ViewCities,
        },
        // {
        //     path: "/edit-job-title/:id",
        //     name: "Edit Jobtitle",
        //     component: AddJobtitle,
        // },
        // =============== Role ===============
        {
            path: "/role",
            name: "Role",
            component: Role
        },
        {
            path: "/add-role",
            name: "Add Role",
            component: AddRole
        },
        {
            path: "/edit-role/:id",
            name: "Edit Role",
            component: AddRole
        },
        //===========Manage FUNDING SERIES========
        {
            path: "/funding_series",
            name: "FundingSeries",
            component: FundingSeries,
        },
        {
            path: "/add-funding_series",
            name: "Add FundingSeries",
            component: AddFundingSeries,
        },
        {
            path: "/edit-funding_series/:id",
            name: "Edit FundingSeries",
            component: AddFundingSeries,
        },       
        {
            path: "/edit-funding_series/:id/:type",
            name: "Edit FundingSeries New",
            component: AddFundingSeries,
        },


    ]
}
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        if (to.path === '/login') {
            if (localStorage.getItem('token') != null) {
                next({
                    path: '/admin',
                    params: { nextUrl: to.fullPath }
                })
            } else {
                next()
            }
        } else {
            next()
        }
    }
});

export default router;