<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Custom Search Web</h4>
          </div>
          <!-- <div class="col-6 text-right">
            <a
              style="padding-left: 20px; padding-right: 20px;cursor: pointer;"
              v-on:click="$router.push('/edit-customer')"
              class="add-btn"
              >Add</a
            >
          </div> -->
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-12 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <div class="col-lg-2 col-sm-2">
                  <!-- <label>Update Status</label> -->
                  <Field
                    as="select"
                    class="form-control"
                    id="inputRole"
                    name="industry"
                    rules="required: status"
                    v-model="searchObj.status"
                  >
                    <option value="">Select Status</option>
                    <option value="P">Pending</option>
                    <option value="C">Completed</option>
                  </Field>
                  <ErrorMessage name="industry" class="text-danger" />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  >Reset</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('id', 'id-name')"
                          >
                            Sr No
                          </th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('tbl_users.first_name', 'id-name')"
                          >
                            User Name
                          </th>
                          <th
                            class="sorting"
                            id="id-platform"
                            v-on:click="sorting('tbl_users.company_name', 'id-platform')"
                          >
                            Company Name
                          </th>
                          <th
                            class="sorting"
                            id="id-platform"
                            v-on:click="sorting('age_range', 'id-platform')"
                          >
                            Experience
                          </th>
                          <th
                            class="sorting"
                            id="id-created_date"
                            v-on:click="
                              sorting('created_date', 'id-created_date')
                            "
                          >
                            Requested Date
                          </th>
                          <th>Status</th>
                          <th v-if="accessObject?.is_view == 1">Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td v-if="data.user_id">
                            {{ data.user.first_name }} {{ data.user.last_name }}
                          </td>
                          <td v-else>
                            {{ data.first_name }} {{ data.last_name }}
                          </td>
                          <td>{{ data.company }}</td>
                          <td>{{ data.age_range }}</td>
                          <td>{{ data.created_date }}</td>
                          <td>
                            <a
                              v-show="data.status == 'P'"
                              id="status-btn"
                              class="edit-btn btn-style"
                              >PENDING</a
                            >
                            <a
                              v-show="data.status == 'C'"
                              id="status-btn"
                              class="fill-btn btn-style"
                              >CONTACTED</a
                            >
                          </td>
                          <td v-if="accessObject?.is_view == 1">
                            <a
                              v-if="accessObject?.is_view == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push(
                                  '/edit-custom-search-web/' + data.id
                                )
                              "
                              >View</a
                            >
                            <a
                              v-show="accessObject?.is_edit == 1"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showChangePasswordPopup(data.id)"
                              >Update Status</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Update Status</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="changePasswordForm" @submit="updateStatus">
      <div class="col-lg-12 col-sm-12 mt-3 mb-3">
        <label>Update Status</label>
        <Field
          as="select"
          class="form-control"
          id="inputRole"
          name="industry"
          rules="required: status"
          v-model="detail.status"
        >
          <option value="">Select Status</option>
          <option value="P">Pending</option>
          <option value="C">Completed</option>
        </Field>
        <ErrorMessage name="industry" class="text-danger" />
      </div>
      <!-- <div class="form-group col-12">
      <label>Note</label>
      <textarea
        type="text"
        name="Current_Responsibility"
        class="form-control"
        placeholder=""
        v-model="detail.note"
      >
      </textarea>
    </div> -->
      <div class="modal-footer pb-0">
        <button
          class="fill-btn"
          id="update-status-button"
          style="width: 20%; border-radius: 5px"
        >
          Save
        </button>
        <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "Customer",
  components: {
    ErrorMessage,
    Form,
    Field,
    ErrorComponent,
    DeleteModal,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel(
        "Manage Custom Search Web"
      ),
      loader: true,
      searchObj: {},
      offset: -1,
      detail: {
        status: "",
      },
      list: [],
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    showChangePasswordPopup(id) {
      this.detail.id = id;
      this.detail.status = "";
      this.$refs.textModal.showModal();
      // this.$refs.updatestatus.resetForm();
    },
    updateStatus() {
      this.$api
        .putAPI({
          _action: "/custom-web",
          _body: this.detail,
          _buttonId: "update-status-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/custom/search/web/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject = this.$helperService.getAccessLevel(
            "Manage Custom Search Web"
          );
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No customer list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.searchObj.status = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Custum Search",
        "Are you sure you want to delete " +
          data.first_name +
          " " +
          data.last_name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/customer/" + detail.id);
    },
  },
};
</script>
