<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/cluster')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Listing</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <!-- <div class="col-lg-4 col-sm-6"> -->
                        <div class="col-6 form-group">
                          <label>Name Of List </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="source"
                            class="form-control"
                            placeholder="Name Of List"
                            v-model="detail.name"
                            rules="required:name of list"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="source" class="text-danger" />
                        </div>

                        <div class="col-6 form-group">
                          <label>Source url</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="Headline"
                            class="form-control"
                            placeholder="Headline"
                            v-model="detail.source_url"
                            rules="required:  source url|website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Headline" class="text-danger" />
                        </div>
                        <div class="col-6 form-group row">
                          <div class="col-8">
                            <label>Publication Name </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="publication_id"
                              placeholder="Select Publication"
                              rules="required: publication"
                              v-model="detail.publication_id"
                              :validateOnInput="true"
                              v-slot="{ field }"
                            >
                              <Multiselect
                                mode="single"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                placeholder="Select Publication"
                                v-model="detail.publication_id"
                                :options="publicationList"
                                searchable="true"
                                @search-change="searchpublicationName($event)"
                                v-bind="field"
                              />
                            </Field>
                            <ErrorMessage
                              name="publication_id"
                              class="text-danger"
                            />
                          </div>
                          <div
                            class="col-4"
                            style="margin-top: 39px; cursor: pointer"
                            v-on:click="showPublicationPopup()"
                          >
                            <img src="/img/plus.png" width="30" height="30" />
                          </div>
                        </div>
                        <div class="form-group col-6" style="margin-left: 29px">
                          <label>Job Function </label>
                          <!-- <span class="text-danger">*</span> -->
                          <!-- <Field
                            type="text"
                            placeholder="Select"
                            name="job_function_new"
                            v-model="detail.job_function_id"
                            rules="required:  job function"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          > -->
                          <Multiselect
                            mode="single"
                            trackBy="job_function_name"
                            label="job_function_name"
                            valueProp="id"
                            placeholder="Select"
                            v-model="detail.job_function_id"
                            :options="jobFunctionList"
                            searchable="true"
                            @search-change="searchJobFuncationName($events)"
                          />
                          <!-- v-bind="field" -->
                          <!-- </Field>
                          <ErrorMessage
                            name="job_function_new"
                            class="text-danger"
                          /> -->
                        </div>
                        <div class="form-group col-6">
                          <label> Industry</label>
                          <!-- <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="industry_id_new"
                            placeholder="Select"
                            rules="required:  industry"
                            v-model="detail.industry_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          > -->
                          <Multiselect
                            style="margin-top: -3px"
                            mode="single"
                            trackBy="industry_name"
                            label="industry_name"
                            valueProp="id"
                            placeholder="Select"
                            v-model="detail.industry_id"
                            :options="industryList"
                            searchable="true"
                            @change="closesub($event)"
                            @input="onIndustryChange"
                          />
                          <!-- v-bind="field" -->
                          <!-- </Field>
                          <ErrorMessage
                            name="industry_id_new"
                            class="text-danger"
                          /> -->
                        </div>
                        <div v-if="showSubsectorDropdown" class="form-group col-6">
                              <label> Subsector</label>
                              <Field
                                type="text"
                                name="subsector_id"
                                placeholder="Select"
                                v-model="detail.subsector_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  style="margin-top: -3px"
                                  mode="single"
                                  trackBy="industry_name"
                                  label="industry_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.subsector_id"
                                  :options="subsectorsList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                        <div class="col-6 form-group">
                          <label>Country</label>
                          <!-- <span class="text-danger">*</span> -->
                          <!-- <Field
                            type="text"
                            name="country_name"
                            placeholder="Select country"
                            rules="required: country"
                            v-model="detail.country_id"
                            :validateOnInput="true"
                            v-on:change="CityList(detail.country_id)"
                            v-slot="{ field }"
                          > -->
                          <Multiselect
                            mode="single"
                            trackBy="country_name"
                            label="country_name"
                            valueProp="id"
                            placeholder="Select Country"
                            v-model="detail.country_id"
                            :options="countryList"
                            searchable="true"
                          />
                          <!-- v-bind="field" -->
                          <!-- </Field>
                          <ErrorMessage
                            name="country_name"
                            class="text-danger"
                          /> -->
                        </div>

                        <div class="col-6 form-group">
                          <label>City</label>
                          <!-- <span class="text-danger">*</span> -->
                          <Field
                            type="text"
                            name="city_id"
                            placeholder="Select city"
                            v-model="detail.city_id"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="city_name"
                              label="city_name"
                              valueProp="id"
                              v-model="detail.city_id"
                              :options="cityList"
                              searchable="true"
                              placeholder="Select City"
                              @search-change="searchCityName($event)"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="city_id" class="text-danger" />
                        </div>
                        <div class="col-6 form-group">
                          <label for="inputRole">Cluster Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="type"
                            placeholder="Select type"
                            rules="required: type"
                            v-model="detail.type"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="value"
                              label="value"
                              valueProp="id"
                              placeholder="Select Type"
                              v-model="detail.type"
                              :options="cluster_type"
                              searchable="true"
                              @search-change="searchCompanyName($event)"
                              v-bind="field"
                            />
                          </Field>
                          <ErrorMessage name="type" class="text-danger" />
                        </div>
                        <div
                          v-if="detail.type == 'COMPANY'"
                          class="col-6 form-group"
                        >
                          <label for="inputRole">Add Company</label>
                          <span class="text-danger">*</span>
                          <Multiselect
                            mode="tags"
                            trackBy="company_name"
                            label="company_name"
                            valueProp="id"
                            placeholder="Select Company"
                            v-model="cluster_executive_company"
                            :options="companyList"
                            searchable="true"
                            @search-change="searchCompanyName($event)"
                          >
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.company_logo
                                    ? option.company_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.company_name }}
                            </template>
                          </Multiselect>
                        </div>
                        <div
                          v-if="detail.type == 'EXECUTIVE'"
                          class="col-6 form-group"
                        >
                          <label for="inputRole">Add Executive</label>
                          <span class="text-danger">*</span>
                          <Multiselect
                            mode="tags"
                            trackBy="executive_name"
                            label="executive_name"
                            valueProp="id"
                            placeholder="Select Executive"
                            v-model="cluster_executive_company"
                            :options="executiveList"
                            searchable="true"
                            @search-change="
                              searchexecutiveName($event, 'FALSE')
                            "
                          >
                            <template v-slot:option="{ option }">
                              <div class="col-12 row" style="padding: 0">
                                <div class="col-11">
                                  <img
                                    class="option-icon"
                                    :src="
                                      option.executive_logo
                                        ? option.executive_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                  {{ option.executive_name }}
                                </div>
                                <div class="col-1">
                                  <img
                                    class="option-icon2"
                                    :src="
                                      option.new_company &&
                                      option.new_company.company_logo
                                        ? option.new_company.company_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                </div>
                              </div>
                            </template>
                          </Multiselect>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Add Publication</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="addPubliction" @submit="addPubliction()">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label>Publication Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="Add_Publication"
            class="form-control"
            placeholder="Publication Name"
            v-model="details.name"
            rules="required: publication name"
            :validateOnInput="true"
          />
          <ErrorMessage name="user_name" class="text-danger" />
        </div>
        <div class="col-6 form-group">
          <label>Website Url</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="website_url"
            class="form-control"
            placeholder="Website Url"
            v-model="details.website_url"
            rules="required: website url |website"
            :validateOnInput="true"
          />
          <ErrorMessage name="website_url" class="text-danger" />
        </div>
        <div class="col-6 mt-3 mb-3">
          <label>Publication Logo</label>
          <div class="text-left">
            <ImagePicker
              :src="details.publication_logo"
              :modelValue="details.publication_logo"
              ref="ImagePicker"
            />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button id="publication-button" class="save-btn px-4 ml-3">
            Save
          </button>
          <button
            type="button"
            class="fil-btn px-4 ml-3"
            v-on:click="$refs.textModal.closeModal"
            id="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import ImagePicker from "@/components/ImagePicker.vue";
import TextModal from "@/components/TextModal";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    ImagePicker,
    ErrorComponent,
    TextModal,
  },
  data() {
    return {
      loader: false,
      showSubsectorDropdown: false,
      id: this.$route.params.id,
      detail: {
        city_id: "",
        country_id: "",
        industry_id: "",
        job_function_id: "",
        subsector_id: "",
      },
      details: {},
      cluster_type: ["COMPANY", "EXECUTIVE"],
      companyList: [],
      jobFunctionList: [],
      cluster_executive_company: [],
      executiveList: [],
      countryList: [],
      cityList: [],
      city_ids: [],
      publicationList: [],
      industryList: [],
      company_ids: [],
      executive_ids: [],
      subsectorsList: [],
      companyKeyword: "",
      jobFunctionKeyword: "",
      executiveKeyword: "",
      cityKeyword: "A",
      publicationKeyword: "",
    };
  },
  mounted() {
    if (!this.id) {
      this.executivelist("TRUE");
    } else {
      this.executivelist();
    }
    this.companylist();
    this.CountryList();
    this.jobFunctionlist();
    this.publicationlist();
    this.industrylist();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchexecutiveName(e, type) {
      this.executiveKeyword = e;
      this.executivelist(type);
    },
    searchpublicationName(e) {
      this.publicationKeyword = e;
      this.publicationlist();
    },
    searchJobFuncationName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    jobFunctionlist(keyword) {
      var obj = {};
      if (keyword) {
        obj.keyword = keyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
          _body: obj,
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    publicationlist() {
      var obj = {};
      if (this.publicationKeyword) {
        obj.keyword = this.publicationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/publications/list",
          _body: obj,
        })
        .then((res) => {
          this.publicationList = res;
        })
        .catch(() => {});
    },

    getDetail() {
      this.$api
        .getAPI({
          _action: "/cluster/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;

          if (this.detail.city_id) {
            this.city_ids.push(this.detail.city_id);
          }

          if (this.detail.cluster_executive_company.length > 0) {
            for (var i in this.detail.cluster_executive_company) {
              if (this.detail.type == "EXECUTIVE") {
                this.executive_ids.push(
                  this.detail.cluster_executive_company[i].refferece_id
                );
              }
              if (this.detail.type == "COMPANY") {
                this.company_ids.push(
                  this.detail.cluster_executive_company[i].refferece_id
                );
              }
            }
          }
          
          if(res.industry_id) {
            this.getSubsectorList(res.industry_id);
          }
          this.searchCompanyName();
          this.searchCityName();
          this.searchexecutiveName(null, "TRUE");
        })
        .catch(() => {});
    },
    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    CityList(country_id) {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      if (this.city_ids.length > 0) {
        obj.city_ids = this.city_ids;
      }
      if (country_id) {
        obj.country_id = country_id;
        // for (var i in this.countryList) {
        //   if (country_id == this.countryList[i].id) {
        //     this.detail.country_code = this.countryList[i].country_code;
        //     this.detail.region_id = this.countryList[i].region_id;
        //   }
        // }
      }
      if (this.detail.country_id) {
        obj.country_id = this.detail.country_id;
      }
      this.$api
        .getAPI({
          _action: "/city/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
          this.city_ids = [];
        })
        .catch(() => {});
    },
    closesub(e) {
      if (!e) {
        this.detail.subsector_id = "";
      }
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.showSubsectorDropdown = false;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.showSubsectorDropdown = false;
      }
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => { });
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorsList = res.list;
          
          if (this.subsectorsList.length > 0) {
            this.showSubsectorDropdown = true;
          } else {
            this.showSubsectorDropdown = false;
          }
        })
        .catch(() => { });
    },
    executivelist(type) {
      if (!type || type == "TRUE") {
        this.$refs.errorComponent.updateFormLoader(true);
      }
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      if (this.executive_ids.length > 0) {
        obj.executive_ids = this.executive_ids;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList = res;
          this.executive_ids = [];

          if (!type || type == "TRUE") {
            if (this.detail.cluster_executive_company.length > 0) {
              this.cluster_executive_company = [];
              for (var i in this.detail.cluster_executive_company) {
                this.cluster_executive_company.push(
                  this.detail.cluster_executive_company[i].refferece_id
                );
              }
            }
          }
          if (type) {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          this.company_ids = [];
        })
        .catch(() => {});
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.detail.published_date) {
        this.detail.published_date = moment(this.detail.published_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.cluster_executive_company.length > 0) {
        this.detail.cluster_executive_company = this.cluster_executive_company;
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/cluster",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/cluster",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showPublicationPopup() {
      this.$refs.textModal.showModal();
      this.$refs.addPubliction.resetForm();
    },
    addPubliction() {
      this.details.package_group_type = "web";
      this.$api
        .postAPI({
          _action: "/publications",
          _body: this.details,
          _buttonId: "publication-button",
        })
        .then((res) => {
          if (!this.$refs.ImagePicker.file) {
            this.detail.publication_id = res.id;
            this.publicationlist();
            this.$refs.textModal.closeModal();
          } else {
            this.uploadImage(res.id);
          }
        })
        .catch(() => {});
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.detail.publication_id = res.id;
          this.publicationlist();
          this.$refs.textModal.closeModal();
        });
    },
    onChange(e) {
      let files = e.target.files;

      let reader = new FileReader();

      reader.readAsDataURL(files[0]);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          var width = img.width;
          var height = img.height;
          if (width > 500 || height > 500) {
            alert("Image should be of 500 x 500 Pixcel");
            return;
          } else {
            if (
              files[0].type.toLowerCase() != "image/jpeg" &&
              files[0].type.toLowerCase() != "image/png"
            ) {
              alert("Invalid file formate, please use jpeg or png file.");
              return;
            }
            // const size = (files[0].size / 1024 / 1024).toFixed(2);
            // if (size > 10) {
            //   alert("File must be less then 10 MB");
            //   return;
            // }
            var tmppath = URL.createObjectURL(files[0]);
            this.file = files[0];
            document.getElementById("preview").src = tmppath;
          }
        };

        img.src = evt.target.result;
      };
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon2 {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-left: 20px;
}
</style>
