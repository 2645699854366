<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/product')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">Edit Settings</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save">
                      <div class="">
                        <div class="form-group col-md-4">
                          <label for="inputRole">Is Plan Accessible</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="name_brand"
                            :disabled="accessObject?.is_edit != 1"
                            v-model="detail.is_plan_accessible"
                          >
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </Field>
                        </div>
                        <div
                          class="col-lg-2 col-sm-12 text-center mt-5"
                          v-if="accessObject?.is_edit == 1"
                        >
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
export default {
  name: "settings",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Settings"),
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/setting/detail",
        })
        .then((res) => {
          if (res) {
            this.detail = res;
            this.accessObject =
              this.$helperService.getAccessLevel("Manage Settings");
          }
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .putAPI({
          _action: "/setting",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>