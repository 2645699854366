<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/package')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} package</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="">
                        <div class="col-12 col-sm-12 row">
                          <!-- <div class="form-group col-6 col-6">
                            <label for="inputRole">Package Group Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="Company"
                              rules="required:company"
                              :validateOnInput="true"
                              v-model="detail.package_group_id"
                            >
                              <option value="">Select Package Group</option>
                              <option
                                v-for="(data, index) in packageGroupList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.package_group_name }}
                              </option>
                            </Field>
                            <ErrorMessage name="Company" class="text-danger" />
                          </div>
                          <div class="form-group col-6 col-6">
                            <label for="inputRole">Package For</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="package_for"
                              rules="required:package type"
                              :validateOnInput="true"
                              v-model="detail.package_type"
                            >
                              <option value="">Select Package For</option>
                              <option value="web">Web</option>
                              <option value="app">App</option>
                            </Field>
                            <ErrorMessage
                              name="package_for"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6">
                            <label for="inputRole">Duration Type</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="duration_type"
                              rules="required:duration type"
                              :validateOnInput="true"
                              v-model="detail.duration_type"
                            >
                              <option value="">Select Duration Type</option>
                              <option value="D">Day</option>
                              <option value="M">Month</option>
                            </Field>
                            <ErrorMessage
                              name="duration_type"
                              class="text-danger"
                            />
                          </div> -->
                          <div class="form-group col-6">
                            <label for="inputRole">Package Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="package_name"
                              placeholder="package name"
                              rules="required:package name"
                              :validateOnInput="true"
                              v-model="detail.package_name"
                            />
                            <ErrorMessage
                              name="package_name"
                              class="text-danger"
                            />
                          </div>
                          <!-- <div class="form-group col-6">
                            <label for="inputRole"> Sub Title </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="subtitle"
                              placeholder="subtitle"
                              rules="required:subtitle"
                              :validateOnInput="true"
                              v-model="detail.subtitle"
                            />
                            <ErrorMessage name="subtitle" class="text-danger" />
                          </div> -->

                          <div class="form-group col-6">
                            <label for="inputRole">Package Cost</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="package_cost"
                              placeholder="Package Cost"
                              rules="required:package cost"
                              :validateOnInput="true"
                              v-model="detail.package_cost"
                            />
                            <ErrorMessage
                              name="package_cost"
                              class="text-danger"
                            />
                          </div>
                          <!-- <div class="form-group col-6">
                            <label for="inputRole">Validity</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="Validity"
                              placeholder="Validity"
                              rules="required:validity"
                              :validateOnInput="true"
                              v-model="detail.validity"
                            />
                            <ErrorMessage name="Validity" class="text-danger" />
                          </div> -->
                          <!-- <div class="form-group col-6">
                            <label for="inputRole">No of view </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="no_of_view"
                              placeholder="No of view "
                              rules="required:no of view "
                              :validateOnInput="true"
                              v-model="detail.view_cnt"
                            />
                            <ErrorMessage
                              name="no_of_view"
                              class="text-danger"
                            />
                          </div> -->
                          <!-- <div class="form-group col-6">
                            <label for="inputRole">No of download</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="No_of_download"
                              placeholder="No of Download "
                              rules="required:no of download"
                              :validateOnInput="true"
                              v-model="detail.download_cnt"
                            />
                            <ErrorMessage
                              name="No_of_download"
                              class="text-danger"
                            />
                          </div> -->
                          <!-- <div class="form-group col-6">
                            <label for="inputRole">No of custom search</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="No_of_custom_search"
                              placeholder="No of custom search "
                              rules="required:no of custom search"
                              :validateOnInput="true"
                              v-model="detail.custom_search_cnt"
                            />
                            <ErrorMessage
                              name="No_of_custom_search"
                              class="text-danger"
                            />
                          </div> -->
                          <div class="form-group col-6">
                            <label for="inputRole">No of Executive</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              id="inputRole"
                              name="No_of_Executive_Bio"
                              placeholder="No of Executive"
                              rules="required:no of executive"
                              :validateOnInput="true"
                              v-model="detail.exec_bio_cnt"
                            />
                            <ErrorMessage
                              name="No_of_Executive_Bio"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6">
                            <label>No of Company</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              name="No_of_Full_Profile"
                              placeholder="No of Company"
                              rules="required:no of company"
                              :validateOnInput="true"
                              v-model="detail.full_profile_cnt"
                            />
                            <ErrorMessage
                              name="No_of_Full_Profile"
                              class="text-danger"
                            />
                          </div>
                          <!-- <div class="form-group col-6 col-6">
                            <label for="inputRole">Is Monthly Request </label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="monthly_full_request_cnt"
                              rules="required:monthly full request"
                              :validateOnInput="true"
                              v-model="detail.is_monthly"
                            >
                              <option value="">Select Request</option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                            <ErrorMessage
                              name="monthly_full_request_cnt"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6 col-6">
                            <label for="inputRole">User Type </label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="user_type"
                              rules="required:user type"
                              :validateOnInput="true"
                              v-model="detail.user_type"
                            >
                              <option value="">Select User Type</option>
                              <option value="single">Single</option>
                              <option value="multiple">Multiple</option>
                            </Field>
                            <ErrorMessage
                              name="user_type"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6 col-6">
                            <label for="inputRole"
                              >Allow Executive Search
                            </label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="Allow_Executive_Search"
                              rules="required:allow executive search"
                              :validateOnInput="true"
                              v-model="detail.is_exec_search"
                            >
                              <option value="">
                                Select Allow Executive Search
                              </option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                            <ErrorMessage
                              name="Allow_Executive_Search"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6 col-6">
                            <label for="inputRole">Allow Company Search </label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="allow_company_search"
                              rules="required:allow company search"
                              :validateOnInput="true"
                              v-model="detail.is_comp_search"
                            >
                              <option value="">
                                Select Allow Company Search
                              </option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                            <ErrorMessage
                              name="allow_company_search"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6 col-6">
                            <label for="inputRole">Allow News Search </label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="Allow_News_Search"
                              rules="required:allow news search"
                              :validateOnInput="true"
                              v-model="detail.is_news_search"
                            >
                              <option value="">Select Allow News Search</option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                            <ErrorMessage
                              name="Allow_News_Search"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group col-6 col-6">
                            <label for="inputRole">Allow Custom Search </label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="Allow_Custom_Search"
                              rules="required:allow custom search"
                              :validateOnInput="true"
                              v-model="detail.is_custom_search"
                            >
                              <option value="">
                                Select Allow Custom Search
                              </option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                            <ErrorMessage
                              name="Allow_Custom_Search"
                              class="text-danger"
                            />
                          </div> -->
                          <!-- <div class="form-group col-12">
                            <label>Description</label> -->
                          <!-- <span class="text-danger">*</span> -->
                          <!-- <textarea
                              type="text"
                              name="Description"
                              class="form-control"
                              placeholder="Comment"
                              v-model="detail.description"
                            >
                            </textarea>
                          </div> -->
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";

export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      packageGroupList: [],
      loader: false,
    };
  },
  mounted() {
    this.packageList();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/package/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    packageList() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/package/group/list",
        })
        .then((res) => {
          this.packageGroupList = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/package",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/package",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
  },
};
</script>
