<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/admin')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "Edit" : "Add" }} Open Position
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>Job Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="Job_Title"
                              class="form-control"
                              placeholder="Job Title"
                              v-model="detail.job_title"
                              rules="required:job title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="Job_Title"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group">
                            <label>URL</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="url"
                              class="form-control"
                              placeholder="url"
                              v-model="detail.url"
                              rules="required:url"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="url" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label for="inputRole">Company Name</label>
                            <span class="text-danger">*</span>
                            <Multiselect
                              mode="single"
                              trackBy="company_name"
                              label="company_name"
                              valueProp="id"
                              placeholder="Select Company"
                              v-model="detail.company_id"
                              :options="companyList"
                              searchable="true"
                              @search-change="searchCompanyName($event)"
                            >
                              <template v-slot:option="{ option }">
                                <img
                                  class="option-icon"
                                  :src="
                                    option.company_logo
                                      ? option.company_logo
                                      : '/img/user-pic.png'
                                  "
                                />
                                {{ option.company_name }}
                              </template>
                            </Multiselect>
                            <!-- <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="Company"
                              rules="required:company"
                              :validateOnInput="true"
                              v-model="detail.company_id"
                            >
                              <option value="">Select Company</option>
                              <option
                                v-for="(data, index) in companyList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.company_name }}
                              </option>
                            </Field> -->
                            <!-- <ErrorMessage name="Company" class="text-danger" /> -->
                          </div>
                          <div class="form-group">
                            <label for="inputRole">Industry Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="industry"
                              rules="required:industry"
                              :validateOnInput="true"
                              v-model="detail.industry_id"
                            >
                              <option value="">Select Industry</option>
                              <option
                                v-for="(data, index) in industryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.industry_name }}
                              </option>
                            </Field>
                            <ErrorMessage name="industry" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Job Function</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              id="inputRole"
                              class="form-control"
                              name="job_function"
                              rules="required:job function"
                              :validateOnInput="true"
                              v-model="detail.job_function_id"
                            >
                              <option value="">Select Job Function</option>
                              <option
                                v-for="(data, index) in jobFunctionList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.job_function_name }}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="job_function"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group">
                            <label>Seniority Level</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              id="inputRole"
                              class="form-control"
                              name="Seniority"
                              rules="required:seniority level"
                              :validateOnInput="true"
                              v-model="detail.seniority_id"
                            >
                              <option value="">Select Seniority Level</option>
                              <option
                                v-for="(data, index) in senioritylevelList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.seniority_name }}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="Seniority"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group">
                            <label>Country</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              id="inputRole"
                              class="form-control"
                              name="Country"
                              rules="required:country"
                              :validateOnInput="true"
                              v-model="detail.country_id"
                            >
                              <option value="">Select Country</option>
                              <option
                                v-for="(data, index) in countryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.country_name }}
                              </option>
                            </Field>
                            <ErrorMessage name="Country" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>City</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              id="inputRole"
                              class="form-control"
                              name="City"
                              rules="required:city"
                              :validateOnInput="true"
                              v-model="detail.city_id"
                            >
                              <option value="">Select City</option>
                              <option
                                v-for="(data, index) in cityList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.city_name }}
                              </option>
                            </Field>
                            <ErrorMessage name="City" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Relevant Candidates</label>
                            <Field
                              type="text"
                              name="alergic_ingredients"
                              class="form-control"
                              placeholder="Alergic Ingredients"
                              v-model="detail.candidate"
                              v-on:keyup.enter="saveAlergicIngredients()"
                            />
                          </div>
                          <div class="row ml-1">
                            <div
                              style="
                                border: 0px;
                                height: 30px;
                                background: lightgray;
                                border-radius: 5px;
                                margin: 3px;
                              "
                              v-for="(data, index) in detail.candidates"
                              :key="index"
                            >
                              <label style="padding-left: 5px">{{
                                data
                              }}</label>
                              <img
                                src="/img/cross.svg"
                                style="margin-right: 5px; cursor: pointer"
                                v-on:click="removeAlergicIngredients(index)"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    ErrorComponent,
  },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      detail: {
        candidates: [],
      },
      companyList: [],
      topicList: [],
      jobFunctionList: [],
      industryList: [],
      senioritylevelList: [],
      countryList: [],
      cityList: [],
      harsh: [],
      companyKeyword: "",
    };
  },
  mounted() {
    this.companylist();
    this.industrylist();
    this.jobFunctionlist();
    this.topiclist();
    this.seniorityList();
    this.CountryList();
    this.CityList();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    removeAlergicIngredients(index) {
      this.detail.candidates.splice(index, 1);
    },
    saveAlergicIngredients() {
      if (this.detail.candidate) {
        this.detail.candidates.push(this.detail.candidate);
        this.detail.candidate = "";
      } else {
        this.$notify({
          type: "error",
          text: "Please add candidates",
        });
      }
    },
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/open/postion/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    CityList() {
      this.$api
        .getAPI({
          _action: "/city/list",
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    seniorityList() {
      this.$api
        .getAPI({
          _action: "/seniority/list",
        })
        .then((res) => {
          this.senioritylevelList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    topiclist() {
      this.$api
        .getAPI({
          _action: "/topic/list",
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    executivelist() {
      this.$api
        .getAPI({
          _action: "/executive/list",
        })
        .then((res) => {
          this.executiveList = res;
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
        })
        .catch(() => {});
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/open/postion",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/open/postion",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>