<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage QA</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  >Reset</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th
                            class="sorting"
                            style="min-width: 250px"
                            id="id-type"
                            v-on:click="sorting('type', 'id-type')"
                          >
                            Flagged type
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-created_at"
                            v-on:click="sorting('created_at', 'id-created_at')"
                          >
                            Flagged Date
                          </th>
                          <th
                            class="sorting"
                            style="
                              min-width: 180px;
                              white-space: break-spaces !important;
                            "
                            id="id-analyst_note"
                            v-on:click="
                              sorting('analyst_note', 'id-analyst_note')
                            "
                          >
                            Analyst Note
                          </th>
                          <th style="min-width: 180px">Profile URL</th>
                          <th
                            class="sorting"
                            style="min-width: 250px"
                            id="id-Analyst "
                            v-on:click="sorting('analyst_id ', 'id-Analyst')"
                          >
                            Analyst
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 100px"
                            id="id-qa"
                            v-on:click="sorting('qa_id', 'id-qa')"
                          >
                            QA
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 100px; width: 10px"
                            id="id-qa_note"
                            v-on:click="sorting('qa_note', 'id-qa_note')"
                          >
                            QA Note
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 100px"
                            id="id-qa_status"
                            v-on:click="sorting('qa_status', 'id-qa_status')"
                          >
                            Status
                          </th>
                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            {{ data.type }}
                          </td>

                          <td>
                            {{
                              $helperService.sendFormatedDate(
                                data.created_at,
                                "date"
                              )
                            }}
                          </td>
                          <td
                            style="
                              white-space: break-spaces !important;
                              width: 10px;
                            "
                          >
                            {{ data.analyst_note }}
                          </td>
                          <td>
                            <a
                              style="color: blue; cursor: pointer"
                              v-on:click="goToProfile(data)"
                              >View Profile</a
                            >
                          </td>
                          <td>
                            {{
                              data.analyst && data.analyst.admin_name
                                ? data.analyst.admin_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.qa && data.qa.admin_name
                                ? data.qa.admin_name
                                : ""
                            }}
                          </td>
                          <td>{{ data.qa_note }}</td>
                          <td>{{ data.qa_status.replaceAll("_", " ") }}</td>
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="goto(data)"
                              >Edit</a
                            >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showChangePasswordPopup(data.id)"
                              >Update Status</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Update Status</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-lg-12 col-sm-12 mt-3 mb-3">
      <label>Update Status</label>
      <select
        class="form-control"
        id="inputRole"
        name="industry"
        v-model="detail.status"
      >
        <option value="">Select Status</option>
        <option value="PENDING">Pending</option>
        <option value="RESOLVED">Resolve</option>
        <option value="NOT_SURE">Not Sure</option>
      </select>
    </div>
    <div class="form-group col-12">
      <label>Note</label>
      <textarea
        type="text"
        name="Current_Responsibility"
        class="form-control"
        placeholder=""
        v-model="detail.note"
      >
      </textarea>
    </div>
    <div class="modal-footer pb-0">
      <button
        class="fill-btn"
        type="button"
        v-on:click="updateStatus"
        id="update-status-button"
        style="width: 20%; border-radius: 5px"
      >
        Save
      </button>
      <button
        type="button"
        class="fil-btn"
        id="cancel-button"
        v-on:click="$refs.textModal.closeModal"
        style="width: 20%; border-radius: 5px"
      >
        Cancel
      </button>
    </div>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
export default {
  name: "QA",
  components: {
    ErrorComponent,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage QA"),
      loader: true,
      searchObj: {},
      offset: -1,
      status: "",
      detail: {},
      note: "",
      id: "",
      list: [],
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    updateStatus() {
      this.$api
        .putAPI({
          _action: "/update/qa/status",
          _body: this.detail,
          _buttonId: "update-status-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    goto(data) {
      if (data.type == "COMPANY") {
        this.$router.push("/edit-company/" + data.refference_id);
      }
      if (data.type == "EXECUTIVE") {
        this.$router.push("/edit-executive/" + data.refference_id);
      }
      if (data.type == "NEWS") {
        this.$router.push("/edit-news/" + data.refference_id);
      }
    },
    goToProfile(data) {
      if (data.type == "COMPANY") {
        window.open(
          "https://execlibrary.winayak.com/company-detail/" + data.refference_id
        );
      }
      if (data.type == "EXECUTIVE") {
        window.open(
          "https://execlibrary.winayak.com/executive-detail/" +
            data.refference_id
        );
      }
      if (data.type == "NEWS") {
        window.open(
          "https://execlibrary.winayak.com/news/" + data.refference_id
        );
      }
    },
    showChangePasswordPopup(id) {
      this.detail.id = id;
      this.$refs.textModal.showModal();
      // this.$refs.updatestatus.resetForm();
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/qa/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject = this.$helperService.getAccessLevel("Manage QA");

          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No news list available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
  },
};
</script>
