<template>
  <div>
    <div class="wrapper">
      <div
        class="section-authentication-login d-flex align-items-center justify-content-center"
      >
        <div class="row">
          <div class="col-12 col-lg-12 mx-auto">
            <div class="text-center mb-5">
              <img src="/images/logo.png" width="200" height="60" />
            </div>
            <div class="card radius-15">
              <div class="row no-gutters">
                <div class="col-lg-12 col-sm-12 my-auto">
                  <div class="card-body p-md-5">
                    <Form @submit="login">
                      <div class="text-center">
                        <label style="color: black; font-size: 44px"
                          >Login</label
                        >
                      </div>
                      <div class="form-group">
                        <i class="fa fa-envelope mr-1" aria-hidden="true"></i>
                        <label>Email</label>
                        <Field
                          type="text"
                          class="form-control"
                          name="Username"
                          placeholder="Email"
                          v-model="details.username"
                          rules="required:email|email"
                          :validateOnInput="true"
                        />
                        <ErrorMessage name="Username" class="text-danger" />
                      </div>
                      <div class="form-group">
                        <i class="fa fa-lock mr-1" aria-hidden="true"></i>
                        <label>Password</label>
                        <Field
                          type="password"
                          class="form-control"
                          name="_password"
                          placeholder="Password"
                          v-model="details.password"
                          rules="required:password"
                          :validateOnInput="true"
                        />
                        <ErrorMessage name="_password" class="text-danger" />
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <!-- <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch1"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch1"
                            >Remember Me</label
                          >
                        </div> -->
                        </div>
                        <div class="form-group col text-right">
                          <router-link to="/forgot-password">
                            <a style="color: #5b67ca; font-weight: 500"
                              >Forgot Password?</a
                            >
                          </router-link>
                        </div>
                      </div>
                      <div class="btn-group mt-3 w-100">
                        <button
                          class="save-btn"
                          style="width: 383px"
                          id="login-button"
                        >
                          Log In
                        </button>
                      </div>
                      <!-- <router-link to="/signup">
                      <div class="text-center mt-4">
                        <p class="mb-0">
                          Don't have an account?
                          <a class="font-weight-bold">Sign up</a>
                        </p>
                      </div>
                    </router-link> -->
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      details: {
        email: "",
        password: "",
      },
      access: [
        {
          name: "Manage Admins",
          url: "admin",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Roles",
          url: "role",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Company",
          url: "company",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Executive",
          url: "executive",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Executive Adv.",
          url: "executives",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage News",
          url: "news",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Job Title",
          url: "news",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Custom Search App",
          url: "custom-search-app",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Industry",
          url: "industry",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Publication",
          url: "publication",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage University",
          url: "university",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Intresting Bites",
          url: "intresting-bites",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Deals & Transaction",
          url: "deals-transaction",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Package",
          url: "package",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },

        // {
        //   name: "Manage rss",
        //   url: "rss",
        //   is_all: 0,
        //   is_view: 0,
        //   is_add: 0,
        //   is_edit: 0,
        //   is_delete: 0,
        //   is_publish: 0,
        // },
        {
          name: "Manage Customer",
          url: "customer",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Cluster",
          url: "cluster",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Commentry",
          url: "commentry",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Function",
          url: "function",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        // {
        //   name: "Manage Cities",
        //   url: "cities",
        //   is_all: 0,
        //   is_view: 0,
        //   is_add: 0,
        //   is_edit: 0,
        //   is_delete: 0,
        //   is_publish: 0,
        // },
        {
          name: "Manage Countries",
          url: "countries",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Topic",
          url: "topic",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Package Group",
          url: "package-group",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Custom Search Web",
          url: "custom-search-web",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Logs",
          url: "logs",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },

        {
          name: "Manage Settings",
          url: "settings",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Region",
          url: "region",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage QA",
          url: "qa",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Demo",
          url: "demo",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "/login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail();
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/users/me",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: "Logged in successfully",
          });

          if (res.access.length > 0) {
            localStorage.setItem("role", res.admin_type);
            for (var i in res.access) {
              for (var j in this.access) {
                if (res.admin_type == "owner") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 1;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 1;
                    this.access[j].is_publish = 1;
                  }
                }
                if (res.admin_type == "admin") {
                  if (this.access[j].name == res.access[i].name) {
                    console.log(res.access[i].name);
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 1;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 1;
                    this.access[j].is_publish = 1;
                  }
                }
                if (res.admin_type == "quality_analyst") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 0;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 1;
                    this.access[j].is_publish = 1;
                  }
                }
                if (res.admin_type == "data_entry_analyst") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 1;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 0;
                    this.access[j].is_publish = 1;
                  }
                }
              }
            }
          }
          if (this.access && this.access.length > 0) {
            this.$helperService.setAccessLevel(this.access);
          }
          if (this.access.length > 0) {
            for (var k in this.access) {
              if (this.access[k].is_view) {
                router.push("/" + this.access[k].url);
                return;
              }
            }
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
        });
    },
  },
};
</script>
