<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/open-position')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld" v-if="!type">
                {{ id ? "Edit" : "Add" }} Funding Series
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="Add()">
                      <div class="col-12 row">
                        <div class="col-8 form-group">
                          <label> Name </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="name"
                            :disabled="type == 'VIEW'"
                            class="form-control"
                            placeholder="Funding Series Name"
                            v-model="detail.name"
                            rules="required:name"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="name" class="text-danger" />
                        </div>
                        <div
                          class="col-lg-12 col-sm-12 text-center mt-2"
                          v-show="!type"
                        >
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
// import Multiselect from "@vueform/multiselect";

import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "Open Postion",
  components: {
    Form,
    Field,

    ErrorMessage,
    //Multiselect,

    ErrorComponent,
  },
  data() {
    return {
      loader: true,
      id: this.$route.params.id,
      type: this.$route.params.type,
      detail: {
        name: "",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    Add() {
      if (this.id) {
        this.$api
          .postAPI({
            _action: "/funding_series/edit/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/funding_series/add",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/funding_series/details/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          this.detail.name = res.result.name;
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon2 {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-left: 20px;
}
</style>
