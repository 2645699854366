<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/executive')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Executive</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12">
                        <div class="col-12 row">
                          <div class="form-group col-4">
                            <div class="col-12 mb-4">
                              <label>Executive Name </label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="Executive_Name"
                                placeholder="Executive Name"
                                rules="required: executive name"
                                v-model="detail.executive_name"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="executive_name"
                                  label="executive_name"
                                  valueProp="executive_name"
                                  placeholder="Select"
                                  v-model="detail.executive_name"
                                  :options="executiveList2"
                                  :create-option="true"
                                  searchable="true"
                                  v-bind="field"
                                  @search-change="searchexecutiveName2($event)"
                                >
                                  <template v-slot:option="{ option }">
                                    <div class="col-12 row" style="padding: 0">
                                      <div class="col-11">
                                        <img
                                          class="option-icon"
                                          :src="
                                            option.executive_logo
                                              ? option.executive_logo
                                              : '/img/user-pic.png'
                                          "
                                        />
                                        {{ option.executive_name }}
                                      </div>
                                      <div class="col-1">
                                        <img
                                          class="option-icon2"
                                          :src="
                                            option.new_company &&
                                            option.new_company.company_logo
                                              ? option.new_company.company_logo
                                              : '/img/user-pic.png'
                                          "
                                        />
                                      </div>
                                    </div>
                                  </template>
                                </Multiselect>
                                <ErrorMessage
                                  name="Executive_Name"
                                  class="text-danger"
                                />
                              </Field>
                            </div>
                            <div class="form-group col-12">
                              <label>Company Name</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="new_company_id"
                                placeholder="Select"
                                rules="required: new company"
                                v-model="detail.new_company_id"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="company_name"
                                  label="company_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.new_company_id"
                                  :options="companyList"
                                  searchable="true"
                                  v-bind="field"
                                  v-on:change="getIndustrydetail($event, 'NEW')"
                                  @search-change="
                                    searchCompanyName($event, 'FALSE')
                                  "
                                >
                                  <template v-slot:option="{ option }">
                                    <img
                                      class="option-icon"
                                      :src="
                                        option.company_logo
                                          ? option.company_logo
                                          : '/img/user-pic.png'
                                      "
                                    />
                                    {{ option.company_name }}
                                  </template>
                                </Multiselect>
                              </Field>
                              <ErrorMessage
                                name="new_company_id"
                                class="text-danger"
                              />
                            </div>
                            <div class="form-group col-12">
                              <label> Job Title </label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="new_job_title_id"
                                placeholder="Select"
                                rules="required:job title"
                                :validateOnInput="true"
                                v-model="detail.new_job_title_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="title"
                                  label="title"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.new_job_title_id"
                                  :create-option="true"
                                  :options="jobTitleList"
                                  v-on:change="
                                    getSeniorityDetail($event, 'NEW')
                                  "
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="new_job_title_id"
                                class="text-danger"
                              />
                            </div>
                            <div class="form-group col-12 mb-4">
                              <label>Age Range </label>
                              <Field
                                type="text"
                                name="age_range_name"
                                placeholder="Select"
                                v-model="detail.age_range_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="age_range_name"
                                  label="age_range_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.age_range_id"
                                  :options="aagerangeList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="form-group col-12">
                              <label>Current Country</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="Country"
                                rules="required:current country"
                                :validateOnInput="true"
                                v-model="detail.country_id"
                                v-on:change="
                                  getsameCountry(detail.country_id, 'NEW')
                                "
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="country_name"
                                  label="country_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.country_id"
                                  :options="countryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="Country"
                                class="text-danger"
                              />
                            </div>
                            <div class="form-group col-12">
                              <label>Past Country</label>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="country_id_prev"
                                v-model="detail.country_id_prev"
                                v-on:change="
                                  getsameCountry(detail.country_id_prev, 'PAST')
                                "
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="country_name"
                                  label="country_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.country_id_prev"
                                  :options="countryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="col-12">
                              <label>HQ based</label>
                              <div>
                                <input
                                  type="checkbox"
                                  id="is_hq_based"
                                  name="is_hq_based"
                                  style="height: 52px; width: 27px"
                                  true-value="1"
                                  false-value="0"
                                  v-on:change="
                                    getCountrySameAsCompany(detail.is_hq_based)
                                  "
                                  v-model="detail.is_hq_based"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-4">
                            <div class="col-12">
                              <span>Investor</span>
                              <div>
                                <input
                                  type="checkbox"
                                  id="Invester"
                                  name="Invester"
                                  style="height: 52px; width: 27px"
                                  true-value="1"
                                  false-value="0"
                                  v-model="detail.is_invester"
                                />
                              </div>
                            </div>
                            <div class="form-group col-12 mt-3">
                              <label> Industry</label>
                              <Field
                                type="text"
                                name="industry_id_new"
                                placeholder="Select"
                                v-model="detail.industry_id_new"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  style="margin-top: -3px"
                                  mode="single"
                                  trackBy="industry_name"
                                  label="industry_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.industry_id_new"
                                  :options="industryList"
                                  @input="onIndustryChange"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="form-group col-12">
                              <label>Job Function </label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="job_function_new"
                                v-model="detail.job_function_id_new"
                                rules="required:  job function"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="job_function_name"
                                  label="job_function_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.job_function_id_new"
                                  :options="jobFunctionList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="job_function_new"
                                class="text-danger"
                              />
                            </div>
                            <div class="form-group col-12">
                              <label>Gender</label>
                              <span class="text-danger">*</span>
                              <Field
                                as="select"
                                class="form-control"
                                name="Gender"
                                rules="required: gender"
                                :validateOnInput="true"
                                v-model="detail.gender"
                              >
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Field>
                              <ErrorMessage name="Gender" class="text-danger" />
                            </div>
                            <div class="form-group col-12">
                              <label>Current City</label>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="CITY"
                                v-model="detail.city_id"
                                v-on:change="getsameCity(detail.city_id)"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="city_name"
                                  label="city_name"
                                  valueProp="id"
                                  v-model="detail.city_id"
                                  :options="cityList"
                                  searchable="true"
                                  placeholder="Select"
                                  @search-change="searchCityName($event)"
                                  v-bind="field"
                                />
                              </Field>
                            </div>

                            <div class="form-group col-12">
                              <label>Past City</label>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="city_id_prev"
                                v-model="detail.city_id_prev"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="city_name"
                                  label="city_name"
                                  valueProp="id"
                                  v-model="detail.city_id_prev"
                                  :options="cityPastList"
                                  searchable="true"
                                  placeholder="Select"
                                  @search-change="searchCityName($event)"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="col-12">
                              <label>Same as current</label>
                              <div>
                                <input
                                  type="checkbox"
                                  id="is_same_as_current"
                                  name="is_same_as_current"
                                  style="height: 52px; width: 27px"
                                  true-value="1"
                                  false-value="0"
                                  v-on:change="
                                    getSameAsCurrent(detail.is_same_as_current)
                                  "
                                  v-model="detail.is_same_as_current"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-4">
                            <div class="form-group col-12">
                              <span>Board Membership</span>
                              <div>
                                <input
                                  type="checkbox"
                                  id="is_board_membership"
                                  name="is_board_membership"
                                  style="height: 52px; width: 27px"
                                  true-value="1"
                                  false-value="0"
                                  v-model="detail.is_board_member"
                                />
                              </div>
                            </div>
                            <!-- v-if="showSubsectorDropdown" -->
                            <div v-if="showSubsectorDropdown" class="form-group col-12 mt-3">
                              <label> Subsector</label>
                              <Field
                                type="text"
                                name="subsector_id"
                                placeholder="Select"
                                v-model="detail.subsector_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  style="margin-top: -3px"
                                  mode="single"
                                  trackBy="industry_name"
                                  label="industry_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.subsector_id"
                                  :options="subsectorsList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="form-group col-12 mt-3">
                              <label>Current role since(Month) </label>
                              <div class="col-12 row">
                                <div class="col-6" style="margin-left: -16px">
                                  <Field
                                    as="select"
                                    class="form-control"
                                    name="month"
                                    v-model="detail.curr_month"
                                  >
                                    <option value="">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </Field>
                                </div>
                                <div class="col-6">
                                  <Field
                                    type="text"
                                    name="year"
                                    class="form-control"
                                    placeholder="year"
                                    v-model="detail.curr_year"
                                    v-slot="{ field }"
                                  >
                                    <Datepicker
                                      autoApply
                                      placeholder="year"
                                      v-bind="field"
                                      v-model="detail.curr_year"
                                      yearPicker
                                    />
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-12">
                              <label>Seniority Level</label>
                              <span class="text-danger">*</span>

                              <Field
                                type="text"
                                name="Seniority"
                                placeholder="Select"
                                rules="required:seniority"
                                :validateOnInput="true"
                                v-model="detail.seniority_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="seniority_name"
                                  label="seniority_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.seniority_id"
                                  :options="senioritylevelList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="Seniority"
                                class="text-danger"
                              />
                            </div>
                            <div class="form-group col-12">
                              <label for="inputRole">Reports to</label>
                              <!-- <span class="text-danger">*</span> -->
                              <Multiselect
                                mode="single"
                                trackBy="executive_name"
                                label="executive_name"
                                valueProp="id"
                                placeholder="Select Executive"
                                v-model="detail.report_to_name_id"
                                :options="executiveList"
                                searchable="true"
                                @search-change="searchexecutiveName($event)"
                              >
                                <template v-slot:option="{ option }">
                                  <div class="col-12 row" style="padding: 0">
                                    <div class="col-10">
                                      <img
                                        class="option-icon"
                                        :src="
                                          option.executive_logo
                                            ? option.executive_logo
                                            : '/img/user-pic.png'
                                        "
                                      />
                                      {{ option.executive_name }}
                                    </div>
                                    <div class="col-1">
                                      <img
                                        class="option-icon2"
                                        :src="
                                          option.new_company &&
                                          option.new_company.company_logo
                                            ? option.new_company.company_logo
                                            : '/img/user-pic.png'
                                        "
                                      />
                                    </div>
                                  </div>
                                </template>
                              </Multiselect>
                            </div>
                            <div class="form-group col-12">
                              <label>Current Region</label>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="NEW_REGION"
                                v-model="detail.region_id_new"
                                v-on:change="
                                  getsameRegion(detail.region_id_new)
                                "
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="region_name"
                                  label="region_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.region_id_new"
                                  :options="regionList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="form-group col-12">
                              <label>Past Region</label>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="region_id_prev"
                                v-model="detail.region_id_prev"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="region_name"
                                  label="region_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.region_id_prev"
                                  :options="regionList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>

                            <!-- <div class="form-group col-12">
                              <label>Bio Source Url</label>
                              <Field
                                type="text"
                                class="form-control"
                                name="source_url"
                                placeholder="Bio Source Url"
                                v-model="detail.source_url"
                                rules="website"
                                :validateOnInput="true"
                              />
                              <ErrorMessage
                                name="source_url"
                                class="text-danger"
                              />
                            </div> -->
                          </div>
                        </div>

                        <hr />
                        <h3 class="sub-heading mt-4">Executive Logo :-</h3>
                        <div class="col-12 mt-3 mb-3">
                          <ImagePicker
                            :src="detail.executive_logo"
                            :modelValue="detail.executive_logo"
                            @removeImage="removeImage()"
                            ref="ImagePicker"
                          />
                        </div>
                        <hr />
                        <hr />
                        <h3 class="sub-heading mt-4">Education :-</h3>
                        <div class="col-12 row">
                          <div class="form-group col-4">
                            <div class="form-group col-12">
                              <label>Education Qualification</label>
                              <Field
                                type="text"
                                name="EducationList"
                                placeholder="Select"
                                v-model="detail.education_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="education_name"
                                  label="education_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.education_id"
                                  :options="EducationList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                          </div>
                          <div class="form-group col-4">
                            <div class="form-group col-12">
                              <label>Specialzation</label>
                              <Field
                                type="text"
                                class="form-control"
                                name="Specialzation"
                                placeholder="Specialzation"
                                v-model="detail.specialzation"
                              />
                            </div>
                          </div>
                          <div class="form-group col-4">
                            <div class="form-group col-12">
                              <label>Name of Education Institution </label>
                              <!-- <Field
                                type="text"
                                class="form-control"
                                name="Name_of_Education_Institution"
                                placeholder="Name of Education Institution"
                                v-model="detail.education_institutre_name"
                              /> -->

                              <Field
                                type="text"
                                name="university"
                                placeholder="Select"
                                v-model="detail.education_institutre_name"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="name"
                                  label="name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.education_institutre_name"
                                  :options="universitylist"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3 class="sub-heading mt-4">Career History :-</h3>
                        <div class="mt-5">
                          <div class="row">
                            <div class="form-group col-3">
                              <label> Past Company</label>
                              <Field
                                type="text"
                                name="prev_company_id"
                                placeholder="Select"
                                v-model="detail.prev_company_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="company_name"
                                  label="company_name"
                                  valueProp="id"
                                  placeholder="Select Previous Company"
                                  v-model="detail.prev_company_id"
                                  :options="companyList"
                                  searchable="true"
                                  v-bind="field"
                                  v-on:change="getIndustrydetail($event, 'OLD')"
                                  @search-change="
                                    searchCompanyName($event, 'FALSE')
                                  "
                                >
                                  <template v-slot:option="{ option }">
                                    <img
                                      class="option-icon"
                                      :src="
                                        option.company_logo
                                          ? option.company_logo
                                          : '/img/user-pic.png'
                                      "
                                    />
                                    {{ option.company_name }}
                                  </template>
                                </Multiselect>
                              </Field>
                            </div>
                            <div class="form-group col-3">
                              <label>Past Industry </label>
                              <Field
                                type="text"
                                name="industry_id_prev"
                                placeholder="Select"
                                v-model="detail.industry_id_prev"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="industry_name"
                                  label="industry_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.industry_id_prev"
                                  :options="industryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-3">
                              <label> Job Title </label>
                              <Field
                                type="text"
                                name="prev_job_title_id"
                                placeholder="Select"
                                v-model="detail.prev_job_title_id"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="title"
                                  label="title"
                                  valueProp="id"
                                  placeholder="Select"
                                  :create-option="true"
                                  v-model="detail.prev_job_title_id"
                                  v-on:change="
                                    getSeniorityDetail($event, 'PREV')
                                  "
                                  :options="jobTitleList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="form-group col-3">
                              <label>Job Function </label>
                              <Field
                                type="text"
                                placeholder="Select"
                                name="job_function_id_prev"
                                v-model="detail.job_function_id_prev"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="job_function_name"
                                  label="job_function_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.job_function_id_prev"
                                  :options="jobFunctionList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>

                            <div class="form-group col-3">
                              <label>Past Seniority Level</label>

                              <Field
                                type="text"
                                name="seniority_id_prev"
                                placeholder="Select"
                                v-model="detail.seniority_id_prev"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="seniority_name"
                                  label="seniority_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.seniority_id_prev"
                                  :options="senioritylevelList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                            </div>
                            <div class="col-12 form-group">
                              <label>More Past Company</label>
                              <Multiselect
                                mode="tags"
                                trackBy="company_name"
                                label="company_name"
                                valueProp="id"
                                v-model="executive_company"
                                placeholder="Select"
                                :options="companyList"
                                searchable="true"
                                @search-change="
                                  searchCompanyName($event, 'FALSE')
                                "
                              >
                                <template v-slot:option="{ option }">
                                  <img
                                    class="option-icon"
                                    :src="
                                      option.company_logo
                                        ? option.company_logo
                                        : '/img/user-pic.png'
                                    "
                                  />
                                  {{ option.company_name }}
                                </template>
                              </Multiselect>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3 class="sub-heading mt-4">
                          Contact/Social Media :-
                        </h3>
                        <div
                          v-for="(data, index) in detail.social_media"
                          :key="index"
                          class="col-12 row"
                        >
                          <div class="form-group col-3">
                            <Field
                              as="select"
                              class="form-control"
                              placeholder="Platform"
                              :name="'Platform_' + index"
                              v-model="data.platform_type"
                            >
                              <option value="">Select Platform</option>
                              <option value="LinkedIn">LinkedIn</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Twitter">Twitter</option>
                            </Field>
                          </div>
                          <div class="form-group col-3">
                            <Field
                              type="text"
                              class="form-control"
                              :name="'URL_' + index"
                              placeholder="URL"
                              v-model="data.url"
                              rules="website"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              :name="'URL_' + index"
                              class="text-danger"
                            />
                          </div>
                          <img
                            v-show="
                              index == detail.social_media.length - 1 &&
                              detail.social_media.length <= 2
                            "
                            v-on:click="addPlatform()"
                            src="/img/plus.png"
                            width="30"
                            height="30"
                          />
                          <img
                            v-show="
                              index != detail.social_media.length - 1 ||
                              index == 2
                            "
                            v-on:click="removePlatform(index)"
                            src="/img/cross.png"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="col-12 row">
                          <div class="form-group col-4">
                            <label> Executive Phone </label>

                            <Field
                              type="text"
                              name="phone"
                              placeholder="Company Mobile"
                              v-model="detail.executive_phone"
                              v-slot="{ field }"
                            >
                              <PhoneCode
                                id="mobile"
                                placeholder="Executive Mobile"
                                v-model:countryCode="detail.country_code"
                                v-model="detail.executive_phone"
                                v-model:dialCode="detail.dial_code"
                                v-bind="field"
                              />
                            </Field>
                          </div>
                          <div class="form-group col-4">
                            <label> Email(Personal) </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              name="Email_P"
                              placeholder="personal email"
                              v-model="detail.executive_email_personal"
                              rules="email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="Email_P" class="text-danger" />
                          </div>
                          <div class="form-group col-4">
                            <label> Email(Work) </label>
                            <Field
                              type="text"
                              class="form-control"
                              name="Email_W"
                              placeholder="work email"
                              v-model="detail.executive_email_work"
                              rules="email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="Email_W" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-12 row" style="padding: 15px">
                          <div class="col-8">
                            <h3 class="sub-heading">Web References :-</h3>
                          </div>
                          <div
                            class="col-4 text-right"
                            style="margin-top: -26px"
                          >
                            <button
                              type="button"
                              style="margin-top: 37px; width: 192px"
                              class="save-btn px-4 ml-3"
                              v-on:click="showPublicationPopup()"
                            >
                              Add New Publication
                            </button>
                          </div>
                          <hr />
                          <h5 class="sub-heading mt-4">
                            Interesting Article -
                          </h5>
                          <div
                            v-for="(data, index) in detail.interting_links"
                            :key="index"
                            class="col-12 row mt-5"
                          >
                            <div class="col-2 form-group">
                              <Datepicker
                                autoApply
                                v-model="data.published_date"
                                inputFormat="dd-MM-yyyy"
                                placeholder="Published Date"
                                v-bind="field"
                                format="dd-MM-yyyy"
                              >
                              </Datepicker>
                            </div>
                            <div class="form-group col-2">
                              <Field
                                type="text"
                                :name="'headline_' + index"
                                class="form-control"
                                placeholder="Title"
                                v-model="data.headline"
                              />
                            </div>
                            <div class="form-group col-3">
                              <Field
                                type="text"
                                :name="'url_' + index"
                                class="form-control"
                                placeholder="Url"
                                v-model="data.url"
                              />
                            </div>
                            <div class="col-3 form-group">
                              <Multiselect
                                mode="single"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                placeholder="Select Publication"
                                v-model="data.publication_id"
                                :options="publicationList"
                                searchable="true"
                              />
                            </div>
                            <div
                              class="col-1 form-group"
                              style="margin-top: -7px"
                            >
                              <input
                                :title="'Add Company Logo'"
                                type="checkbox"
                                id="is_same_as_current"
                                name="is_same_as_current"
                                style="height: 52px; width: 27px"
                                true-value="1"
                                false-value="0"
                                v-model="data.is_company_logo"
                              />
                            </div>
                            <div class="col-1" style="margin-top: 5px">
                              <img
                                v-show="index == 0"
                                v-on:click="addLinkObj()"
                                src="/img/plus.png"
                                width="30"
                                height="30"
                              />
                              <img
                                v-show="index != 0"
                                v-on:click="removeLinkObj(index)"
                                src="/img/cross.png"
                                width="30"
                                height="30"
                              />
                            </div>
                          </div>
                          <hr />
                          <h5 class="sub-heading mt-4">
                            Conferences & Events -
                          </h5>
                          <div
                            v-for="(data, index) in detail.conferences_event"
                            :key="index"
                            class="col-12 row mt-5"
                          >
                            <div class="col-2 form-group">
                              <Datepicker
                                autoApply
                                v-model="data.published_date"
                                inputFormat="dd-MM-yyyy"
                                placeholder="Published Date"
                                v-bind="field"
                                format="dd-MM-yyyy"
                              >
                              </Datepicker>
                            </div>
                            <div class="form-group col-2">
                              <Field
                                type="text"
                                :name="'title_' + index"
                                class="form-control"
                                placeholder="Title"
                                v-model="data.headline"
                              />
                            </div>
                            <div class="form-group col-3">
                              <Field
                                type="text"
                                :name="'url1_' + index"
                                class="form-control"
                                placeholder="Url"
                                v-model="data.url"
                              />
                            </div>
                            <div class="col-3 form-group">
                              <Multiselect
                                mode="single"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                placeholder="Select Publication"
                                v-model="data.publication_id"
                                :options="publicationList"
                                searchable="true"
                              />
                            </div>
                            <div
                              class="col-1 form-group"
                              style="margin-top: -7px"
                            >
                              <div>
                                <input
                                  :title="'Add Company Logo'"
                                  type="checkbox"
                                  id="is_same_as_current"
                                  name="is_same_as_current"
                                  style="height: 52px; width: 27px"
                                  true-value="1"
                                  false-value="0"
                                  v-model="data.is_company_logo"
                                />
                              </div>
                            </div>
                            <div class="col-1" style="margin-top: 7px">
                              <img
                                v-show="index == 0"
                                v-on:click="addEventObj()"
                                src="/img/plus.png"
                                width="30"
                                height="30"
                              />
                              <img
                                v-show="index != 0"
                                v-on:click="removeEventObj(index)"
                                src="/img/cross.png"
                                width="30"
                                height="30"
                              />
                            </div>
                          </div>

                          <hr />
                          <h5 class="sub-heading mt-4">Video -</h5>
                          <div
                            v-for="(data, index) in detail.videos"
                            :key="index"
                            class="col-12 row mt-5"
                          >
                            <div class="form-group col-5">
                              <Field
                                type="text"
                                :name="'title1_' + index"
                                class="form-control"
                                placeholder="Title"
                                v-model="data.title"
                              />
                            </div>
                            <div class="form-group col-5">
                              <Field
                                type="text"
                                :name="'news_ur_' + index"
                                class="form-control"
                                placeholder="Url"
                                v-model="data.video_url"
                              />
                            </div>
                            <img
                              v-show="index == 0"
                              v-on:click="addVideoObj()"
                              src="/img/plus.png"
                              width="30"
                              height="30"
                            />
                            <img
                              v-show="index != 0"
                              v-on:click="removevideoObj(index)"
                              src="/img/cross.png"
                              width="30"
                              height="30"
                            />
                          </div>
                          <hr />
                          <div class="col-12">
                            <div class="col-12 row">
                              <div class="form-group col-8">
                                <label>Biography</label>
                                <textarea
                                  type="text"
                                  name="Biography"
                                  style="height: 197px"
                                  class="form-control"
                                  placeholder="Biography"
                                  v-model="detail.executive_biography"
                                  rules="required: executive biography"
                                  :validateOnInput="true"
                                >
                                <ErrorMessage
                                  name="Biography"
                                  class="text-danger"
                                />
                                </textarea>
                              </div>
                              <div class="col-4" style="margin-top: 39px">
                                <a
                                  type="button"
                                  class="fill-btn"
                                  v-on:click="createExecutiveBio()"
                                >
                                  Create Bio
                                </a>
                              </div>
                              <div class="form-group col-6">
                              <label>Bio Source Url</label>
                              <Field
                                type="text"
                                class="form-control"
                                name="source_url"
                                placeholder="Bio Source Url"
                                v-model="detail.source_url"
                                rules="website"
                                :validateOnInput="true"
                              />
                              <ErrorMessage
                                name="source_url"
                                class="text-danger"
                              />
                            </div>
                              <div class="col-6 form-group col-6">
                                <label>Current Responsibility</label>
                                <textarea
                                  type="text"
                                  name="Current_Responsibility"
                                  class="form-control"
                                  placeholder="Current Responsibility"
                                  v-model="detail.current_responsibility"
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 row">
                            <div class="form-group col-2">
                              <label>Flag for Review</label>
                              <div>
                                <input
                                  type="checkbox"
                                  id="is_review"
                                  name="is_review"
                                  style="height: 52px; width: 27px"
                                  true-value="1"
                                  false-value="0"
                                  v-model="detail.is_review"
                                />
                              </div>
                            </div>
                            <div class="form-group col-6">
                              <label></label>
                              <textarea
                                type="text"
                                name="Current_Responsibility"
                                class="form-control"
                                placeholder=""
                                v-model="detail.reason_for_flag"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 text-center mt-2">
                        <button id="save-button" class="save-btn px-4 ml-3">
                          Save
                        </button>
                        <button
                          type="button"
                          class="fil-btn px-4 ml-3"
                          v-on:click="$router.go(-1)"
                          id="cancel-button"
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Add Publication</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form itemref="addPubliction" @submit="addPubliction()">
        <div class="col-12 row">
          <div class="col-6 form-group">
            <label>Publication Name</label>
            <span class="text-danger">*</span>
            <Field
              type="text"
              name="user_name"
              class="form-control"
              placeholder="Publication Name"
              v-model="detail.name"
              rules="required: publication name"
              :validateOnInput="true"
            />
            <ErrorMessage name="user_name" class="text-danger" />
          </div>
          <div class="col-6 form-group">
            <label>Website Url</label>
            <span class="text-danger">*</span>
            <Field
              type="text"
              name="website_url"
              class="form-control"
              placeholder="Website Url"
              v-model="detail.website_url"
              rules="required: website url |website"
              :validateOnInput="true"
            />
            <ErrorMessage name="website_url" class="text-danger" />
          </div>
          <div class="col-6 mt-3 mb-3">
            <label>Publication Logo</label>
            <div class="text-left">
              <ImagePicker
                :src="detail.publication_logo"
                :modelValue="detail.publication_logo"
                ref="publication_logo"
              />
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 text-center mt-2">
            <button id="publication-button" class="save-btn px-4 ml-3">
              Save
            </button>
            <button
              type="button"
              class="fil-btn px-4 ml-3"
              v-on:click="$refs.textModal.closeModal"
              id="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal";
import PhoneCode from "@/components/PhoneCodePicker/PhoneCode";
import { Form, Field, ErrorMessage } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import ImagePicker from "@/components/ImagePicker.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import Multiselect from "@vueform/multiselect";
export default {
  name: "Add Executive",
  components: {
    Form,
    TextModal,
    Field,
    ErrorComponent,
    Multiselect,
    Datepicker,
    ImagePicker,
    ErrorMessage,
    PhoneCode,
  },
  computed: {
    checkLogo() {
      return this.detail.executive_logo ? false : true;
    },
  },
  data() {
    return {
      loader: false,
      isShow: false,
      showSubsectorDropdown: false,
      id: this.$route.params.id,
      executiveList2: [],
      executive_company: [],
      executiveList: [],
      executiveKeyword: "",
      detail: {
        age_range_id: 0,
        report_to_name_id: "",
        profile_update_linkedin: "Y",
        is_same_as_current: 0,
        new_company_id: "",
        prev_company_id: "",
        country_id_prev: "",
        country_id: "",
        city_id: "",
        city_id_prev: "",
        region_id_new: "",
        region_id_prev: "",
        subsector_id: "",
        executive_compan: [],
        news: [{}],
        social_media: [{}],
        videos: [
          {
            video_url: "",
            title: "",
          },
        ],
        interting_links: [
          {
            published_date: null,
            publication_id: "",
            headline: "",
            url: "",
          },
        ],
        conferences_event: [
          {
            published_date: null,
            publication_id: "",
            headline: "",
            url: "",
          },
        ],
      },

      subsectorsList: [],
      departureReasonList: [],
      companyList: [],
      countryList: [],
      company_ids: [],
      executive_ids: [],
      city_ids: [],
      cityList: [],
      cityPastList: [],
      regionList: [],
      industryList: [],
      universitylist: [],
      senioritylevelList: [],
      jobFunctionList: [],
      EducationList: [],
      aagerangeList: [],
      publicationList: [],
      jobTitleList: [],
      companyKeyword: "",
      cityKeyword: "",
      company_name: "-",
      prev_company_name: "-",
      cityname: " - ",
    };
  },
  mounted() {
    this.$refs.errorComponent.updateFormLoader(true);
    if (!this.id) {
      this.companylist("TRUE");
    }
    // else {
    //   this.companylist();
    // }
    this.executivelist();
    this.reasonList();
    this.CountryList();
    this.listRegion();
    this.executivelist2();
    this.publicationlist();
    this.industrylist();
    this.jobFunctionlist();
    this.seniorityList();
    this.educationlist();
    this.ageRangeList();
    this.getUniversitylist();
    if (this.id) {
      this.getDetail();
    }
    this.getJobTitleList();
  },
  methods: {
    onIndustryChange(selectedIndustryId) {
      const selectedIndustry = this.industryList.find(industry => industry.id == selectedIndustryId);

      if (selectedIndustry && selectedIndustry.sub_sector_count > 0) {
        this.showSubsectorDropdown = true;
        this.getSubsectorList(selectedIndustry.id);
      } else {
        this.showSubsectorDropdown = false;
        this.subsectorList = [];
        this.detail.subsector_id = '';
      }
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorsList = res.list;
        })
        .catch(() => {});
    },
    getSeniorityDetail(id, type) {
      for (var i in this.jobTitleList) {
        if (id == this.jobTitleList[i].id) {
          if (type == "NEW") {
            this.detail.seniority_id = this.jobTitleList[i].seniority_level_id;
            this.detail.job_function_id_new =
              this.jobTitleList[i].job_function_id;
          }
          if (type == "PREV") {
            this.detail.seniority_id_prev =
              this.jobTitleList[i].seniority_level_id;
            this.detail.job_function_id_prev =
              this.jobTitleList[i].job_function_id;
          }
        }
      }
    },
    getIndustrydetail(id, type) {
      for (var i in this.companyList) {
        if (this.companyList[i].id == id && type == "NEW") {
          this.detail.industry_id_new = this.companyList[i].industry_id;
        }
        if (this.companyList[i].id == id && type == "OLD") {
          this.detail.industry_id_prev = this.companyList[i].industry_id;
        }
      }
      this.getCountrySameAsCompany("INDUSTRY");
    },
    createExecutiveBio() {
      this.getCompamyName("NEW");
      this.getCompamyName("OLD");
      this.getCityName();

      var executive = this.detail.executive_name
        ? this.detail.executive_name
        : "Executive ";
      var job_title = "-";

      if (
        this.$helperService.isStringOnlyAlphabet(this.detail.new_job_title_id)
      ) {
        job_title = this.detail.new_job_title_id;
      } else {
        for (var a in this.jobTitleList) {
          if (this.detail.new_job_title_id == this.jobTitleList[a].id) {
            job_title = this.jobTitleList[a].title;
          }
        }
      }

      var gender =
        this.detail.gender == "male"
          ? "He"
          : this.detail.gender == "female"
          ? "She"
          : "-";
      var prev_job_title = "-";

      if (
        this.$helperService.isStringOnlyAlphabet(this.detail.prev_job_title_id)
      ) {
        prev_job_title = this.detail.prev_job_title_id;
      } else {
        for (var b in this.jobTitleList) {
          if (this.detail.prev_job_title_id == this.jobTitleList[b].id) {
            prev_job_title = this.jobTitleList[b].title;
          }
        }
      }
      var degree = "-";
      for (var i in this.EducationList) {
        if (this.detail.education_id == this.EducationList[i].id) {
          degree = this.EducationList[i].education_name;
        }
      }
      var specialzation = this.detail.specialzation
        ? this.detail.specialzation
        : "-";
      var education_institutre_name = "-";
      for (var j in this.universitylist) {
        if (
          this.detail.education_institutre_name == this.universitylist[j].id
        ) {
          education_institutre_name = this.universitylist[j].name;
        }
      }
      var country_name = "-";
      for (var k in this.countryList) {
        if (this.detail.country_id == this.countryList[k].id) {
          country_name = this.countryList[k].country_name;
        }
      }
      setTimeout(() => {
        var bio =
          executive +
          " serves as " +
          job_title +
          " at " +
          this.company_name +
          ". " +
          gender +
          " previously served as a " +
          prev_job_title +
          " at " +
          this.prev_company_name +
          ". " +
          "\n\n" +
          gender +
          " holds a " +
          degree +
          " in " +
          specialzation +
          " from the " +
          education_institutre_name +
          ". " +
          "\n\n" +
          "Currently based at " +
          this.cityname +
          country_name +
          ".";

        this.detail.executive_biography = bio;
      }, 2300);
    },
    getCityName() {
      var obj = {};
      if (this.detail.city_id) {
        obj.name = this.detail.city_id;
      }

      this.$api
        .getAPI({
          _action: "/city/list",
          _body: obj,
        })
        .then((res) => {
          if (res.city_name) {
            this.cityname = res.city_name + ", ";
          }
        })
        .catch(() => {});
    },
    removeImage() {
      if (this.id) {
        this.$api
          .postAPI({
            _action: "/remove-executive-image/" + this.id,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getDetail();
          })
          .catch(() => {});
      } else {
        this.detail.executive_logo = "";
        // // console.log("this.detail.user_images after", index);
      }
    },
    getCompamyName(type) {
      var obj = {};
      if (this.detail.new_company_id && type == "NEW") {
        obj.name = this.detail.new_company_id;
      }
      if (this.detail.prev_company_id && type == "OLD") {
        obj.name = this.detail.prev_company_id;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          if (res.company_name) {
            if (type == "NEW") {
              this.company_name = res.company_name;
              return;
            }
          }
          if (res.company_name) {
            if (type == "OLD") {
              this.prev_company_name = res.company_name;
              return;
            }
          }
        })
        .catch(() => {});
    },
    getPrevCompamyName() {
      var obj = {};
      if (this.detail.prev_company_id) {
        obj.name = this.detail.prev_company_id;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.prev_company_name = res.company_name;
          return;
        })
        .catch(() => {});
    },
    getCountrySameAsCompany(type) {
      if (this.detail.is_hq_based == 1) {
        if (this.detail.new_company_id) {
          this.$api
            .getAPI({
              _action: "/company/detail/" + this.detail.new_company_id,
            })
            .then((res) => {
              this.detail.country_id = res.country_id;
              this.detail.region_id_new = res.region_id;
              this.detail.city_id = res.city_id;
              this.city_ids.push(this.detail.city_id);
              this.detail.executive_phone = res.company_mobile;
              this.detail.country_code = res.country_code;
              this.detail.dial_code = res.dial_code;
              if (this.detail.is_same_as_current == 1) {
                this.detail.country_id_prev = this.detail.country_id;
                this.detail.region_id_prev = this.detail.region_id_new;
                this.detail.city_id_prev = this.detail.city_id;
              }

              this.CityList(this.detail.country_id, type);
              return;
            })
            .catch(() => {});
        }

        if (this.detail.is_same_as_current == 1) {
          this.detail.country_id_prev = this.detail.country_id;
          this.detail.region_id_prev = this.detail.region_id_new;
          this.detail.city_id_prev = this.detail.city_id;
        }
      }
      if (this.detail.is_hq_based == 0) {
        // this.detail.country_id = "";
        // this.detail.region_id_new = "";
        // this.detail.city_id = "";
      }
      this.CityList(this.detail.country_id, type);
    },
    getsameCountry(id, type) {
      if (type == "NEW") {
        this.detail.city_id = "";
      }
      if (type == "PAST") {
        this.detail.city_id_prev = "";
      }
      if (this.detail.is_same_as_current == 1) {
        this.detail.country_id_prev = id;
      }
      for (var j in this.countryList) {
        if (id == this.countryList[j].id) {
          if (this.detail.is_same_as_current == 1 || type == "PAST") {
            this.detail.region_id_prev = this.countryList[j].region_id;
          }
          if (type == "NEW") {
            this.detail.country_code = this.countryList[j].country_code;
            this.detail.region_id_new = this.countryList[j].region_id;
          }
        }
      }
      this.CityList(id, type);
    },
    CityList(country_id, type) {
      if (type == "INDUSTRY" || type) {
        this.$refs.errorComponent.updateFormLoader(true);
      }
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      if (this.city_ids.length > 0) {
        obj.city_ids = this.city_ids;
      }
      if (country_id) {
        obj.country_id = country_id;

        // for (var i in this.countryList) {
        //   if (country_id == this.countryList[i].id) {
        //     this.detail.country_code = this.countryList[i].country_code;
        //   }
        // }
      }
      // if (this.detail.country_id) {
      //   // obj.country_id = this.detail.country_id;
      //   for (var j in this.countryList) {
      //     if (this.detail.country_id == this.countryList[j].id) {
      //       this.detail.country_code = this.countryList[j].country_code;
      //     }
      //   }
      // }
      // if (this.detail.country_id_prev) {
      //   obj.country_id = this.detail.country_id_prev;
      //   for (var k in this.countryList) {
      //     if (this.detail.country_id_prev == this.countryList[k].id) {
      //       this.detail.country_code = this.countryList[k].country_code;
      //     }
      //   }
      // }
      if (this.detail.country_id || this.detail.country_id_prev || type) {
        this.$api
          .getAPI({
            _action: "/city/list",
            _body: obj,
          })
          .then((res) => {
            if (type == "NEW") {
              this.cityList = res;
            } else if (type == "PAST") {
              this.cityPastList = res;
            } else {
              this.cityList = res;
              this.cityPastList = res;
            }
            this.city_ids = [];
            this.cityKeyword = "";
            if (type == "INDUSTRY" || type) {
              this.loader = false;
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.setData(res);
                this.$refs.errorComponent.updateFormLoader(false);
              }
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      } else {
        this.detail.city_id = "";
        this.detail.city_id_prev = "";
        this.$notify({
          type: "error",
          text: "Please Select Country First.",
        });
      }
    },
    getsameRegion(id) {
      if (this.detail.is_same_as_current == 1) {
        this.detail.region_id_prev = id;
      }
    },
    getsameCity(id) {
      if (this.detail.is_same_as_current == 1) {
        this.detail.city_id_prev = id;
      }
    },
    getSameAsCurrent(value) {
      if (value == 1) {
        if (this.detail.country_id) {
          this.detail.country_id_prev = this.detail.country_id;
        }
        if (this.detail.region_id_new) {
          this.detail.region_id_prev = this.detail.region_id_new;
        }
        if (this.detail.city_id) {
          this.detail.city_id_prev = this.detail.city_id;
        }
      }
    },
    searchCompanyName(e, type) {
      this.companyKeyword = e;
      this.companylist(type);
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchexecutiveName(e) {
      this.executiveKeyword = e;
      this.executivelist();
    },
    searchexecutiveName2(e) {
      this.executiveKeyword = e;
      this.executivelist2();
    },
    addPlatform() {
      if (this.detail.social_media.length < 3) {
        this.detail.social_media.push({});
      }
    },
    removePlatform(index) {
      this.detail.social_media.splice(index, 1);
    },
    addEventObj() {
      this.detail.conferences_event.push({});
    },
    removeEventObj(index) {
      this.detail.conferences_event.splice(index, 1);
    },
    addLinkObj() {
      this.detail.interting_links.push({});
    },
    removeLinkObj(index) {
      this.detail.interting_links.splice(index, 1);
    },
    addNewsObj() {
      this.detail.news.push({});
    },
    removeNewsObj(index) {
      this.detail.news.splice(index, 1);
    },
    addVideoObj() {
      this.detail.videos.push({});
    },
    removevideoObj(index) {
      this.detail.videos.splice(index, 1);
    },
    executivelist2() {
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList2 = res;
          this.executiveKeyword = "";
          for (var a in this.executiveList2) {
            this.executiveList2[a].disabled = false;
          }
          for (var i in this.executiveList2) {
            if (this.executiveList2[i].executive_name) {
              this.executiveList2[i].name =
                this.executiveList2[i].executive_name;
            }
            if (this.executiveList2[i].new_job_title) {
              this.executiveList2[i].name +=
                ", " + this.executiveList2[i].new_job_title;
            }
            if (this.executiveList2[i].new_company) {
              this.executiveList2[i].name +=
                ", " + this.executiveList2[i].new_company.company_name;
            }
          }
        })
        .catch(() => {});
    },
    executivelist() {
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      if (this.executive_ids.length > 0) {
        obj.executive_ids = this.executive_ids;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList = res;
          this.executiveKeyword = "";
          this.executive_ids = [];
          for (var i in this.executiveList) {
            if (this.executiveList[i].executive_name) {
              this.executiveList[i].name = this.executiveList[i].executive_name;
            }
            if (this.executiveList[i].new_job_title) {
              this.executiveList[i].name +=
                ", " + this.executiveList[i].new_job_title;
            }
            if (this.executiveList[i].new_company) {
              this.executiveList[i].name +=
                ", " + this.executiveList[i].new_company.company_name;
            }
          }
        })
        .catch(() => {});
    },
    publicationlist() {
      var obj = {};
      if (this.publicationKeyword) {
        obj.keyword = this.publicationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/publications/list",
          _body: obj,
        })
        .then((res) => {
          this.publicationList = res;
        })
        .catch(() => {});
    },
    getJobTitleList() {
      this.$api
        .getAPI({
          _action: "/job/title/list",
        })
        .then((res) => {
          this.jobTitleList = res;

          if (this.detail.new_job_title_id) {
            if (
              this.$helperService.isStringOnlyAlphabet(
                this.detail.new_job_title_id
              )
            ) {
              this.jobTitleList.push({
                title: this.detail.new_job_title_id,
                id: this.detail.new_job_title_id,
              });
            }
          }

          if (this.detail.prev_job_title_id) {
            if (
              this.$helperService.isStringOnlyAlphabet(
                this.detail.prev_job_title_id
              )
            ) {
              this.jobTitleList.push({
                title: this.detail.prev_job_title_id,
                id: this.detail.prev_job_title_id,
              });
            }
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/executive/detail/" + this.id,
        })
        .then((res) => {
          if (res.executive_compan.length > 0) {
            for (var i in res.executive_compan) {
              this.executive_company.push(res.executive_compan[i].company_id);
              this.company_ids.push(res.executive_compan[i].company_id);
            }
          }
          this.company_ids.push(res.new_company_id);
          this.company_ids.push(res.prev_company_id);
          this.executive_ids.push(res.report_to_name_id);
          this.city_ids.push(res.city_id_prev);
          this.city_ids.push(res.city_id);
          this.detail = res;
          if (this.detail.executive_name) {
            this.executiveKeyword = this.detail.executive_name;
            this.executivelist2();
          }

          this.detail.social_media = [];
          if (this.detail.facebook_url) {
            var obj1 = {
              platform_type: "Facebook",
              url: this.detail.facebook_url,
            };
            this.detail.social_media.push(obj1);
          }
          if (this.detail.twitter_url) {
            var obj2 = {
              platform_type: "Twitter",
              url: this.detail.twitter_url,
            };
            this.detail.social_media.push(obj2);
          }
          if (this.detail.linkedin_url) {
            var obj3 = {
              platform_type: "LinkedIn",
              url: this.detail.linkedin_url,
            };
            this.detail.social_media.push(obj3);
          }
          if (this.detail.social_media.length == 0) {
            this.detail.social_media.push({});
          }
          if (this.detail.videos.length <= 0) {
            this.detail.videos.push({});
          }

          if (this.detail.interting_links.length <= 0) {
            this.detail.interting_links.push({
              published_date: null,
              publication_id: "",
              headline: "",
              url: "",
            });
          }
          if (this.detail.conferences_event.length <= 0) {
            this.detail.conferences_event.push({
              published_date: null,
              publication_id: "",
              headline: "",
              url: "",
            });
          }
          if (this.detail.news.length <= 0) {
            this.detail.news.push({});
          }
          if (this.detail.industry_id_new) {
            this.getSubsectorList(this.detail.industry_id_new);
            this.showSubsectorDropdown = true;
          }

          this.searchexecutiveName();
          this.searchCityName();
          this.searchCompanyName(null, "TRUE");
        })
        .catch(() => {});
    },
    ageRangeList() {
      this.$api
        .getAPI({
          _action: "/age/range/list",
        })
        .then((res) => {
          this.aagerangeList = res;
        })
        .catch(() => {});
    },
    getUniversitylist() {
      this.$api
        .getAPI({
          _action: "/university/list",
        })
        .then((res) => {
          this.universitylist = res;
        })
        .catch(() => {});
    },
    educationlist() {
      this.$api
        .getAPI({
          _action: "/education/list",
        })
        .then((res) => {
          this.EducationList = res;
        })
        .catch(() => {});
    },

    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/subsector-count/list", //we changed the industry list and get the count- that which industry have subsector.
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    listRegion() {
      this.$api
        .getAPI({
          _action: "/region/list",
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    CountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },

    companylist(type) {
      if (!type || type == "TRUE") {
        this.$refs.errorComponent.updateFormLoader(true);
      }
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          this.company_ids = [];
          if (type) {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reasonList() {
      this.$api
        .getAPI({
          _action: "/departure/reason/list",
        })
        .then((res) => {
          this.departureReasonList = res;
        })
        .catch(() => {});
    },
    seniorityList(keyword) {
      var obj = {};
      if (keyword) {
        obj.keyword = keyword;
      }
      this.$api
        .getAPI({
          _action: "/seniority/list",
          _body: obj,
        })
        .then((res) => {
          this.senioritylevelList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist(keyword) {
      var obj = {};
      if (keyword) {
        obj.keyword = keyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
          _body: obj,
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    save() {
      // if (!this.$refs.ImagePicker.file && !this.detail.executive_logo) {
      //   this.$notify({
      //     type: "error",
      //     text: "Please upload executive logo",
      //   });
      //   return;
      // }
      if (this.detail.social_media.length > 0) {
        this.detail.linkedin_url = "";
        this.detail.facebook_url = "";
        this.detail.twitter_url = "";
        for (var a in this.detail.social_media) {
          if (this.detail.social_media[a].platform_type == "LinkedIn") {
            this.detail.linkedin_url = this.detail.social_media[a].url;
          }
          if (this.detail.social_media[a].platform_type == "Facebook") {
            this.detail.facebook_url = this.detail.social_media[a].url;
          }
          if (this.detail.social_media[a].platform_type == "Twitter") {
            this.detail.twitter_url = this.detail.social_media[a].url;
          }
        }
      }
      if (
        this.detail.interting_links &&
        this.detail.interting_links.length > 0 &&
        this.detail.interting_links[0].published_date == null &&
        this.detail.interting_links[0].publication_id == "" &&
        this.detail.interting_links[0].headline == "" &&
        this.detail.interting_links[0].url == ""
      ) {
        this.detail.interting_links = [];
      }
      if (
        this.detail.conferences_event &&
        this.detail.conferences_event.length > 0 &&
        this.detail.conferences_event[0].published_date == null &&
        this.detail.conferences_event[0].publication_id == "" &&
        this.detail.conferences_event[0].headline == "" &&
        this.detail.conferences_event[0].url == ""
      ) {
        this.detail.conferences_event = [];
      }
      if (
        this.detail.videos &&
        this.detail.videos.length > 0 &&
        this.detail.videos[0].title == "" &&
        this.detail.videos[0].video_url == ""
      ) {
        this.detail.videos = [];
      }
      this.detail.executive_company = this.executive_company;
      if (this.id) {
        this.detail.id = this.id;
        this.$api
          .putAPI({
            _action: "/executive",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (!this.$refs.ImagePicker.file) {
              this.$router.go(-1);
            } else {
              this.uploadImage(res.id);
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/executive",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (!this.$refs.ImagePicker.file) {
              this.$router.go(-1);
            } else {
              this.uploadImage(res.id);
            }
          })
          .catch(() => {});
      }
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/executive/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        });
    },
    showPublicationPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    addPubliction() {
      this.detail.package_group_type = "web";
      this.$api
        .postAPI({
          _action: "/publications",
          _body: this.detail,
          _buttonId: "publication-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          if (!this.$refs.publication_logo.file) {
            this.publicationlist();
            this.detail.publication_id = res.id;
            this.$refs.textModal.closeModal();
          } else {
            this.uploadPublicationImage(res.id);
          }
        })
        .catch(() => {});
    },
    uploadPublicationImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.publication_logo.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.publicationlist();
          this.detail.publication_id = res.id;
          this.$refs.textModal.closeModal();
        });
    },
    onChange(e) {
      let files = e.target.files;

      let reader = new FileReader();

      reader.readAsDataURL(files[0]);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          var width = img.width;
          var height = img.height;
          if (width > 500 || height > 500) {
            alert("Image should be of 500 x 500 Pixcel");
            return;
          } else {
            if (
              files[0].type.toLowerCase() != "image/jpeg" &&
              files[0].type.toLowerCase() != "image/png"
            ) {
              alert("Invalid file formate, please use jpeg or png file.");
              return;
            }
            // const size = (files[0].size / 1024 / 1024).toFixed(2);
            // if (size > 10) {
            //   alert("File must be less then 10 MB");
            //   return;
            // }
            var tmppath = URL.createObjectURL(files[0]);
            this.file = files[0];
            document.getElementById("preview").src = tmppath;
          }
        };

        img.src = evt.target.result;
      };
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon2 {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-left: 20px;
}
</style>