<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Open Position</h4>
          </div>
          <div class="col-6 text-right" v-show="accessObject?.is_add == 1">
            <a
              style="padding-left: 20px; padding-right: 20px; cursor: pointer"
              v-on:click="$router.push('/add-open-position')"
              class="add-btn"
              >Add</a
            >
          </div>
        </div>

        <div class="page-content">
          <div class="card-body py-0 px-0">
            <!-- <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  >Reset</a
                >
              </div>
            </div> -->
            <div class="px-0">
              <div class="row mb-3">
                <div class="col-lg-6 col-sm-6">
                  <!-- <form class="form-search form-inline"> -->
                  <Field
                    name="keyword"
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                    style="border: 0px none; width: 100%"
                  />
                  <!-- </form> -->
                </div>
                 <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label class="label">From Date</label> -->
                  <datepicker
                    v-model="searchObj.from_date"
                    inputFormat="dd/MM/yyyy"
                    placeholder="From Date"
                    class="form-control"
                    style="background-color: white !important; height: 35px"
                    :clearable="false"
                    :upperLimit="
                      searchObj.to_date ? searchObj.to_date : new Date()
                    "
                  >
                  </datepicker>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label class="label">To Date</label> -->
                  <datepicker
                    v-model="searchObj.to_date"
                    inputFormat="dd/MM/yyyy"
                    class="form-control"
                    placeholder="To Date"
                    :clearable="false"
                    style="background-color: white !important; height: 35px"
                    :lowerLimit="
                      searchObj.from_date ? searchObj.from_date : null
                    "
                    :upperLimit="new Date()"
                  >
                  </datepicker>
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select Company"
                  v-model="searchObj.company_id"
                  :options="companyList"
                  searchable="true"
                  @search-change="searchCompanyName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_type"
                  :options="company_types"
                  searchable="true"
                  placeholder="Select Company Type"
                />
              </div>
              <!-- <div v-show="is_show" class="row advasearch-data mt-1"> -->
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Size</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_size"
                  :options="company_sizes"
                  searchable="true"
                  placeholder="Select Company Size"
                />
              </div>
               <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Seniority Level</label>
                <Multiselect
                  mode="single"
                  trackBy="seniority_name"
                  label="seniority_name"
                  valueProp="id"
                  placeholder="Select Seniority"
                  v-model="searchObj.seniority_id"
                  :options="seniorityList"
                  searchable="true"
                  @search-change="searchSeniorityName($event)"
                />
              </div>
               <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select Industry"
                  @search-change="searchIndustryName($event)"
                />
              </div>

              <!-- <div class="col-lg-2 col-sm-4 col-6">
                <label></label>
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
              </div> -->
            </div>
            <div class="row mt-1">
               <!-- <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select Industry"
                  @search-change="searchIndustryName($event)"
                />
              </div> -->
              
             <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Job Function</label>
                <Multiselect
                  mode="single"
                  trackBy="job_function_name"
                  label="job_function_name"
                  valueProp="id"
                  v-model="searchObj.job_function_id_new"
                  :options="jobFunctionList"
                  searchable="true"
                  placeholder="Select Job Function"
                  @search-change="searchJobFunctionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Location</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  v-model="searchObj.country_id"
                  :options="cityList"
                  searchable="true"
                  placeholder="Select Location"
                  @search-change="searchCityName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label></label>
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
              </div>
            </div>
            <hr />

            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th
                            class="sorting"
                            style="min-width: 250px"
                            id="id-title"
                            v-on:click="sorting('title', 'id-title')"
                          >
                            Title
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-company_name"
                            v-on:click="
                              sorting(
                                'tbl_company.company_name',
                                'id-company_name'
                              )
                            "
                          >
                            Company Name
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-seniority_name"
                            v-on:click="
                              sorting(
                                'tbl_seniority.seniority_name',
                                'id-seniority_name'
                              )
                            "
                          >
                            Seniority Name
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-industry_name"
                            v-on:click="
                              sorting(
                                'tbl_industry.industry_name',
                                'id-industry_name'
                              )
                            "
                          >
                            Industry Name
                          </th>

                          <th
                            class="sorting"
                            style="min-width: 250px"
                            id="id-job_function_name"
                            v-on:click="
                              sorting(
                                'tbl_job_functions.job_function_name',
                                'id-job_function_name'
                              )
                            "
                          >
                            Job-function name
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 100px"
                            id="id-country_name"
                            v-on:click="
                              sorting(
                                'tbl_country.country_name',
                                'id-country_name'
                              )
                            "
                          >
                            Country Name
                          </th>
                          <th
                            
                            style="min-width: 100px"
                            id="id-region_name"
                           
                          >
                            Region Name
                          </th>

                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_view == 1 ||
                              accessObject?.is_delete == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            {{ data.title ?? "" }}
                          </td>
                          <td>
                            <!-- {{ data.company != null  && data.company.company_name ? data.company.comapny_name: "" }} -->
                            {{ data.company_name ?? "" }}
                          </td>
                          <td>
                            {{
                              data.seniority != null &&
                              data.seniority.seniority_name
                                ? data.seniority.seniority_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.industry != null &&
                              data.industry.industry_name
                                ? data.industry.industry_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.function != nulll &&
                              data.function.job_function_name
                                ? data.function.job_function_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.country != null && data.country.country_name
                                ? data.country.country_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.region != nulll && data.region.region_name
                                ? data.region.region_name
                                : ""
                            }}
                          </td>
                          <!-- accessObject?.is_edit == 1 || -->
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_view == 1 ||
                              accessObject?.is_delete == 1
                            "
                          >
                            <!-- v-if="accessObject?.is_edit == 1" -->
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="goToView(data.id)"
                              >Edit</a
                            >
                            <!-- <a
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push(
                                  '/edit-intresting-bites/' + data.id
                                )
                              "
                              >View</a
                            > -->
                            <a
                              v-if="accessObject?.is_delete == 1"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import datepicker from "vue3-datepicker";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import { Field } from "vee-validate";
import moment from "moment";

export default {
  name: "Publication",
  components: {
    ErrorComponent,

    DeleteModal,
    Pagination,
    Multiselect,
    Field,
    datepicker,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Open Position"),
      loader: true,
      is_show: false,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      company_sizes: [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001-10000",
        "10000+",
      ],
      searchObj: {
        from_date: "",
        to_date: "",
        company_id: [],
        industry_id: [],
        country_id: "",
        job_function_id_new: "",
        company_type: "",
        seniority_id: "",
        company_size: "",
      },
      offset: -1,
      list: [],
      companyKeyword: "",
      cityKeyword: "",
      companyList: [],
      company_ids: [],
      industryList: [],
      industryKeyword: "",
      jobFunctionKeyword: "",
      jobFunctionList: [],
      cityList: [],
      seniorityList: [],
    };
  },
  mounted() {
    this.getList(0);
    this.companylist();
    this.CityList();
    this.jobFunctionlist();
    this.getindustrylist();
    this.getSenioritylist();
  },
  methods: {
    getAdvanceSearch() {
      this.is_show = !this.is_show;
    },
    // updatepublishstatus(status, id) {
    //   var obj = {};
    //   obj.id = id;
    //   obj.publish = status;
    //   this.$api
    //     .putAPI({
    //       _action: "/intresting/bites",
    //       _body: obj,
    //     })
    //     .then(() => {
    //       this.getList(0);
    //     })
    //     .catch(() => {});
    // },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    searchSeniorityName(e) {
      this.seniorityKeyword = e;
      this.getSenioritylist();
    },
    getSenioritylist() {
      var obj = {};
      if (this.seniorityKeyword) {
        obj.keyword = this.seniorityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/seniority/list",
          _body: obj,
        })
        .then((res) => {
          this.seniorityList = res;
        })
        .catch(() => {});
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (!this.searchObj.company_size) {
        this.searchObj.company_size = "";
      }
      // if (!this.searchObj.region_id_new) {
      //   this.searchObj.region_id_new = "";
      // }
      if (!this.searchObj.company_id) {
        this.searchObj.company_id = "";
      }
      if (!this.searchObj.seniority_id) {
        this.searchObj.seniority_id = "";
      }
      if (!this.searchObj.industry_id) {
        this.searchObj.industry_id = "";
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
       if (!this.searchObj.country_id) {
        this.searchObj.country_id = "";
      }
       if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
       if (!this.searchObj.from_date) {
        this.searchObj.from_date = "";
      }

      if (!this.searchObj.to_date) {
        this.searchObj.to_date = "";
      }
      if (this.searchObj.from_date) {
        this.searchObj.start_date = moment(this.searchObj.from_date).format(
          "DD-MM-YYYY"
        );
      }
      if (this.searchObj.to_date) {
        this.searchObj.end_date = moment(this.searchObj.to_date).format(
          "DD-MM-YYYY"
        );
      }
      this.$api
        .getAPI({
          _action: "/open_position/PaginationList",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Open Position");
          if (page == 0) {
            if (this.$refs.listPagination) {
              // alert(res.count);
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            // alert("error");
            this.$refs.errorComponent.setData(
              res,
              "No Open Position list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          // alert(e);
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.company_id = "";
      this.searchObj.country_id = "";
      this.searchObj.company_type = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.region_id_new = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      console.log(data);
      this.$refs.deleteItem.showModal(
        "Delete Open Position",
        "Are you sure you want to delete " + data.title,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/open_position/delete/" + detail.id);
      //   this.getList(0);
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.getindustrylist();
    },
    getindustrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    goToView(id) {
      this.$router.push("/edit-open-position/" + id);
    },
  },
};
</script>