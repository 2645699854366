<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/industry')"
              >
                <span class="font-24 mr-2">
                  <img src="/images/back-arrow.svg" width="18" />
                </span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Industry</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form ref="adminForm" @submit="save">
                      <div class="row">
                        <div class="col-lg-4 col-sm-4">
                          <div class="form-group">
                            <label>Industry Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="industry_name"
                              class="form-control"
                              placeholder="Industry Name"
                              v-model="detail.industry_name"
                              rules="required:industry name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="industry_name"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button
                            type="submit"
                            id="save-button"
                            class="save-btn px-4 ml-3"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-2)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <!-- <div class="col-lg-12 col-sm-12 text-center mt-2"></div> -->

                  <div v-if="id != null" class="card border mb-0 radius-15">
                    <div
                      class="col-lg-12 col-sm-6 text-right"
                      v-show="accessObject?.is_add == 1"
                    >
                      <a
                        style="
                          padding-left: 20px;
                          padding-right: 20px;
                          cursor: pointer;
                        "
                        v-on:click="showAddModelModal"
                        class="add-btn"
                        >Add Subsector</a
                      >
                    </div>
                    <div class="card-body p-0 pb-4">
                      <div class="table-responsive">
                        <div class="dataTables_wrapper dt-bootstrap4">
                          <table
                            class="table table-striped table-bordered dataTable"
                            style="width: 100%"
                            role="grid"
                            aria-describedby="example_info"
                          >
                            <thead>
                              <tr role="row">
                                <th>Sr No</th>
                                <!-- <th
                              class="sorting"
                              id="id-headline"
                              v-on:click="sorting('headline', 'id-headline')"
                             >
                              Headline
                             </th> -->
                                <th
                                  class="sorting"
                                  id="id-subsector_name"
                                  v-on:click="
                                    sorting(
                                      'industry_name',
                                      'id-subsector_name'
                                    )
                                  "
                                >
                                  Subsector Name
                                </th>
                                <th
                                  v-if="
                                    accessObject?.is_edit == 1 ||
                                    accessObject?.is_delete == 1 ||
                                    accessObject?.is_view == 1
                                  "
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody v-show="!loader">
                              <tr v-for="(data, index) in list" :key="index">
                                <td>
                                  {{
                                    ($refs.listPagination.currentPage - 1) *
                                      12 +
                                    index +
                                    1
                                  }}
                                </td>
                                <!-- <td>
                              {{ $helperService.getText(data.headline, 50) }}
                             </td> -->

                                <td>
                                  {{
                                    data.industry_name ? data.industry_name : ""
                                  }}
                                </td>
                                <!-- <td>
                              {{
                                data.executive
                                  ? data.executive.executive_name
                                  : ""
                              }}
                             </td>
                             <td>
                              {{ data.topic && data.topic.topic_name ?  data.topic.topic_name : ''}}
                             </td> -->
                                <td
                                  v-if="
                                    accessObject?.is_edit == 1 ||
                                    accessObject?.is_delete == 1
                                  "
                                >
                                  <a
                                    v-if="accessObject?.is_edit == 1"
                                    class="edit-btn btn-style cursor-pointer"
                                    v-on:click="showAddModelModal(data)"
                                    >Edit</a
                                  >
                                  <a
                                    v-if="accessObject?.is_delete == 1"
                                    class="delete-btn btn-style cursor-pointer"
                                    v-on:click="showDeletePopup(data)"
                                    >Delete</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row mx-0 mb-4">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="listPagination"
                            />
                          </div>
                        </div>
                      </div>
                      <ErrorComponent
                        @retry="getList(0)"
                        ref="errorComponent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddModel ref="addModel" @refreshList="getList(0)" />
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
  <TextModal ref="addSubSector">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Add Subsector</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.addSubSector.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="addSubSectorForm" @submit="addSubSector()">
      <div class="col-12 row">
        <div class="col-12 form-group">
          <label>Sub Sector Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="Sub_Sector"
            class="form-control"
            placeholder="Sub Sector Name"
            v-model="subSectorDetail.industry_name"
            rules="required: Sub Sector name"
            :validateOnInput="true"
          />
          <ErrorMessage name="Sub_Sector" class="text-danger" />
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button id="subsector-add" class="save-btn px-4 ml-3">Save</button>
          <button
            type="button"
            class="fil-btn px-4 ml-3"
            v-on:click="$refs.addSubSector.closeModal"
            id="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  </TextModal>
  <DeleteModal
    @remove="deleteRecord($event)"
    @get-list="getList(0)"
    ref="deleteItem"
  />
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    TextModal,
    Pagination,
    DeleteModal,
  },
  data() {
    return {
      subsector_id: "",
      inserted_id: "",
      accessObject: this.$helperService.getAccessLevel("Manage Industry"),
      id: this.$route.params.id,
      loader: true,
      searchObj: {},
      offset: -1,
      list: [],
      detail: {},
      subSectorDetail: {},
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
      this.getList(0);
    }
  },
  methods: {
    showAddModelModal(data) {
      this.$refs.addSubSector.showModal();
      if (data) {
        this.subsector_id = data.id;
        this.subSectorDetail.industry_name = data.industry_name;
      }
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/subsector/list/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Industry");

          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No Subsector list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      console.log(data);
      this.$refs.deleteItem.showModal(
        "Delete Subsector",
        "Are you sure you want to delete",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/subsector/" + detail.id);
    },
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/industry/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, " ");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/industry",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/industry",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            // this.$router.go(-1);
            if (res && res.inserted_id) {
              this.inserted_id = res.inserted_id;
              this.$notify({
                type: "success",
                text: res.message,
              });
              this.$router.push("/edit-industry/" + this.inserted_id);
              this.id = this.inserted_id;
            }
          })
          .catch(() => {});
      }
    },
    addSubSector() {
      if (this.subsector_id) {
        this.$api
          .putAPI({
            _action: "/edit/subsector/" + this.subsector_id,
            _body: this.subSectorDetail,
            _buttonId: "subsector-add",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.addSubSectorForm.resetForm();
            this.$refs.addSubSector.closeModal();
            if (this.id) {
              this.getDetail();
              this.getList(0);
            }
          })
          .catch(() => {});
      } else {
        this.subSectorDetail.industry_id = this.id;
        this.$api
          .postAPI({
            _action: "/subsector/add",
            _body: this.subSectorDetail,
            _buttonId: "subsector-add",
          })
          .then(() => {
            this.$refs.addSubSectorForm.resetForm();
            this.$refs.addSubSector.closeModal();
            if (this.id) {
              this.getDetail();
              this.getList(0);
            }
          })
          .catch(() => {});
      }
    },
    addSubsector() {
      this.detail.subsectors.push({ name: "" });
      // if (this.detail.subsectors.length < 3) {
      //   this.detail.subsectors.push({ name: "" });
      // }
    },
    removeSubsector(index) {
      if (this.detail.subsectors.length > 1) {
        this.detail.subsectors.splice(index, 1);
      }
    },
  },
};
</script>
