<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-9 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Executive</h4>
          </div>

          <div class="col-3 d-flex justify-content-end">
            <div class="" v-show="accessObject?.is_add == 1">
              <a
                style="padding-left: 20px; padding-right: 20px; cursor: pointer"
                v-on:click="$router.push('/add-executive')"
                class="add-btn"
                >Add</a
              >
            </div>
            <div class="ml-2" v-if="role == 'admin'">
              <a
                v-on:click="getExecutiveExport()"
                style="cursor: pointer"
                class="add-btn"
                >Export</a
              >
            </div>
            <div class="ml-2">
              <a
                v-on:click="showImportPopup()"
                style="cursor: pointer"
                class="add-btn"
                >Import</a
              >
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="px-0">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <Field
                    name="keyword"
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                    style="border: 0px none; width: 100%" />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label class="label">From Date</label> -->
                  <datepicker
                    v-model="searchObj.from_date"
                    inputFormat="dd/MM/yyyy"
                    placeholder="From Date"
                    class="form-control"
                    style="background-color: white !important; height: 35px"
                    :clearable="false"
                    :upperLimit="
                      searchObj.to_date ? searchObj.to_date : new Date()
                    ">
                  </datepicker>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label class="label">To Date</label> -->
                  <datepicker
                    v-model="searchObj.to_date"
                    inputFormat="dd/MM/yyyy"
                    class="form-control"
                    placeholder="To Date"
                    :clearable="false"
                    style="background-color: white !important; height: 35px"
                    :lowerLimit="
                      searchObj.from_date ? searchObj.from_date : null
                    "
                    :upperLimit="new Date()">
                  </datepicker>
                </div>
              </div>
              <hr class="mt-4 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Current Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  closeOnSelect="true"
                  placeholder="Select"
                  v-model="searchObj.new_company_id"
                  :options="companyList"
                  searchable="true"
                  @search-change="searchCompanyName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Job Function</label>
                <Multiselect
                  mode="single"
                  trackBy="job_function_name"
                  label="job_function_name"
                  valueProp="id"
                  v-model="searchObj.job_function_id_new"
                  :options="jobFunctionList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchJobFunctionName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>

                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id_new"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchIndustryName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Location</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  v-model="searchObj.country_id"
                  :options="cityList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchCityName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Gender</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="view"
                  valueProp="value"
                  v-model="searchObj.gender"
                  :options="genders"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchGenderName($event)" />
                <!-- <select v-model="searchObj.gender" class="select">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select> -->
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label></label>
                <button
                  v-on:click="getList(0)"
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  class="apply-btn mr-1">
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn">
                  Reset
                </button>
                <div class="text-center">
                  <a
                    style="cursor: pointer; text-decoration: underline"
                    v-on:click="getAdvanceSearch()"
                    class="advance-search"
                    >{{ is_show ? "Hide Advance Search" : "Advance Search" }}</a
                  >
                </div>
              </div>
            </div>
            <!-- <br /> -->
            <div v-show="is_show" class="row advasearch-data mt-1 mb-2">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Education</label>
                <Multiselect
                  mode="single"
                  trackBy="education_name"
                  label="education_name"
                  valueProp="id"
                  v-model="searchObj.education_id"
                  :options="educationList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchEducationName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">University</label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  v-model="searchObj.education_institutre_name"
                  :options="universityList"
                  searchable="true"
                  placeholder="Select" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Age Range</label>
                <Multiselect
                  mode="single"
                  trackBy="age_range_name"
                  label="age_range_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.age_range_id"
                  :options="ageRangeList"
                  searchable="true"
                  @search-change="searchAgeRangeName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Past Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.prev_company_id"
                  :options="companyList"
                  searchable="true"
                  @search-change="searchCompanyName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Seniority Level</label>
                <Multiselect
                  mode="single"
                  trackBy="seniority_name"
                  label="seniority_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.seniority_id"
                  :options="seniorityList"
                  searchable="true"
                  @search-change="searchSeniorityName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Region (Current)</label>
                <Multiselect
                  mode="single"
                  trackBy="region_name"
                  label="region_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.region_id_new"
                  :options="regionList"
                  searchable="true"
                  @search-change="searchRegionName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-3">
                <label class="label">Region (Past)</label>
                <Multiselect
                  mode="single"
                  trackBy="region_name"
                  label="region_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.region_id_prev"
                  :options="regionList"
                  searchable="true"
                  @search-change="searchRegionName($event)" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-3">
                <label class="label">Company Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_type"
                  :options="company_types"
                  searchable="true"
                  placeholder="Select" />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-3">
                <label class="label">Year</label>
                <Field
                  type="text"
                  name="year"
                  class="form-control"
                  placeholder="year"
                  v-model="searchObj.curr_year"
                  v-slot="{ field }">
                  <Datepicker
                    autoApply
                    placeholder="year"
                    v-bind="field"
                    v-model="searchObj.curr_year"
                    yearPicker />
                </Field>
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-3">
                <label class="label">Limit</label>
                <Field
                  name="limit"
                  type="text"
                  class="form-control"
                  placeholder="Limit"
                  v-model="searchObj.limit"
                  style="border: 1px solid black; width: 100%" />
                <!-- </form> -->
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>New Job Title</th>
                          <th>New Company</th>
                          <th>Industry</th>
                          <th>Function</th>
                          <th>Location</th>
                          <!-- <th>Status</th> -->
                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1
                            ">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            <img
                              v-if="data.executive_logo || !data.gender"
                              :src="
                                data.executive_logo
                                  ? data.executive_logo
                                  : '/img/user-pic.png'
                              "
                              class="user-img"
                              id="loginuser-image" />

                            <img
                              v-if="
                                data.gender == 'male' && !data.executive_logo
                              "
                              :src="
                                data.executive_logo
                                  ? data.executive_logo
                                  : '/img/male.png'
                              "
                              class="user-img"
                              id="loginuser-image" />
                            <img
                              v-if="
                                data.gender == 'female' && !data.executive_logo
                              "
                              :src="
                                data.executive_logo
                                  ? data.executive_logo
                                  : '/img/female.png'
                              "
                              class="user-img"
                              id="loginuser-image" />
                          </td>

                          <td>
                            {{ data.executive_name }}
                          </td>
                          <td>
                            {{
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.new_company
                                ? data.new_company.company_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.new_industry
                                ? data.new_industry.industry_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.new_function
                                ? data.new_function.job_function_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{ data.country ? data.country.country_name : "" }}
                          </td>
                          <!-- <td>
                            <a
                              v-show="data.is_pending == 'N'"
                              id="status-btn"
                              class="fill-btn btn-style"
                              >APPROVED</a
                            >
                            <a
                              v-show="data.is_pending == 'Y'"
                              id="status-btn"
                              class="edit-btn btn-style"
                              >PENDING</a
                            >
                          </td> -->
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1
                            ">
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="
                                goToView('/edit-executive/' + data.id)
                              "
                              >Edit</a
                            >
                            <a
                              v-if="accessObject?.is_delete == 1"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                            <a
                              v-if="
                                role == 'admin' || role == 'quality_analyst'
                              "
                              style="cursor: pointer"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="pdfView(data.id)"
                            >
                              <i class="fa fa-download" aria-hidden="true"></i
                            ></a>
                            <!-- <a
                              v-if="
                                role == 'admin' || role == 'quality_analyst'
                              "
                              style="cursor: pointer"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="downloadExecutivePdf(data.id)">
                              <i class="fa fa-download" aria-hidden="true"></i
                            ></a> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem" />
  </div>
  <TextModal ref="textModal">
    <!-- <Form ref="practiceForm" @submit="uploadDocument()"> -->
    <div class="modal-header">
      <h5 class="modal-title">Import Company</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-lg-12 col-sm-12 mb-3">
        <div class="text-center mb-4">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile04"
                v-on:change="onChange($event)" />
              <label
                class="custom-file-label"
                style="text-align: left"
                for="inputGroupFile04"
                >{{ docFile ? docFile.name : "Choose File" }}</label
              >
              <!-- <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
                <label class="custom-file-label" for="inputGroupFile04">Choose file</label> -->
            </div>
          </div>
          <p>
            <a href="" v-on:click="downloadTemplate">Download Template </a>
            file and fill with the items information.
          </p>
          <div id="msg"></div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        id="cancle-button"
        class="btn btn-secondary position-relative w-auto px-4"
        data-dismiss="modal"
        v-on:click="$refs.textModal.closeModal">
        Close
      </button>
      <button
        v-on:click="uploadDocument()"
        id="document-button"
        type="button"
        class="btn btn-primary position-relative w-auto mt-0 mb-0 px-4">
        Import
      </button>
    </div>

    <!-- </Form> -->
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import moment from "moment";
import datepicker from "vue3-datepicker";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Multiselect from "@vueform/multiselect";
import { Field } from "vee-validate";
import TextModal from "@/components/TextModal";

export default {
  name: "Executive",
  components: {
    ErrorComponent,
    TextModal,
    Multiselect,
    Field,
    DeleteModal,
    Pagination,
    datepicker,
    Datepicker,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Executive"),
      loader: true,
      is_show: false,
      role: localStorage.getItem("role"),
      docFile: undefined,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      genders: [
        {
          value: "male",
          view: "Male",
        },
        { value: "female", view: "Female" },
      ],
      searchObj: {
        from_date: "",
        to_date: "",
        gender: "",
        keyword: "",
        industry_id_new: "",
        new_company_id: "",
        education_institutre_name: "",
        prev_company_id: "",
        job_function_id_new: "",
        seniority_id: "",
        company_type: "",
        city_id: "",
        education_id: "",
        age_range_id: "",
        region_id_prev: "",
        region_id_new: "",
        limit: "",
      },
      offset: -1,
      list: [],
      universityList: [],
      cityList: [],
      company_ids: [],
      industryList: [],
      companyList: [],
      jobFunctionList: [],
      educationList: [],
      seniorityList: [],
      ageRangeList: [],
      regionList: [],
      cityKeyword: "",
      companyKeyword: "",
      jobFunctionKeyword: "",
      educationKeyword: "",
      industryKeyword: "",
      searchGenderKeyword: "",
      ageRangeKeyword: "",
      regionKeyword: "",
    };
  },
  mounted() {
    this.getList(0);
    this.companylist();
    this.industrylist();
    this.CityList();
    this.jobFunctionlist();
    this.getEducationlist();
    this.getAgeRangeList();
    this.getRegionlist();
    this.getSenioritylist();
    this.getUniversitylist();
  },
  methods: {
    getUniversitylist() {
      var obj = {};
      if (this.educationKeyword) {
        obj.keyword = this.educationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/university/list",
          _body: obj,
        })
        .then((res) => {
          this.universityList = res;
        })
        .catch(() => {});
    },

    getExecutiveExport() {
      var obj = {};
      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      } else {
        obj.from_date = "";
      }

      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      } else {
        obj.to_date = "";
      }

      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      } else {
        obj.keyword = "";
      }

      if (this.searchObj.gender) {
        obj.gender = this.searchObj.gender;
      } else {
        obj.gender = "";
      }
      if (this.searchObj.industry_id_new) {
        obj.industry_id_new = this.searchObj.industry_id_new;
      } else {
        obj.industry_id_new = "";
      }
      if (this.searchObj.new_company_id) {
        obj.new_company_id = this.searchObj.new_company_id;
      } else {
        obj.new_company_id = "";
      }
      if (this.searchObj.prev_company_id) {
        obj.prev_company_id = this.searchObj.prev_company_id;
      } else {
        obj.prev_company_id = "";
      }
      if (this.searchObj.job_function_id_new) {
        obj.job_function_id_new = this.searchObj.job_function_id_new;
      } else {
        obj.job_function_id_new = "";
      }
      if (this.searchObj.seniority_id) {
        obj.seniority_id = this.searchObj.seniority_id;
      } else {
        obj.seniority_id = "";
      }
      if (this.searchObj.company_type) {
        obj.company_type = this.searchObj.company_type;
      } else {
        obj.company_type = "";
      }
      if (this.searchObj.city_id) {
        obj.city_id = this.searchObj.city_id;
      } else {
        obj.city_id = "";
      }
      if (this.searchObj.education_id) {
        obj.education_id = this.searchObj.education_id;
      } else {
        obj.education_id = "";
      }
      if (this.searchObj.age_range_id) {
        obj.age_range_id = this.searchObj.age_range_id;
      } else {
        obj.age_range_id = "";
      }
      if (this.searchObj.education_institutre_name) {
        obj.education_institutre_name =
          this.searchObj.education_institutre_name;
      } else {
        obj.education_institutre_name = "";
      }

      if (this.searchObj.region_id_prev) {
        obj.region_id_prev = this.searchObj.region_id_prev;
      } else {
        obj.region_id_prev = "";
      }
      if (this.searchObj.region_id_new) {
        obj.region_id_new = this.searchObj.region_id_new;
      } else {
        obj.region_id_new = "";
      }
      if (this.searchObj.limit) {
        obj.limit = this.searchObj.limit;
      } else {
        obj.limit = "";
      }
      // var url = this.$api.getBaseUrl() + "/company/export/data";

      var url =
        this.$api.getBaseUrl() +
        "/executive/export/data?keyword=" +
        obj.keyword +
        "&start_date=" +
        obj.from_date +
        "&end_date=" +
        obj.to_date +
        "&gender=" +
        obj.gender +
        "&industry_id_new=" +
        obj.industry_id_new +
        "&new_company_id=" +
        obj.new_company_id +
        "&prev_company_id=" +
        obj.prev_company_id +
        "&job_function_id_new=" +
        obj.job_function_id_new +
        "&seniority_id=" +
        obj.seniority_id +
        "&company_type=" +
        obj.company_type +
        "&city_id=" +
        obj.city_id +
        "&region_id_prev=" +
        obj.region_id_prev +
        "&region_id_new=" +
        obj.region_id_new +
        "&age_range_id=" +
        obj.age_range_id +
        "&education_id=" +
        obj.education_id +
        "&limit=" +
        obj.limit +
        "&education_institutre_name=" +
        obj.education_institutre_name;
      // alert(url);
      // console.log("Fd",url)
      window.open(url);
    },
    showImportPopup() {
      this.docFile = undefined;
      this.$refs.textModal.showModal();
    },
    downloadTemplate() {
      var url = "executive-file.csv";
      window.open(this.$api.getBaseUrl().replaceAll("api/admin", "") + url);
    },
    pdfView(executive_id) {
      this.$router.push("/preview-pdf-executive/" + executive_id);
    },
    downloadExecutivePdf(id) {
      if (id) {
        var url =
          this.$api.getBaseUrl() + "/executive/getExecutivePdf?id=" + id;
        window.open(url);
      }
    },
    uploadDocument() {
      if (this.docFile) {
        this.$api
          .uploadImageAPI({
            _action: "/executive/import/data",
            _key: "file",
            _file: this.docFile,
            _buttonId: "document-button",
          })
          .then((res) => {
            // window.open(this.$api.getBaseUrl() + res.url);
            // // window.open("https://apps.grademybeauty.com/api" + res.url)
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getList(0);
            this.$refs.textModal.closeModal();
          });
      } else {
        this.$refs.textModal.closeModal();
      }
    },
    onChange(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "application/pdf" &&
      //   files[0].type.toLowerCase() != "application/doc"
      // ) {
      //   alert("Invalid file formate, please use pdf file.");
      //   return;
      // }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
      console.log(this.docFile);
    },
    searchGenderName(e) {
      this.searchGenderKeyword = e;
      this.genders;
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchSeniorityName(e) {
      this.seniorityKeyword = e;
      this.getSenioritylist();
    },
    searchEducationName(e) {
      this.educationKeyword = e;
      this.getEducationlist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchRegionName(e) {
      this.regionKeyword = e;
      this.getRegionlist();
    },
    searchAgeRangeName(e) {
      this.ageRangeKeyword = e;
      this.getAgeRangeList();
    },
    getAgeRangeList() {
      var obj = {};
      if (this.ageRangeKeyword) {
        obj.keyword = this.ageRangeKeyword;
      }
      this.$api
        .getAPI({
          _action: "/age/range/list",
          _body: obj,
        })
        .then((res) => {
          this.ageRangeList = res;
        })
        .catch(() => {});
    },
    getRegionlist() {
      var obj = {};
      if (this.regionKeyword) {
        obj.keyword = this.regionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/region/list",
          _body: obj,
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    getSenioritylist() {
      var obj = {};
      if (this.seniorityKeyword) {
        obj.keyword = this.seniorityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/seniority/list",
          _body: obj,
        })
        .then((res) => {
          this.seniorityList = res;
        })
        .catch(() => {});
    },
    getEducationlist() {
      var obj = {};
      if (this.educationKeyword) {
        obj.keyword = this.educationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/education/list",
          _body: obj,
        })
        .then((res) => {
          this.educationList = res;
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.company_ids = [];
          this.companyList = res;
        })
        .catch(() => {});
    },

    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    getAdvanceSearch() {
      this.is_show = !this.is_show;
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    goToView(url) {
      this.searchObj.is_show = this.is_show;
      this.$shareService.setMapValue("executive_page_filter", this.searchObj);
      this.$router.push(url);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (!this.searchObj.gender) {
        this.searchObj.gender = "";
      }
      if (!this.searchObj.keyword) {
        this.searchObj.keyword = "";
      }
      if (!this.searchObj.industry_id_new) {
        this.searchObj.industry_id_new = "";
      }
      if (!this.searchObj.new_company_id) {
        this.searchObj.new_company_id = "";
      }
      if (!this.searchObj.prev_company_id) {
        this.searchObj.prev_company_id = "";
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
      if (!this.searchObj.seniority_id) {
        this.searchObj.seniority_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (!this.searchObj.city_id) {
        this.searchObj.city_id = "";
      }
      if (!this.searchObj.education_id) {
        this.searchObj.education_id = "";
      }
      if (!this.searchObj.age_range_id) {
        this.searchObj.age_range_id = "";
      }
      if (!this.searchObj.region_id_prev) {
        this.searchObj.region_id_prev = "";
      }
      if (!this.searchObj.region_id_new) {
        this.searchObj.region_id_new = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (this.offset != page) {
        this.searchObj.offset = page;
      } else {
        this.searchObj.offset = page;
      }
      if (!this.searchObj.from_date) {
        this.searchObj.from_date = "";
      }

      if (!this.searchObj.to_date) {
        this.searchObj.to_date = "";
      }
      if (this.searchObj.from_date) {
        this.searchObj.start_date = moment(this.searchObj.from_date).format(
          "DD-MM-YYYY"
        );
      }
      if (this.searchObj.to_date) {
        this.searchObj.end_date = moment(this.searchObj.to_date).format(
          "DD-MM-YYYY"
        );
      }

      var filter_obj = this.$shareService.getMapValue("executive_page_filter")
        ? this.$shareService.getMapValue("executive_page_filter")
        : null;
      if (filter_obj) {
        this.searchObj = filter_obj;
        this.is_show = this.searchObj.is_show;
        this.$shareService.getMapValueAndRemove("executive_page_filter");
        if (this.searchObj.new_company_id) {
          this.company_ids.push(this.searchObj.new_company_id);
        }
        if (this.searchObj.prev_company_id) {
          this.company_ids.push(this.searchObj.prev_company_id);
        }
        this.companylist();
      }
      this.$api
        .getAPI({
          _action: "/executive/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Executive");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(
                  this.searchObj.offset + 1
                );
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No executive list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj = {};
      this.searchObj.gender = "";
      this.searchObj.keyword = "";
      this.searchObj.industry_id_new = "";
      this.searchObj.new_company_id = "";
      this.searchObj.prev_company_id = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.seniority_id = "";
      this.searchObj.company_type = "";
      this.searchObj.city_id = "";
      this.searchObj.education_id = "";
      this.searchObj.age_range_id = "";
      this.searchObj.region_id_new = "";
      this.searchObj.region_id_prev = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Executive",
        "Are you sure you want to delete",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/executive/" + detail.id);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
