<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/news')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} QA</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="form-group ml-3 mb-3">
                        <h2>News</h2>
                      </div>
                      <div class="col-12 row">
                        <!-- <div class="col-lg-12 Newscol-sm-6"> -->
                        <div class="col-8 form-group">
                          <label>News URL </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="Link"
                            class="form-control"
                            placeholder="Link"
                            v-model="detail.news_link"
                            rules="required: link|website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Link" class="text-danger" />
                        </div>
                        <div class="col-4" style="margin-top: 39px">
                          <a
                            type="button"
                            class="fill-btn"
                            v-on:click="getTitleFromUrl(detail.news_link)"
                          >
                            Get Title
                          </a>
                        </div>
                        <div class="col-6 form-group">
                          <label>Headline</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="News Title"
                            class="form-control"
                            placeholder="News Title"
                            v-model="detail.news_title"
                            rules="required:news title"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="News Title" class="text-danger" />
                        </div>
                        <div class="col-6 form-group">
                          <label> Published Date </label>

                          <Field
                            type="text"
                            name="Published"
                            class="form-control"
                            placeholder="Published Date"
                            v-model="detail.news_date"
                            rules="required:published date"
                            :validateOnInput="true"
                          >
                            <Datepicker
                              autoApply
                              v-model="detail.news_date"
                              inputFormat="dd-MM-yyyy"
                              format="dd-MM-yyyy"
                            >
                            </Datepicker>
                          </Field>
                          <ErrorMessage name="Published" class="text-danger" />
                        </div>

                        <div class="col-6 form-group">
                          <label>News Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            name="type"
                            v-model="detail.type"
                            rules="required:type"
                            :validateOnInput="true"
                          >
                            <option value="">Select Type</option>
                            <option value="arrival_departure">
                              Arrival Departure
                            </option>
                            <option value="internal_move">Internal Move</option>
                            <option value="retirement">Retirement</option>
                            <option value="lay_off">Lay Off</option>
                            <option value="own_venture">Own Venture</option>
                            <option value="terminated">Terminated</option>
                          </Field>
                          <ErrorMessage name="type" class="text-danger" />
                        </div>
                        <div class="col-6 form-group row">
                          <div class="col-8">
                            <label>Publication Name </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="publication_id"
                              placeholder="Select"
                              rules="required: publication"
                              v-model="detail.publication_id"
                              :validateOnInput="true"
                              v-slot="{ field }"
                            >
                              <Multiselect
                                mode="single"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                placeholder="Select"
                                v-model="detail.publication_id"
                                :options="publicationList"
                                searchable="true"
                                @search-change="searchpublicationName($event)"
                                v-bind="field"
                              />
                            </Field>
                            <ErrorMessage
                              name="publication_id"
                              class="text-danger"
                            />
                          </div>
                          <div class="col-4" style="margin-top: 39px">
                            <a
                              type="button"
                              class="fill-btn"
                              v-on:click="showPublicationPopup()"
                            >
                              Add New
                            </a>
                          </div>
                        </div>
                        <div class="col-6" style="margin-left: 30px">
                          <span> Add Company Logo</span>
                          <span class="text-danger">*</span>
                          <div>
                            <input
                              type="checkbox"
                              id="is_company_logo"
                              name="is_company_logo"
                              style="height: 52px; width: 27px"
                              true-value="1"
                              false-value="0"
                              v-model="detail.is_company_logo"
                            />
                          </div>
                        </div>
                        <div class="col-12 form-group">
                          <h2 class="mb-3">Primary Executive</h2>

                          <div class="col-12 row">
                            <div class="col-12 row">
                              <div class="col-6 form-group">
                                <label>Executive Name </label>
                                <span class="text-danger">*</span>
                                <Field
                                  name="executive_id"
                                  placeholder="Select"
                                  v-model="detail.executive_id"
                                  rules="required: executive"
                                  :validateOnInput="true"
                                  v-slot="{ field }"
                                >
                                  <Multiselect
                                    mode="single"
                                    trackBy="executive_name"
                                    label="executive_name"
                                    valueProp="id"
                                    placeholder="Select"
                                    v-model="detail.executive_id"
                                    :options="executiveList"
                                    searchable="true"
                                    v-bind="field"
                                    v-on:change="geteExecutivedetail($event)"
                                    @search-change="
                                      searchexecutiveName($event, 'FALSE')
                                    "
                                  >
                                    <template v-slot:option="{ option }">
                                      <img
                                        class="option-icon"
                                        :src="
                                          option.executive_logo
                                            ? option.executive_logo
                                            : '/img/user-pic.png'
                                        "
                                      />
                                      {{ option.name }}
                                    </template>
                                  </Multiselect>
                                </Field>
                                <ErrorMessage
                                  name="executive_id"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-2 form-group">
                              <label for="inputRole">Current Company</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="1company_name"
                                placeholder="Select"
                                rules="required: current company"
                                v-model="detail.company_id"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="company_name"
                                  label="company_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.company_id"
                                  :options="companyList"
                                  searchable="true"
                                  v-bind="field"
                                  @search-change="searchCompanyName($event)"
                                >
                                  <template v-slot:option="{ option }">
                                    <img
                                      class="option-icon"
                                      :src="
                                        option.company_logo
                                          ? option.company_logo
                                          : '/img/user-pic.png'
                                      "
                                    />
                                    {{ option.company_name }}
                                  </template>
                                </Multiselect>
                              </Field>
                              <ErrorMessage
                                name="1company_name"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label for="inputRole">Current Industry</label>
                              <span class="text-danger">*</span>

                              <Field
                                type="text"
                                name="industry_id_new"
                                placeholder="Select"
                                rules="required: current industry"
                                v-model="detail.industry_id_new"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="industry_name"
                                  label="industry_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.industry_id_new"
                                  :options="industryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="industry_id_new"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label>Current Job Title </label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                class="form-control"
                                name="New_Job_Title"
                                placeholder="Current Job Title"
                                v-model="detail.new_job_title"
                                rules="required: current job title"
                                :validateOnInput="true"
                              />
                              <ErrorMessage
                                name="New_Job_Title"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label>Current Function</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="jobFunctionList"
                                rules="required: current function"
                                v-model="detail.job_function_id_new"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="job_function_name"
                                  label="job_function_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.job_function_id_new"
                                  :options="jobFunctionList"
                                  searchable="true"
                                  v-bind="field"
                                  @search-change="searchfunctionName($event)"
                                />
                              </Field>
                              <ErrorMessage
                                name="jobFunctionList"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label>Current Location</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="prev_job_location"
                                rules="required:new job location"
                                v-model="detail.new_job_location"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="country_name"
                                  label="country_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.new_job_location"
                                  :options="countryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="prev_job_location"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label for="inputRole">Current Region</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="region_name"
                                placeholder="Select"
                                rules="required: current region"
                                v-model="detail.region_id_new"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="region_name"
                                  label="region_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.region_id_new"
                                  :options="regionList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="region_name"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label for="inputRole"
                                >Past Company
                                {{ detail.prev_company_id }}</label
                              >
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="past"
                                placeholder="Select"
                                rules="required: past company"
                                v-model="detail.prev_company_id"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="company_name"
                                  label="company_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.prev_company_id"
                                  :options="companyList"
                                  searchable="true"
                                  v-bind="field"
                                  @search-change="searchCompanyName($event)"
                                >
                                  <template v-slot:option="{ option }">
                                    <img
                                      class="option-icon"
                                      :src="
                                        option.company_logo
                                          ? option.company_logo
                                          : '/img/user-pic.png'
                                      "
                                    />
                                    {{ option.company_name }}
                                  </template>
                                </Multiselect>
                              </Field>
                              <ErrorMessage name="past" class="text-danger" />
                            </div>
                            <div class="col-2 form-group">
                              <label for="inputRole">Past Industry</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="industry_id_prev"
                                placeholder="Select"
                                rules="required: past industry"
                                v-model="detail.industry_id_prev"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="industry_name"
                                  label="industry_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.industry_id_prev"
                                  :options="industryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="industry_id_prev"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label>Past Job Title </label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                class="form-control"
                                name="Past_Job_Title"
                                placeholder="Past Job Title"
                                v-model="detail.prev_job_title"
                                rules="required: past job title"
                                :validateOnInput="true"
                              />
                              <ErrorMessage
                                name="Past_Job_Title"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label>Past Function</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="jobFunctionList"
                                rules="required:past job function"
                                v-model="detail.job_function_id_prev"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="job_function_name"
                                  label="job_function_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.job_function_id_prev"
                                  :options="jobFunctionList"
                                  searchable="true"
                                  v-bind="field"
                                  @search-change="searchfunctionName($event)"
                                />
                              </Field>
                              <ErrorMessage
                                name="jobFunctionList"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label>Past Location</label>
                              <span class="text-danger">*</span>

                              <Field
                                type="text"
                                name="prev_job_location"
                                rules="required:past job location"
                                v-model="detail.prev_job_location"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="country_name"
                                  label="country_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.prev_job_location"
                                  :options="countryList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="prev_job_location"
                                class="text-danger"
                              />
                            </div>
                            <div class="col-2 form-group">
                              <label for="inputRole">Current Region</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="region_name"
                                placeholder="Select"
                                rules="required: past region"
                                v-model="detail.region_id_prev"
                                :validateOnInput="true"
                                v-slot="{ field }"
                              >
                                <Multiselect
                                  mode="single"
                                  trackBy="region_name"
                                  label="region_name"
                                  valueProp="id"
                                  placeholder="Select"
                                  v-model="detail.region_id_prev"
                                  :options="regionList"
                                  searchable="true"
                                  v-bind="field"
                                />
                              </Field>
                              <ErrorMessage
                                name="region_name"
                                class="text-danger"
                              />
                            </div>
                            <label style="margin-left: 18px"
                              >Open Position</label
                            >
                            <div
                              class="col-3 form-group mt-3"
                              style="margin-left: -80px"
                            >
                              <div className="toggle-button-cover">
                                <div class="button-cover">
                                  <div class="button r" id="button-1">
                                    <input
                                      type="checkbox"
                                      class="checkbox"
                                      name="is_openPosition"
                                      true-value="1"
                                      false-value="0"
                                      v-model="is_openPosition"
                                      id="button-2"
                                    />
                                    <div class="knobs"></div>
                                    <div class="layer"></div>
                                    <label class="label" for="button-2" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 form-group mb-5">
                          <h2 class="mb-2">Mentioned Company</h2>
                          <!-- <span class="text-danger">*</span> -->
                          <Multiselect
                            mode="tags"
                            trackBy="company_name"
                            label="company_name"
                            valueProp="id"
                            placeholder="Select"
                            v-model="news_mention_company"
                            object="true"
                            :options="companyList"
                            searchable="true"
                            @search-change="searchCompanyName($event)"
                          >
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.company_logo
                                    ? option.company_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.company_name }}
                            </template>
                          </Multiselect>
                        </div>
                        <div class="col-12 form-group mb-5">
                          <h2 class="mb-2">Mentioned Executive</h2>
                          <!-- <span class="text-danger">*</span> -->

                          <Multiselect
                            mode="tags"
                            trackBy="executive_name"
                            label="executive_name"
                            valueProp="id"
                            placeholder="Select"
                            object="true"
                            v-model="news_mention_executives"
                            :options="executiveList"
                            searchable="true"
                            @search-change="
                              searchexecutiveName($event, 'FALSE')
                            "
                          >
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.executive_logo
                                    ? option.executive_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.name }}
                            </template>
                          </Multiselect>
                        </div>
                        <hr />
                        <div class="col-12 row">
                          <div class="form-group col-2">
                            <label>Flag for Review</label>
                            <div>
                              <input
                                type="checkbox"
                                id="is_review"
                                name="is_review"
                                style="height: 52px; width: 27px"
                                true-value="1"
                                false-value="0"
                                v-model="detail.is_review"
                              />
                            </div>
                          </div>
                          <div class="form-group col-6">
                            <label></label>
                            <textarea
                              type="text"
                              name="Current_Responsibility"
                              class="form-control"
                              placeholder=""
                              v-model="detail.reason_for_flag"
                            >
                            </textarea>
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Add Publication</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="changePasswordForm" @submit="addPubliction()">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label>Publication Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="user_name"
            class="form-control"
            placeholder="Publication Name"
            v-model="detail.name"
            rules="required: publication name"
            :validateOnInput="true"
          />
          <ErrorMessage name="user_name" class="text-danger" />
        </div>
        <div class="col-6 form-group">
          <label>Website Url</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="website_url"
            class="form-control"
            placeholder="Website Url"
            v-model="detail.website_url"
            rules="required: website url |website"
            :validateOnInput="true"
          />
          <ErrorMessage name="website_url" class="text-danger" />
        </div>
        <div class="col-6 mt-3 mb-3">
          <label>Publication Logo</label>
          <div class="text-left">
            <ImagePicker
              :src="detail.publication_logo"
              :modelValue="detail.publication_logo"
              ref="ImagePicker"
            />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button id="publication-button" class="save-btn px-4 ml-3">Save</button>
          <button
            type="button"
            class="fil-btn px-4 ml-3"
            v-on:click="$refs.textModal.closeModal"
            id="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import Datepicker from "@vuepic/vue-datepicker";
import TextModal from "@/components/TextModal";
import "@vuepic/vue-datepicker/dist/main.css";
import ImagePicker from "@/components/ImagePicker.vue";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    ErrorMessage,
    ImagePicker,
    Multiselect,
    ErrorComponent,
    TextModal,
    Datepicker,
  },
  data() {
    return {
      loader: false,
      news_mention_executives: [],
      news_mention_company: [],
      publicationList: [],
      company_ids: [],
      executive_ids: [],
      id: this.$route.params.id,
      is_openPosition: 0,
      seniority_id: "",
      detail: {
        is_company_logo: 0,
      },
      executiveList: [],
      companyList: [],
      jobFunctionList: [],
      countryList: [],
      regionList: [],
      industryList: [],
      companyKeyword: "",
      publicationKeyword: "",
      executiveKeyword: "",
      functionKeyword: "",
    };
  },
  mounted() {
    if (!this.id) {
      this.getexecutivelist("TRUE");
    } else {
      this.getexecutivelist();
    }
    this.getcompanylist();
    this.getjobFunctionlist();
    this.getRegionList();
    this.getindustrylist();
    this.getpublicationlist();
    this.getCountryList();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getTitleFromUrl(url) {
      if (url) {
        this.$refs.errorComponent.updateFormLoader(true);
        var obj = {};
        obj.url = url;
        this.$api
          .getAPI({
            _action: "/get/title/url",
            _body: obj,
          })
          .then((res) => {
            this.detail.news_title = res.title;
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      } else {
        this.$notify({
          type: "error",
          text: "Please Enter News Url First.",
        });
      }
    },
    searchfunctionName(e) {
      this.functionKeyword = e;
      this.getjobFunctionlist();
    },
    searchpublicationName(e) {
      this.publicationKeyword = e;
      this.getpublicationlist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.getcompanylist();
    },
    searchexecutiveName(e, type) {
      this.executiveKeyword = e;
      this.getexecutivelist(type);
    },
    getindustrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getRegionList() {
      this.$api
        .getAPI({
          _action: "/region/list",
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    geteExecutivedetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/executive/detail/" + id,
          })

          .then((res) => {
            this.detail.company_id = res.new_company_id;
            this.detail.prev_company_id = res.prev_company_id;
            this.detail.prev_job_title = res.prev_job_title;
            this.detail.new_job_title = res.new_job_title;
            this.detail.job_function_id_prev = res.job_function_id_prev;
            this.detail.job_function_id_new = res.job_function_id_new;
            this.detail.prev_job_location = res.new_company_id;
            this.detail.new_job_location = res.country_id;
            this.detail.region_id_prev = res.region_id_prev;
            this.detail.region_id_new = res.region_id_new;
            this.detail.industry_id_prev = res.industry_id_prev;
            this.detail.industry_id_new = res.industry_id_new;
            this.seniority_id = res.seniority_id;
          })
          .catch(() => {});
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/news/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;

          this.is_openPosition =
            this.detail.position && this.detail.position.is_active == "Y"
              ? 1
              : 0;
          if (this.detail.news_mention_executives.length > 0) {
            this.news_mention_executives;
            for (var i in this.detail.news_mention_executives) {
              this.news_mention_executives.push({
                id: this.detail.news_mention_executives[i].executives_id,
              });
              this.executive_ids.push(
                this.detail.news_mention_executives[i].executives_id
              );
            }
          }
          if (this.detail.news_mention_company.length > 0) {
            this.news_mention_company = [];
            for (var k in this.detail.news_mention_company) {
              this.company_ids.push(
                this.detail.news_mention_company[k].company_id
              );
              this.news_mention_company.push({
                id: this.detail.news_mention_company[k].company_id,
              });
            }
          }
          this.company_ids.push(this.detail.company_id);
          this.company_ids.push(this.detail.prev_company_id);
          this.executive_ids.push(this.detail.executive_id);
          this.searchCompanyName();
          this.searchexecutiveName(null, "TRUE");
        })
        .catch(() => {});
    },
    getpublicationlist() {
      var obj = {};
      if (this.publicationKeyword) {
        obj.keyword = this.publicationKeyword;
      }

      this.$api
        .getAPI({
          _action: "/publications/list",
          _body: obj,
        })
        .then((res) => {
          this.publicationList = res;
        })
        .catch(() => {});
    },
    getCountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    getjobFunctionlist() {
      var obj = {};
      if (this.functionKeyword) {
        obj.keyword = this.functionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
          _body: obj,
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    getexecutivelist(type) {
      if (!type || type == "TRUE") {
        this.$refs.errorComponent.updateFormLoader(true);
      }
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      if (this.executive_ids.length > 0) {
        obj.executive_ids = this.executive_ids;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList = res;
          for (var i in this.executiveList) {
            if (this.executiveList[i].executive_name) {
              this.executiveList[i].name = this.executiveList[i].executive_name;
            }
            // if (this.executiveList[i].new_job_title) {
            //   this.executiveList[i].name +=
            //     ", " + this.executiveList[i].new_job_title;
            // }
            if (this.executiveList[i].new_company) {
              this.executiveList[i].name +=
                ", " + this.executiveList[i].new_company.company_name;
            }
          }
          this.executive_ids = [];

          if (type) {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    getcompanylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
          this.company_ids = [];
        })
        .catch(() => {});
    },
    save() {
      if (this.news_mention_company.length > 0) {
        this.detail.news_mention_company = this.news_mention_company;
      }
      if (this.news_mention_executives.length > 0) {
        this.detail.news_mention_executives = this.news_mention_executives;
      }
      if (this.is_openPosition) {
        this.detail.is_openPosition = this.is_openPosition;
      }
      if (this.seniority_id) {
        this.detail.seniority_id = this.seniority_id;
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/news",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/news",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showPublicationPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    addPubliction() {
      this.detail.package_group_type = "web";
      this.$api
        .postAPI({
          _action: "/publications",
          _body: this.detail,
          _buttonId: "publication-button",
        })
        .then((res) => {
          if (!this.$refs.ImagePicker.file) {
            this.publicationlist();
            this.detail.publication_id = res.id;
            this.$refs.textModal.closeModal();
          } else {
            this.uploadImage(res.id);
          }
        })
        .catch(() => {});
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getpublicationlist();
          this.detail.publication_id = res.id;
          this.$refs.textModal.closeModal();
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.toggle-button-cover {
  position: relative;
  width: auto;
  height: auto;
  box-sizing: border-box;
}
.button-cover {
  height: auto;
  border-radius: 4px;
}
.button-cover:before {
  counter-increment: button-counter;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}
.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}
.button.r,
.button.r .layer {
  border-radius: 100px;
}
.button.b2 {
  border-radius: 2px;
}
.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.knobs {
  z-index: 2;
}
.layer {
  width: 100%;
  background-color: #fcebeb;
  transition: 0.3s ease all;
  z-index: 1;
}
#button-1 .knobs:before {
  content: "NO";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #f44336;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}
#button-1 .checkbox:checked + .knobs:before {
  content: "YES";
  left: 42px;
  background-color: #03a9f4;
}
#button-1 .checkbox:checked ~ .layer {
  background-color: #ebf7fc;
}
#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}
</style>