<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/customer')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Customer</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <ul class="nav nav-pills mb-3">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  :class="{ active: currentTab == 'BASIC_INFORMATION' }"
                  v-on:click="changeTab('BASIC_INFORMATION')"
                >
                  <span>Basic Information</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  :class="{ active: currentTab == 'SUBSCRIPTION' }"
                  v-on:click="changeTab('SUBSCRIPTION')"
                >
                  <span>Subscription</span></a
                >
              </li>
            </ul>

            <div class="row" v-show="currentTab == 'BASIC_INFORMATION'">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form ref="adminForm" @submit="save()">
                      <div class="">
                        <div class="col-lg-4 col-sm-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="first_name"
                              class="form-control"
                              placeholder="First Name"
                              v-model="detail.first_name"
                              rules="required:first name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="first_name"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group">
                            <label>Last Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="Last Name"
                              v-model="detail.last_name"
                              rules="required:last name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="last_name"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="detail.email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Contact</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="contact"
                              class="form-control"
                              placeholder="Contact"
                              v-model="detail.contact"
                              rules="required:contact"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="contact" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Company Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="company_name"
                              class="form-control"
                              placeholder="Company Name"
                              v-model="detail.company_name"
                              rules="required:company name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="company_name"
                              class="text-danger"
                            />
                          </div>
                          <div class="form-group">
                            <label for="inputRole1">Is App Accessible</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole1"
                              name="name_brand2"
                              v-model="detail.is_app_accessible"
                            >
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                          </div>
                          <div class="form-group">
                            <label for="inputRole2">Status</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole2"
                              name="name_brand1"
                              v-model="detail.is_active"
                            >
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </Field>
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="save-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="currentTab == 'SUBSCRIPTION'">
              <div class="page-content">
                <div class="card-body py-0 px-0">
                  <div class="card border mb-0 radius-15">
                    <div class="card-body p-0 p-4">
                      <div class="table-responsive">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6 form-group row ml-2">
                            <!-- <a
                              class="search-btn btn-style cursor-pointer ml-3"
                              v-on:click="getList(0)"
                              >Renew</a
                            > -->
                          </div>
                        </div>
                        <div class="dataTables_wrapper dt-bootstrap4">
                          <table
                            class="table table-striped table-bordered dataTable"
                            style="width: 100%"
                            role="grid"
                            aria-describedby="example_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  class="sorting"
                                  id="id-name"
                                  v-on:click="sorting('id', 'id-name')"
                                >
                                  Sr No
                                </th>
                                <th
                                  class="sorting"
                                  id="id-name"
                                  v-on:click="sorting('first_name', 'id-name')"
                                >
                                  Plan Name
                                </th>
                                <th
                                  class="sorting"
                                  id="id-email"
                                  v-on:click="sorting('email', 'id-email')"
                                >
                                  Start Date
                                </th>
                                <th
                                  class="sorting"
                                  id="id-platform"
                                  v-on:click="
                                    sorting('platform', 'id-platform')
                                  "
                                >
                                  End Date
                                </th>
                                <th
                                  class="sorting"
                                  id="id-platform"
                                  v-on:click="
                                    sorting('platform', 'id-platform')
                                  "
                                >
                                  Cost
                                </th>
                                <th
                                  class="sorting"
                                  id="id-updated_on"
                                  v-on:click="
                                    sorting('updated_on', 'id-updated_on')
                                  "
                                >
                                  Updated Date
                                </th>
                                <th
                                  class="sorting"
                                  id="id-isactive"
                                  v-on:click="
                                    sorting('is_active', 'id-isactive')
                                  "
                                >
                                  Status
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody v-show="!loader">
                              <tr v-for="(data, index) in list" :key="index">
                                <td>
                                  {{
                                    ($refs.listPagination.currentPage - 1) *
                                      10 +
                                    index +
                                    1
                                  }}
                                </td>
                                <td>{{ data?.package?.package_name }}</td>

                                <td>
                                  {{
                                    data.created_on
                                      ? $helperService.getFormatedDate(
                                          data.created_on
                                        )
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                    data.created_on
                                      ? $helperService.addDayInDate(
                                          data.created_on,
                                          data.validity
                                        )
                                      : "-"
                                  }}
                                </td>
                                <td>{{ data.cost }}</td>
                                <td>
                                  {{
                                    data.created_on
                                      ? $helperService.getFormatedDate(
                                          data.updated_on
                                        )
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  <a
                                    v-show="data.status == 'C'"
                                    id="status-btn"
                                    class="fill-btn btn-style"
                                    >COMPLETED</a
                                  >
                                  <a
                                    v-show="data.status == 'F'"
                                    id="status-btn"
                                    class="reject-btn btn-style"
                                    >FAIL</a
                                  >
                                  <a
                                    v-show="data.status == 'P'"
                                    id="status-btn"
                                    class="reject-btn btn-style"
                                    >PENDING</a
                                  >
                                </td>
                                <td>
                                  <a
                                    class="delete-btn btn-style cursor-pointer"
                                    v-on:click="
                                      showChangePasswordPopup(data.id)
                                    "
                                    >Update Package</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row mx-0 mb-4">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="listPagination"
                            />
                          </div>
                        </div>
                      </div>
                      <ErrorComponent
                        @retry="getList(0)"
                        ref="errorComponent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Update package</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="changePasswordForm" @submit="updateStatus">
      <div class="col-lg-12 col-sm-12 mt-3 mb-3">
        <label>Package</label>
        <Field
          as="select"
          class="form-control"
          name="package_id"
          v-model="packageObj.package_id"
          rules="required: package"
          :validateOnInput="true"
        >
          <option value="">Select package</option>
          <option
            v-for="(data, index) in packageList"
            :key="index"
            :value="data.id"
          >
            {{ data.package_name }}
          </option>
        </Field>
        <ErrorMessage name="package_id" class="text-danger" />
      </div>
      <div class="modal-footer pb-0">
        <button
          class="fill-btn"
          id="update-status-button"
          style="width: 20%; border-radius: 5px"
        >
          Save
        </button>
        <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal";

export default {
  name: "Edit Customer",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    Pagination,
    TextModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      currentTab: "BASIC_INFORMATION",
      detail: {},
      packageObj: {},
      package_id: "",
      searchObj: {},
      list: [],
      packageList: [],
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
      this.getList(0);
    }
    this.getPackageList();
  },
  methods: {
    showChangePasswordPopup(id) {
      this.packageObj.id = id;
      this.packageObj.package_id = "";
      this.$refs.textModal.showModal();
    },
    updateStatus() {
      this.$api
        .putAPI({
          _action: "/update-customer-package",
          _body: this.packageObj,
          _buttonId: "update-status-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    getPackageList() {
      this.$api
        .getAPI({
          _action: "/package/list",
        })
        .then((res) => {
          this.packageList = res;
        })
        .catch(() => {});
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "BASIC_INFORMATION") {
        this.getDetail();
      } else {
        this.getList();
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/customer/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/customer",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/customer",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.searchObj.id = this.id;
      this.$api
        .getAPI({
          _action: "/customer/subcription/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Customer");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No customer list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
