<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-9 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Publication</h4>
          </div>
          <div class="col-3 d-flex justify-content-end">
            <div class="" v-show="accessObject?.is_add == 1">
              <a
                style="cursor: pointer"
                v-on:click="$router.push('/add-publication')"
                class="add-btn"
                >Add</a
              >
            </div>
            <div class="ml-2">
              <a
                v-on:click="getPublicationExport()"
                style="cursor: pointer"
                class="add-btn"
                >Export</a
              >
            </div>
            <div class="ml-2">
              <a
                v-on:click="showImportPopup()"
                style="cursor: pointer"
                class="add-btn"
                >Import</a
              >
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  >Reset</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            class="sorting"
                            id="id-id"
                            v-on:click="sorting('id', 'id-id')"
                          >
                            Sr No
                          </th>
                          <th>Publication Logo</th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('name', 'id-name')"
                          >
                            Publication Name
                          </th>
                          <th
                            class="sorting"
                            id="id-requested_by_name"
                            v-on:click="
                              sorting(
                                'requested_by.admin_name',
                                'id-requested_by_name'
                              )
                            "
                          >
                            Added By
                          </th>
                          <th
                            class="sorting"
                            id="id-updated_by_name"
                            v-on:click="
                              sorting(
                                'updated_by.admin_name',
                                'id-updated_by_name'
                              )
                            "
                          >
                            Updated By
                          </th>
                          <th>Publish</th>
                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            <img
                              :src="
                                data.publication_logo
                                  ? data.publication_logo
                                  : '/img/upload-profile.PNG'
                              "
                              class="img-fit-cover rounded-5"
                              id="preview"
                              height="40"
                              width="40"
                            />
                          </td>
                          <td>{{ data.name }}</td>
                          <td>{{ data.requested_by_name }}</td>
                          <td>{{ data.updated_by_name }}</td>
                          <td>{{ data.publish == "Y" ? "Yes" : "No" }}</td>
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/edit-publication/' + data.id)
                              "
                              >Edit</a
                            >
                            <a
                              v-if="accessObject?.is_delete == 1"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                            <a
                              v-show="
                                data.publish == 'Y' &&
                                accessObject?.is_publish == 1
                              "
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('N', data.id)"
                              >Unpublish</a
                            >
                            <a
                              v-show="
                                data.publish == 'N' &&
                                accessObject?.is_publish == 1
                              "
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('Y', data.id)"
                              >Published</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
    <TextModal ref="textModal">
      <!-- <Form ref="practiceForm" @submit="uploadDocument()"> -->
      <div class="modal-header">
        <h5 class="modal-title">Import Publication</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-sm-12 mb-3">
          <div class="text-center mb-4">
            <div class="input-group mb-3">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="inputGroupFile04"
                  v-on:change="onChange($event)"
                />
                <label
                  class="custom-file-label"
                  style="text-align: left"
                  for="inputGroupFile04"
                  >{{ docFile ? docFile.name : "Choose File" }}</label
                >
                <!-- <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
                <label class="custom-file-label" for="inputGroupFile04">Choose file</label> -->
              </div>
            </div>
            <p>
              <a href="" v-on:click="downloadTemplate">Download Template </a>
              file and fill with the items information.
            </p>
            <div id="msg"></div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="cancle-button"
          class="btn btn-secondary position-relative w-auto px-4"
          data-dismiss="modal"
          v-on:click="$refs.textModal.closeModal"
        >
          Close
        </button>
        <button
          v-on:click="uploadDocument()"
          id="document-button"
          type="button"
          class="btn btn-primary position-relative w-auto mt-0 mb-0 px-4"
        >
          Import
        </button>
      </div>

      <!-- </Form> -->
    </TextModal>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";

export default {
  name: "Publication",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Publication"),
      loader: true,
      docFile: undefined,
      searchObj: {},
      offset: -1,
      list: [],
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    getPublicationExport() {
      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      } else {
        obj.keyword = "";
      }

      var url =
        this.$api.getBaseUrl() +
        "/publication/export/data?keyword=" +
        obj.keyword;
      window.open(url);
    },
    uploadDocument() {
      if (this.docFile) {
        this.$api
          .uploadImageAPI({
            _action: "/publication/import/data",
            _key: "file",
            _file: this.docFile,
            _buttonId: "document-button",
          })
          .then((res) => {
            // window.open(this.$api.getBaseUrl() + res.url);
            // // window.open("https://apps.grademybeauty.com/api" + res.url)
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getList(0);
            this.$refs.textModal.closeModal();
          });
      } else {
        this.$refs.textModal.closeModal();
      }
    },
    onChange(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "application/pdf" &&
      //   files[0].type.toLowerCase() != "application/doc"
      // ) {
      //   alert("Invalid file formate, please use pdf file.");
      //   return;
      // }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
      console.log(this.docFile);
    },
    showImportPopup() {
      this.docFile = undefined;
      this.$refs.textModal.showModal();
    },
    downloadTemplate() {
      var url = "publication-file.xlsx";
      window.open(this.$api.getBaseUrl().replaceAll("api/admin", "") + url);
    },
    updatepublishstatus(status, id) {
      var obj = {};
      obj.id = id;
      obj.publish = status;
      this.$api
        .putAPI({
          _action: "/publications",
          _body: obj,
        })
        .then(() => {
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/publications/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Publication");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No publications list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
   
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Publication",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/publications/" + detail.id);
    },
  },
};
</script>
