<template>
  <div>
    <div class="sidebar-wrapper" style="overflow-y: scroll">
      <div class="sidebar-header">
        <div class="">
          <img src="/images/logo.png" width="130" />
        </div>
      </div>
      <ul class="metismenu" id="menu">
        <li :class="view.is_system_user_show == true ? 'mm-active' : ''">
          <a
            v-on:click="showDown('is_system_user_show')"
            class="has-arrow"
            style="cursor: pointer"
          >
            <!--<div class="parent-icon"><i class="bx bx-user"></i></div> -->
            <img src="/images/left-icon/team.png" alt="Manage Users" />
            <div>&nbsp;Manage User</div>
          </a>
          <ul
            :class="
              view.is_system_user_show == true
                ? 'mm-collapse mm-show'
                : 'mm-collapse'
            "
            style="
              background: transparent !important;
              margin-left: 35px;
              border: 0px !important;
            "
          >
            <li v-show="$helperService.getLeftMenuAccess('Manage Company')">
              <a
                :class="{ active: pageName.includes('company') }"
                style="cursor: pointer; margin-top: 8px"
                v-on:click="$router.push('/company')"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="menu-title">Manage Company</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Executive')">
              <a
                :class="{ active: pageName.includes('executive') }"
                style="cursor: pointer"
                v-on:click="$router.push('/executive')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Executive</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage News')">
              <a
                :class="{ active: pageName.includes('news') }"
                style="cursor: pointer"
                v-on:click="$router.push('/news')"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="menu-title">Manage News</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Intresting Bites')">
              <a
                :class="{ active: pageName.includes('intresting-bites') }"
                style="cursor: pointer"
                v-on:click="$router.push('/intresting-bites')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Interesting Bites</span>
              </a>
            </li>
            <li
              v-show="$helperService.getLeftMenuAccess('Deals & Transaction')"
            >
              <a
                :class="{ active: pageName.includes('deals-transaction') }"
                style="cursor: pointer"
                v-on:click="$router.push('/deals-transaction')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title"> Deals & Transaction</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Commentry')">
              <a
                :class="{ active: pageName.includes('commentry') }"
                style="cursor: pointer"
                v-on:click="$router.push('/commentry')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Commentary </span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Deal Stages')">
              <a
                :class="{ active: pageName.includes('funding_series') }"
                style="cursor: pointer"
                v-on:click="$router.push('/funding_series')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Deal Stages </span>
              </a>
            </li>
            <li
              v-show="$helperService.getLeftMenuAccess('Manage Open Position')"
            >
              <a
                :class="{ active: pageName.includes('open-position') }"
                style="cursor: pointer"
                v-on:click="$router.push('/open-position')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Open Position </span>
              </a>
            </li>
          </ul>
        </li>
        <li :class="view.is_system_admin_show == true ? 'mm-active' : ''">
          <a
            v-on:click="showDown('is_system_admin_show')"
            class="has-arrow"
            style="cursor: pointer"
          >
            <!--<div class="parent-icon"><i class="bx bx-user"></i></div> -->
            <img src="/images/left-icon/team.png" alt="Manage Users" />
            <div>&nbsp;Manage Admin</div>
          </a>
          <ul
            :class="
              view.is_system_admin_show == true
                ? 'mm-collapse mm-show'
                : 'mm-collapse'
            "
            style="
              background: transparent !important;
              margin-left: 35px;
              border: 0px !important;
            "
          >
            <li v-show="$helperService.getLeftMenuAccess('Manage Customer')">
              <a
                :class="{ active: pageName.includes('customer') }"
                style="cursor: pointer; margin-top: 8px"
                v-on:click="$router.push('/customer')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage Customer</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Admins')">
              <a
                :class="{ active: pageName.includes('admin') }"
                style="cursor: pointer"
                v-on:click="$router.push('/admin')"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="menu-title">Manage Admins</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Demo')">
              <a
                :class="{ active: pageName.includes('demo') }"
                style="cursor: pointer"
                v-on:click="$router.push('/demo')"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="menu-title">Request Demo</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Settings')">
              <a
                id="notification"
                :class="{ active: pageName.includes('settings') }"
                style="cursor: pointer"
                v-on:click="$router.push('/settings')"
              >
                <i class="fa fa-bell" aria-hidden="true"></i>
                <span class="menu-title">Manage Settings</span>
              </a>
            </li>
            <!-- <li
              v-show="$helperService.getLeftMenuAccess('Manage Package Group')"
            >
              <a
                :class="{ active: pageName.includes('package-group') }"
                style="cursor: pointer"
                v-on:click="$router.push('/package-group')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Package Group</span>
              </a>
            </li> -->
            <li
              v-show="
                $helperService.getLeftMenuAccess('Manage Custom Search Web')
              "
            >
              <a
                :class="{ active: pageName.includes('custom-search-web') }"
                style="cursor: pointer"
                v-on:click="$router.push('/custom-search-web')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Custom Search Web</span>
              </a>
            </li>
          </ul>
        </li>
        <li :class="view.is_system_qa_show == true ? 'mm-active' : ''">
          <a
            v-on:click="showDown('is_system_qa_show')"
            class="has-arrow"
            style="cursor: pointer"
          >
            <!--<div class="parent-icon"><i class="bx bx-user"></i></div> -->
            <img src="/images/left-icon/team.png" alt="Manage Users" />
            <div>&nbsp;Manage QA</div>
          </a>
          <ul
            :class="
              view.is_system_qa_show == true
                ? 'mm-collapse mm-show'
                : 'mm-collapse'
            "
            style="
              background: transparent !important;
              margin-left: 35px;
              border: 0px !important;
            "
          >
            <li v-show="$helperService.getLeftMenuAccess('Manage Job Title')">
              <a
                :class="{ active: pageName.includes('job-title') }"
                style="cursor: pointer; margin-top: 8px"
                v-on:click="$router.push('/job-title')"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="menu-title">Manage Job Title</span>
              </a>
            </li>
            <!-- <li>
          <a
            
            :class="{ active: pageName.includes('') == 'positions' }"
            style="cursor: pointer"
            v-on:click="$router.push('/positions')"
          >
            <i class="fa fa-sitemap" aria-hidden="true"></i>
            <span class="menu-title">Open Positions </span>
          </a>
        </li> -->
            <li
              v-show="
                $helperService.getLeftMenuAccess('Manage Custom Search App')
              "
            >
              <a
                :class="{ active: pageName.includes('custom-search-app') }"
                style="cursor: pointer"
                v-on:click="$router.push('/custom-search-app')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Custom Search App</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Industry')">
              <a
                :class="{ active: pageName.includes('industry') }"
                style="cursor: pointer"
                v-on:click="$router.push('/industry')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage Industry</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Publication')">
              <a
                :class="{ active: pageName.includes('publication') }"
                style="cursor: pointer"
                v-on:click="$router.push('/publication')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage Publication</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage University')">
              <a
                :class="{ active: pageName.includes('university') }"
                style="cursor: pointer"
                v-on:click="$router.push('/university')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage University</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage QA')">
              <a
                :class="{ active: pageName.includes('QA') }"
                style="cursor: pointer"
                v-on:click="$router.push('/qa')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage QA</span>
              </a>
            </li>

            <li v-show="$helperService.getLeftMenuAccess('Manage Package')">
              <a
                :class="{ active: pageName.includes('package') }"
                style="cursor: pointer"
                v-on:click="$router.push('/package')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Package </span>
              </a>
            </li>
            <!-- <li v-show="$helperService.getLeftMenuAccess('Manage rss')">
          <a
            
            :class="{ active: pageName.includes('rss') }"
            style="cursor: pointer"
            v-on:click="$router.push('/rss')"
          >
            <i class="fa fa-users" aria-hidden="true"></i>
            <span class="menu-title">Manage Rss</span>
          </a>
        </li> -->

            <li v-show="$helperService.getLeftMenuAccess('Manage Cluster')">
              <a
                :class="{ active: pageName.includes('cluster') }"
                style="cursor: pointer"
                v-on:click="$router.push('/cluster')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage Listing</span>
              </a>
            </li>

            <li v-show="$helperService.getLeftMenuAccess('Manage Function')">
              <a
                :class="{ active: pageName.includes('function') }"
                style="cursor: pointer"
                v-on:click="$router.push('/function')"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="menu-title">Manage Function</span>
              </a>
            </li>
            <!-- <li v-show="$helperService.getLeftMenuAccess('Manage Cities')">
          <a
            
            :class="{ active: pageName.includes('cities') }"
            style="cursor: pointer"
            v-on:click="$router.push('/cities')"
          >
            <i class="fa fa-sitemap" aria-hidden="true"></i>
            <span class="menu-title">Manage Cities </span>
          </a>
        </li> -->
            <li v-show="$helperService.getLeftMenuAccess('Manage Countries')">
              <a
                :class="{ active: pageName.includes('countries') }"
                style="cursor: pointer"
                v-on:click="$router.push('/countries')"
              >
                <i class="fa fa-sitemap" aria-hidden="true"></i>
                <span class="menu-title">Manage Countries</span>
              </a>
            </li>
            <li v-show="$helperService.getLeftMenuAccess('Manage Topic')">
              <a
                :class="{ active: pageName.includes('topic') }"
                style="cursor: pointer"
                v-on:click="$router.push('/topic')"
              >
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                <span class="menu-title">Manage Topic</span>
              </a>
            </li>

            <li v-show="$helperService.getLeftMenuAccess('Manage Logs')">
              <a
                :class="{ active: pageName.includes('logs') }"
                style="cursor: pointer"
                v-on:click="$router.push('/logs')"
              >
                <i class="fa fa-align-center" aria-hidden="true"></i>
                <span class="menu-title">Manage Logs</span>
              </a>
            </li>

            <li v-show="$helperService.getLeftMenuAccess('Manage Region')">
              <a
                :class="{ active: pageName.includes('region') }"
                style="cursor: pointer"
                v-on:click="$router.push('/region')"
              >
                <i class="fa fa-align-center" aria-hidden="true"></i>
                <span class="menu-title">Manage Region</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <header class="top-header">
      <nav class="navbar navbar-expand">
        <div class="left-topbar d-flex align-items-center">
          <a href="javascript:;" class="toggle-btn">
            <i class="bx bx-menu"></i>
          </a>
        </div>
        <div class="right-topbar ml-auto">
          <ul class="navbar-nav">
            <li class="nav-item dropdown dropdown-user-profile">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret pr-0"
                href="javascript:;"
                data-toggle="dropdown"
              >
                <div class="media user-box align-items-center">
                  <div class="media-body user-info">
                    <p class="user-name mb-0" id="loginuser-name">
                      {{ details.admin_email }}
                    </p>
                  </div>
                  <img
                    :src="details.image ? details.image : '/img/user-pic.png'"
                    class="user-img"
                    id="loginuser-image"
                  />
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link to="/my-profile">
                  <a class="dropdown-item">
                    <i class="bx bx-user"></i>
                    <span>Profile</span>
                  </a></router-link
                >
                <div class="dropdown-divider mb-0"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  v-on:click="logout"
                >
                  <i class="bx bx-power-off"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
// import api from "../services/WebService";
// import router from "../router";
export default {
  name: "Menu",
  data() {
    return {
      pageName: "",
      details: {},
      view: {
        is_system_user_show: true,
        is_system_admin_show: false,
        is_system_qa_show: false,
      },
      access: [
        {
          name: "Manage Admins",
          url: "admin",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Roles",
          url: "role",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Company",
          url: "company",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Executive",
          url: "executive",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Executive Adv.",
          url: "executives",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage News",
          url: "news",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Job Title",
          url: "news",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Custom Search App",
          url: "custom-search-app",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Industry",
          url: "industry",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Publication",
          url: "publication",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage University",
          url: "university",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Intresting Bites",
          url: "intresting-bites",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Deals & Transaction",
          url: "deals-transaction",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Package",
          url: "package",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },

        // {
        //   name: "Manage rss",
        //   url: "rss",
        //   is_all: 0,
        //   is_view: 0,
        //   is_add: 0,
        //   is_edit: 0,
        //   is_delete: 0,
        //   is_publish: 0,
        // },
        {
          name: "Manage Customer",
          url: "customer",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Cluster",
          url: "cluster",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Commentry",
          url: "commentry",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Function",
          url: "function",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        // {
        //   name: "Manage Cities",
        //   url: "cities",
        //   is_all: 0,
        //   is_view: 0,
        //   is_add: 0,
        //   is_edit: 0,
        //   is_delete: 0,
        //   is_publish: 0,
        // },
        {
          name: "Manage Countries",
          url: "countries",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Topic",
          url: "topic",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Package Group",
          url: "package-group",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Custom Search Web",
          url: "custom-search-web",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Logs",
          url: "logs",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Settings",
          url: "settings",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Region",
          url: "region",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage QA",
          url: "qa",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Demo",
          url: "demo",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Open Position",
          url: "open-position",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
        {
          name: "Manage Deal Stages",
          url: "funding_series",
          is_all: 0,
          is_view: 0,
          is_add: 0,
          is_edit: 0,
          is_delete: 0,
          is_publish: 0,
        },
      ],
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    this.getLoginUserDetail();
  },
  methods: {
    showDown(type) {
      if (type == "is_system_user_show") {
        this.view = {
          is_system_user_show: !this.view.is_system_user_show,
          is_system_admin_show: false,
          is_system_qa_show: false,
        };
      } else if (type == "is_system_admin_show") {
        this.view = {
          is_system_admin_show: !this.view.is_system_admin_show,
          is_system_user_show: false,
          is_system_qa_show: false,
        };
      } else if (type == "is_system_qa_show") {
        this.view = {
          is_system_qa_show: !this.view.is_system_qa_show,
          is_system_admin_show: false,
          is_system_user_show: false,
        };
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/users/me",
        })
        .then((res) => {
          this.details = res;
          if (res.access.length > 0) {
            localStorage.setItem("role", res.admin_type);
            for (var i in res.access) {
              for (var j in this.access) {
                if (res.admin_type == "owner") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 1;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 1;
                    this.access[j].is_publish = 1;
                  }
                }
                if (res.admin_type == "admin") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 1;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 1;
                    this.access[j].is_publish = 1;
                  }
                }
                if (res.admin_type == "quality_analyst") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 0;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 1;
                    this.access[j].is_publish = 1;
                  }
                }
                if (res.admin_type == "data_entry_analyst") {
                  if (this.access[j].name == res.access[i].name) {
                    this.access[j].is_all = 1;
                    this.access[j].is_view = 1;
                    this.access[j].is_add = 1;
                    this.access[j].is_edit = 1;
                    this.access[j].is_delete = 0;
                    this.access[j].is_publish = 1;
                  }
                }
              }
            }
          }
          if (this.access && this.access.length > 0) {
            this.$helperService.setAccessLevel(this.access);
          }
          // if (this.access.length > 0) {
          //   for (var k in this.access) {
          //     if (this.access[k].is_view) {
          //       router.push("/" + this.access[k].url);
          //       return;
          //     }
          //   }
          // }
        })
        .catch(() => {});
    },
  },
};
const accSingleTriggers = document.querySelectorAll(".js-acc-single-trigger");
accSingleTriggers.forEach((trigger) =>
  trigger.addEventListener("click", toggleAccordion)
);

function toggleAccordion() {
  const items = document.querySelectorAll(".js-acc-item");
  const thisItem = this.parentNode;
  items.forEach((item) => {
    if (thisItem == item) {
      thisItem.classList.toggle("is-open");
      return;
    }
    item.classList.remove("is-open");
  });
}
</script>
