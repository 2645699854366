<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Open Position</h4>
          </div>
          <div class="col-6 text-right">
            <a
              style="padding-left: 20px; padding-right: 20px;cursor: pointer;"
              v-on:click="$router.push('/add-positions')"
              class="add-btn"
              >Add</a
            >
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  >Reset</a
                >
              </div>
              
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Company Name</th>
                          <th>Job Title</th>
                          <th>Seniority Name</th>
                          <th>Job Function</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            {{ data.company ? data.company.company_name : "" }}
                          </td>

                          <td>
                            {{ data.job_title }}
                          </td>
                          <td>
                            {{
                              data.seneority
                                ? data.seneority.seniority_name
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              data.jon_function
                                ? data.jon_function.job_function_name
                                : ""
                            }}
                          </td>
                          <td>
                            <a
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/edit-positions/' + data.id)
                              "
                              >Edit</a
                            >
                            <a
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                            <a
                              v-show="data.publish == 'Y'"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('N', data.id)"
                              >Publish</a
                            >
                            <a
                              v-show="data.publish == 'N'"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('Y', data.id)"
                              >Unpublish</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";

export default {
  name: "Package Group",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      loader: true,
      searchObj: {},
      offset: -1,
      list: [],
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    updatepublishstatus(status, id) {
      var obj = {};
      obj.id = id;
      obj.publish = status;
      this.$api
        .putAPI({
          _action: "/open/postion",
          _body: obj,
        })
        .then(() => {
          this.getList(0)
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/open/position/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No commentary list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Position",
        "Are you sure you want to delete",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/open/postion/" + detail.id);
    },
  },
};
</script>