<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage News</h4>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <div class="" v-show="accessObject?.is_add == 1">
              <a
                style="padding-left: 20px; padding-right: 20px; cursor: pointer"
                v-on:click="$router.push('/add-news')"
                class="add-btn"
                >Add</a
              >
            </div>
            <div class="ml-2" v-if="role == 'admin'">
              <a
                v-on:click="getNewsExport()"
                style="cursor: pointer"
                class="add-btn"
                >Export</a
              >
            </div>
            <!-- <div class="ml-2">
              <a
                v-on:click="showImportPopup()"
                style="cursor: pointer"
                class="add-btn"
                >Import</a
              > -->
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <!-- <form class="form-search form-inline"> -->
                  <Field
                    name="keyword"
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                    style="border: 0px none; width: 100%"
                  />
                  <!-- </form> -->
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">News Type</label>
                <Multiselect
                  mode="single"
                  trackBy="lable"
                  label="value"
                  valueProp="lable"
                  v-model="searchObj.type"
                  :options="types"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.company_id"
                  :options="companyList"
                  searchable="true"
                  v-on:change="geteComponydetail($event)"
                  @search-change="searchCompanyName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">From Date</label>
                <datepicker
                  v-model="searchObj.from_date"
                  inputFormat="dd/MM/yyyy"
                  placeholder="From Date"
                  class="form-control"
                  style="background-color: white !important; height: 35px"
                  :clearable="false"
                  :upperLimit="
                    searchObj.to_date ? searchObj.to_date : new Date()
                  "
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important; height: 35px"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                  :upperLimit="new Date()"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label></label>
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
                <div class="text-center">
                  <a
                    style="cursor: pointer"
                    v-on:click="getAdvanceSearch()"
                    class="advance-search"
                    >{{ is_show ? "Hide Advance Search" : "Advance Search" }}</a
                  >
                </div>
                <!--  <div class="text-center"><a href="#" class="advance-search">Advance Search</a></div> -->
              </div>
            </div>
            <div v-show="is_show" class="row advasearch-data mt-1">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Job Function</label>
                <Multiselect
                  mode="single"
                  trackBy="job_function_name"
                  label="job_function_name"
                  valueProp="id"
                  v-model="searchObj.job_function_id_new"
                  :options="jobFunctionList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchJobFunctionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Location</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  v-model="searchObj.country_id"
                  :options="cityList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchCityName($event)"
                />
              </div>

              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Region</label>
                <Multiselect
                  mode="single"
                  trackBy="region_name"
                  label="region_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.region_id_new"
                  :options="regionList"
                  searchable="true"
                  @search-change="searchRegionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_type"
                  :options="company_types"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Past Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.prev_company_id"
                  :options="companyList"
                  searchable="true"
                  @search-change="searchCompanyName($event)"
                />
              </div>
            </div>
            <hr />
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th
                            class="sorting"
                            style="min-width: 250px"
                            id="id-title"
                            v-on:click="sorting('news_title', 'id-title')"
                          >
                            News title
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-company_name"
                            v-on:click="
                              sorting(
                                'tbl_company.company_name',
                                'id-company_name'
                              )
                            "
                          >
                            Company Name
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-executive_name"
                            v-on:click="
                              sorting(
                                'tbl_executive.executive_name',
                                'id-executive_name'
                              )
                            "
                          >
                            Executive Name
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 180px"
                            id="id-created_on"
                            v-on:click="
                              sorting(
                                'tbl_company_news.created_on',
                                'id-created_on'
                              )
                            "
                          >
                            Published Date
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 250px"
                            id="id-news_link"
                            v-on:click="sorting('news_link', 'id-news_link')"
                          >
                            Url
                          </th>
                          <th
                            class="sorting"
                            style="min-width: 100px"
                            id="id-type"
                            v-on:click="sorting('type', 'id-type')"
                          >
                            Type
                          </th>
                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            {{ $helperService.getText(data.news_title, 50) }}
                          </td>
                          <td>
                            {{ data.company ? data.company.company_name : "-" }}
                          </td>
                          <td>
                            {{
                              data.executive
                                ? data.executive.executive_name
                                : "-"
                            }}
                          </td>
                          <td>
                            {{ data.news_date }}
                          </td>
                          <td>
                            {{ $helperService.getText(data.news_link, 50) }}
                          </td>
                          <td>
                            {{ data.type }}
                          </td>
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="goToView('/edit-news/' + data.id)"
                              >Edit</a
                            >
                            <a
                              v-if="accessObject?.is_delete == 1"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                            <a
                              v-show="
                                data.publish == 'Y' &&
                                accessObject?.is_publish == 1
                              "
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('N', data.id)"
                              >Publish</a
                            >
                            <a
                              v-show="
                                data.publish == 'N' &&
                                accessObject?.is_publish == 1
                              "
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('Y', data.id)"
                              >Unpublish</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import { Field } from "vee-validate";

export default {
  name: "Package Group",
  components: {
    ErrorComponent,
    DeleteModal,
    datepicker,
    Multiselect,
    Pagination,
    Field,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage News"),
      loader: true,
      role: localStorage.getItem("role"),
      offset: -1,
      list: [],
      is_show: false,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      types: [
        {
          value: "Arrival / Departure",
          lable: "arrival_departure",
        },
        {
          value: "Internal Move",
          lable: "internal_move",
        },
        {
          value: "Lay Off",
          lable: "lay_off",
        },
        {
          value: "Own Venture",
          lable: "own_venture",
        },
        {
          value: "Terminated",
          lable: "terminated",
        },
      ],
      searchObj: {
        type: [],
        from_date: "",
        to_date: "",
        country_id: "",
        company_type: "",
        job_function_id_new: "",
        prev_company_id: "",
        region_id_new: "",
        industry_id: [],
        company_id: [],
      },
      industryList: [],
      companyList: [],
      company_ids: [],
      cityList: [],
      regionList: [],
      jobFunctionList: [],
      cityKeyword: "",
      companyKeyword: "",
      industryKeyword: "",
      newsTypeKeyword: "",
      jobFunctionKeyword: "",
      regionKeyword: "",
    };
  },
  mounted() {
    this.getList(0);
    this.companylist();
    this.CityList();
    this.jobFunctionlist();
    this.getRegionlist();
    this.getindustrylist();
  },
  methods: {
    getAdvanceSearch() {
      this.is_show = !this.is_show;
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchRegionName(e) {
      this.regionKeyword = e;
      this.getRegionlist();
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    searchNewsType(e) {
      this.newsTypeKeyword = e;
      this.industrylist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
        })
        .catch(() => {});
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },

    getindustrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getRegionlist() {
      var obj = {};
      if (this.regionKeyword) {
        obj.keyword = this.regionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/region/list",
          _body: obj,
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.company_id = "";
      this.searchObj.country_id = "";
      this.searchObj.company_type = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.region_id_new = "";
      this.getList(0);
    },
    updatepublishstatus(status, id) {
      var obj = {};
      obj.id = id;
      obj.publish = status;
      this.$api
        .putAPI({
          _action: "/news",
          _body: obj,
        })
        .then(() => {
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    goToView(url) {
      this.searchObj.is_show = this.is_show;
      this.$shareService.setMapValue("news_page_filter", this.searchObj);
      this.$router.push(url);
    },
    getNewsExport() {
      var obj = {};
      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      } else {
        obj.from_date = "";
      }

      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      } else {
        obj.to_date = "";
      }

      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      } else {
        obj.keyword = "";
      }

      if (this.searchObj.country_id) {
        obj.country_id = this.searchObj.country_id;
      } else {
        obj.country_id = "";
      }
      if (this.searchObj.company_type) {
        obj.company_type = this.searchObj.company_type;
      } else {
        obj.company_type = "";
      }

      if (this.searchObj.job_function_id_new) {
        obj.job_function_id_new = this.searchObj.job_function_id_new;
      } else {
        obj.job_function_id_new = "";
      }
      if (this.searchObj.prev_company_id) {
        obj.prev_company_id = this.searchObj.prev_company_id;
      } else {
        obj.prev_company_id = "";
      }

      if (this.searchObj.type) {
        obj.type = this.searchObj.type;
      } else {
        obj.type = "";
      }

      if (this.searchObj.region_id_new) {
        obj.region_id_new = this.searchObj.region_id_new;
      } else {
        obj.region_id_new = "";
      }
      if (this.searchObj.industry_id) {
        obj.industry_id = this.searchObj.industry_id;
      } else {
        obj.industry_id = "";
      }
      if (this.searchObj.company_id) {
        obj.company_id = this.searchObj.company_id;
      } else {
        obj.company_id = "";
      }

      if (this.searchObj.limit) {
        obj.limit = this.searchObj.limit;
      } else {
        obj.limit = "";
      }
      // var url = this.$api.getBaseUrl() + "/company/export/data";

      var url =
        this.$api.getBaseUrl() +
        "/news/export/data?keyword=" +
        obj.keyword +
        "&start_date=" +
        obj.from_date +
        "&end_date=" +
        obj.to_date +
        "&country_id=" +
        obj.country_id +
        "&company_type=" +
        obj.company_type +
        "&job_function_id_new=" +
        obj.job_function_id_new +
        "&prev_company_id=" +
        obj.prev_company_id +
        "&region_id_new=" +
        obj.region_id_new +
        "&industry_id=" +
        obj.industry_id +
        "&company_id=" +
        obj.company_id +
        "&limit=" +
        obj.limit +
        "&type=" +
        obj.type;
      // alert(url);
      // console.log("Fd",url)
      window.open(url);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (this.searchObj.from_date) {
        this.searchObj.start_date = moment(this.searchObj.from_date).format(
          "DD-MM-YYYY"
        );
      }
      if (this.searchObj.to_date) {
        this.searchObj.end_date = moment(this.searchObj.to_date).format(
          "DD-MM-YYYY"
        );
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
      if (!this.searchObj.region_id_new) {
        this.searchObj.region_id_new = "";
      }
      if (!this.searchObj.country_id) {
        this.searchObj.country_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (!this.searchObj.keyword) {
        this.searchObj.keyword = "";
      }
      if (!this.searchObj.industry_id) {
        this.searchObj.industry_id = "";
      }
      if (!this.searchObj.company_id) {
        this.searchObj.company_id = "";
      }
      if (this.offset != page) {
        this.searchObj.offset = page;
      } else {
        this.searchObj.offset = page;
      }
      var filter_obj = this.$shareService.getMapValue("news_page_filter")
        ? this.$shareService.getMapValue("news_page_filter")
        : null;
      if (filter_obj) {
        this.searchObj = filter_obj;
        this.is_show = this.searchObj.is_show;
        this.$shareService.getMapValueAndRemove("news_page_filter");
        if (this.searchObj.company_id) {
          this.company_ids.push(this.searchObj.company_id);
          this.companylist();
        }
      }
      this.$api
        .getAPI({
          _action: "/news/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject = this.$helperService.getAccessLevel("Manage News");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(
                  this.searchObj.offset + 1
                );
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No news list available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },

    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete News",
        "Are you sure you want to delete",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/news/" + detail.id);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
