<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">Import RSS</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <div class="col-md-12">
                      <div class="form-group col-6">
                        <label>Upload File(.csv)</label>
                        <div class="input-group mb-3">
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="CSV"
                              v-on:change="onChange($event)"
                            />
                            <label
                              class="custom-file-label"
                              for="CSV"
                              aria-describedby="inputGroupFileAddon02"
                              >{{ file ? file.name : "Choose File" }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 text-center mt-2">
                      <button
                        v-on:click="uploadDocument"
                        id="import-button"
                        class="save-btn px-4 ml-3"
                      >
                        Import
                      </button>
                      <button
                        type="button"
                        v-on:click="$router.go(-1)"
                        class="fil-btn px-4 ml-3"
                        id="cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "Add Package Group",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onChange(e) {
      let files = e.target.files;
      if (files[0].type.toLowerCase() != "application/vnd.ms-excel") {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("CSV").src = tmppath;
    },
    uploadDocument() {
      if (!this.file) {
        this.$notify({
          type: "error",
          text: "Please add csv file first!",
        });
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/import/rss",
          _key: "file",
          _file: this.file,
          _buttonId: "import-button",
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
};
</script>