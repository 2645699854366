<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/intresting-bites')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "View" : "Add" }} Interesting Bites
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="col-12 row">
                        <!-- <div class="col-lg-4 col-sm-6"> -->
                        <div class="col-8 form-group">
                          <label>News URL </label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="Link"
                            class="form-control"
                            placeholder="Link"
                            :disabled="id"
                            v-model="detail.news_url"
                            rules="required: link|website"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Link" class="text-danger" />
                        </div>
                        <div class="col-4" style="margin-top: 39px">
                          <a
                            type="button"
                            class="fill-btn"
                            v-on:click="getTitleFromUrl(detail.news_url)"
                          >
                            Get Title
                          </a>
                        </div>
                        <div class="col-6 form-group">
                          <label>Headline</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="Headline"
                            class="form-control"
                            placeholder="Headline"
                            v-model="detail.headline"
                            style="background-color: white !important"
                            :disabled="id"
                            rules="required:headline"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="Headline" class="text-danger" />
                        </div>
                        <div
                          class="col-6 form-group"
                          style="background-color: white !important"
                        >
                          <label> Published Date </label>

                          <Field
                            type="text"
                            style="background-color: white !important"
                            name="Published"
                            class="form-control"
                            placeholder="Published Date"
                            :disabled="id"
                            v-model="detail.published_date"
                            rules="required:published date"
                            :validateOnInput="true"
                          >
                            <Datepicker
                              style="background-color: white !important"
                              autoApply
                              :disabled="id"
                              v-model="detail.published_date"
                              inputFormat="dd-MM-yyyy"
                              format="dd-MM-yyyy"
                            >
                            </Datepicker>
                          </Field>
                          <ErrorMessage name="Published" class="text-danger" />
                        </div>

                        <div class="col-6 form-group">
                          <label for="inputRole">Company Name</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="company_name"
                            placeholder="Select Previous Company"
                            :disabled="id"
                            rules="required: company name"
                            v-model="detail.company_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="company_name"
                              label="company_name"
                              valueProp="id"
                              placeholder="Select Company"
                              :disabled="id"
                              v-model="detail.company_id"
                              :options="companyList"
                              searchable="true"
                              v-bind="field"
                              @search-change="searchCompanyName($event)"
                            >
                              <template v-slot:option="{ option }">
                                <img
                                  class="option-icon"
                                  :src="
                                    option.company_logo
                                      ? option.company_logo
                                      : '/img/user-pic.png'
                                  "
                                />
                                {{ option.company_name }}
                              </template>
                            </Multiselect>
                          </Field>
                          <ErrorMessage
                            name="company_name"
                            class="text-danger"
                          />
                        </div>

                        <!-- <div class="col-6 form-group">
                          <label>Executive Name </label>
                          <span class="text-danger">*</span>

                          <Field
                            type="text"
                            name="executive_name"
                            placeholder="Select Executive Name"
                            rules="required: executive name"
                            v-model="detail.executive_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="executive_name"
                              label="executive_name"
                              valueProp="id"
                              placeholder="Select Executive"
                              v-model="detail.executive_id"
                              :options="executiveList"
                              searchable="true"
                              @search-change="searchexecutiveName($event)"
                              v-bind="field"
                          >
                            <template v-slot:option="{ option }">
                              <img
                                class="option-icon"
                                :src="
                                  option.executive_logo
                                    ? option.executive_logo
                                    : '/img/user-pic.png'
                                "
                              />
                              {{ option.executive_name }}
                            </template>
                          </Multiselect>
                          </Field>
                          <ErrorMessage
                            name="executive_name"
                            class="text-danger"
                          />
                        </div> -->
                        <div class="col-6 form-group row">
                          <div class="col-9">
                            <label>Publication Name </label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="publication_id"
                              placeholder="Select Publication"
                              style="background-color: white !important"
                              :disabled="id"
                              rules="required: publication"
                              v-model="detail.publication_id"
                              :validateOnInput="true"
                              v-slot="{ field }"
                            >
                              <Multiselect
                                mode="single"
                                trackBy="name"
                                label="name"
                                :disabled="id"
                                valueProp="id"
                                placeholder="Select Publication"
                                v-model="detail.publication_id"
                                :options="publicationList"
                                searchable="true"
                                @search-change="searchpublicationName($event)"
                                v-bind="field"
                              />
                            </Field>
                            <ErrorMessage
                              name="publication_id"
                              class="text-danger"
                            />
                          </div>
                          <div class="col-3" style="margin-top: 39px">
                            <a
                              type="button"
                              class="fill-btn"
                              v-on:click="id ? '' : showPublicationPopup()"
                            >
                              Add New
                            </a>
                          </div>
                        </div>
                        <div class="col-6">
                          <span> Add Company Logo</span>
                          <span class="text-danger">*</span>
                          <div>
                            <input
                              type="checkbox"
                              id="is_company_logo"
                              name="is_company_logo"
                              style="
                                height: 52px;
                                width: 27px;
                                background-color: white !important;
                              "
                              :disabled="id"
                              true-value="1"
                              false-value="0"
                              v-model="detail.is_company_logo"
                            />
                          </div>
                        </div>
                        <div class="col-6 form-group">
                          <label>Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            name="topic_id"
                            placeholder="Select Topic"
                            :disabled="type == 'VIEW'"
                            rules="required: type"
                            v-model="detail.topic_id"
                            :validateOnInput="true"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              mode="single"
                              trackBy="topic_name"
                              label="topic_name"
                              valueProp="id"
                              placeholder="Select Topic"
                              :disabled="type == 'VIEW'"
                              v-model="detail.topic_id"
                              :options="commentType"
                              v-bind="field"
                              searchable="true"
                            />
                          </Field>
                          <ErrorMessage name="topic_id" class="text-danger" />
                        </div>
                        <!-- </div> -->
                        <div class="col-12 form-group">
                          <label>Bites</label>
                          <!-- <span class="text-danger">*</span> -->
                          <textarea
                            style="background-color: white !important"
                            type="text"
                            name="bites"
                            class="form-control"
                            placeholder="bites"
                            :disabled="id"
                            v-model="detail.bites"
                            rules="required:bites"
                            :validateOnInput="true"
                          >
                          </textarea>
                          <ErrorMessage name="bites" class="text-danger" />
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button
                            v-if="!id"
                            id="save-button"
                            class="save-btn px-4 ml-3"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
  </div>
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">Add Publication</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form itemref="addPubliction" @submit="addPubliction()">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label>Publication Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="user_name"
            class="form-control"
            placeholder="Publication Name"
            v-model="detail.name"
            rules="required: publication name"
            :validateOnInput="true"
          />
          <ErrorMessage name="user_name" class="text-danger" />
        </div>
        <div class="col-6 form-group">
          <label>Website Url</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="website_url"
            class="form-control"
            placeholder="Website Url"
            v-model="detail.website_url"
            rules="required: website url |website"
            :validateOnInput="true"
          />
          <ErrorMessage name="website_url" class="text-danger" />
        </div>
        <div class="col-6 mt-3 mb-3">
          <label>Publication Logo</label>
          <div class="text-left">
            <ImagePicker
                :src="detail.publication_logo"
                :modelValue="detail.publication_logo"
                ref="publication_logo"
              />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 text-center mt-2">
          <button id="publication-button" class="save-btn px-4 ml-3">Save</button>
          <button
            type="button"
            class="fil-btn px-4 ml-3"
            v-on:click="$router.go(-1)"
            id="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import ImagePicker from "@/components/ImagePicker.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import TextModal from "@/components/TextModal";
import moment from "moment";
export default {
  name: "Add Package Group",
  components: {
    Form,
    Field,
    Datepicker,
    ErrorMessage,
    Multiselect,
    ErrorComponent,
    ImagePicker,
    TextModal,
  },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      detail: {
        is_company_logo: 0,
      },
      company_ids: [],
      companyList: [],
      topicList: [],
      executiveList: [],
      publicationList: [],
      commentType: [],
      commentTypeKeyword: "",
      companyKeyword: "",
      executiveKeyword: "",
      publicationKeyword: "",
    };
  },
  mounted() {
    this.companylist();
    this.executivelist();
    this.publicationlist();
    this.topiclist();
    this.getCommentlist();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getTitleFromUrl(url) {
      if (url) {
        this.$refs.errorComponent.updateFormLoader(true);
        var obj = {};
        obj.url = url;
        this.$api
          .getAPI({
            _action: "/get/title/url",
            _body: obj,
          })
          .then((res) => {
            this.detail.headline = res.title;
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateListLoader(false);
            }
          });
      } else {
        this.$notify({
          type: "error",
          text: "Please Enter News Url First.",
        });
      }
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchexecutiveName(e) {
      this.executiveKeyword = e;
      this.executivelist();
    },
    searchpublicationName(e) {
      this.publicationKeyword = e;
      this.publicationlist();
    },
    searchCommrntTypeName(e) {
      this.commentTypeKeyword = e;
      this.getCommentlist();
    },
    getCommentlist() {
      this.$api
        .getAPI({
          _action: "/comment/type/list",
        })
        .then((res) => {
          this.commentType = res;
        })
        .catch(() => {});
    },
    publicationlist() {
      var obj = {};
      if (this.publicationKeyword) {
        obj.keyword = this.publicationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/publications/list",
          _body: obj,
        })
        .then((res) => {
          this.publicationList = res;
        })
        .catch(() => {});
    },
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/intresting/bites/detail/" + this.id,
        })
        .then((res) => {
          this.company_ids = [];
          this.detail = res;
          if (this.detail.company_id) {
            this.company_ids.push(this.detail.company_id);
          }
          this.companylist();
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    topiclist() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/topic/list",
        })
        .then((res) => {
          this.topicList = res;
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res);
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    executivelist() {
      var obj = {};
      if (this.executiveKeyword) {
        obj.keyword = this.executiveKeyword;
      }
      this.$api
        .getAPI({
          _action: "/executive/list",
          _body: obj,
        })
        .then((res) => {
          this.executiveList = res;
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids.length > 0) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
        })
        .catch(() => {});
    },
    save() {
      this.detail.package_group_type = "web";
      if (this.detail.published_date) {
        this.detail.published_date = moment(this.detail.published_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/intresting/bites",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/intresting/bites",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showPublicationPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    addPubliction() {
      this.detail.package_group_type = "web";
      this.$api
        .postAPI({
          _action: "/publications",
          _body: this.detail,
          _buttonId: "publication-button",
        })
        .then((res) => {
          if (!this.$refs.publication_logo.file) {
            this.publicationlist();
            this.detail.publication_id = res.id;
            this.$refs.textModal.closeModal();
          } else {
            this.uploadImage(res.id);
          }
        })
        .catch(() => {});
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/publications/logo",
          _key: "image",
          _file: this.$refs.publication_logo.file,
          _body: {
            id: id,
          },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.publicationlist();
          this.detail.publication_id = res.id;
          this.$refs.textModal.closeModal();
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style scoped>
.multiselect {
  background: white !important;
  height: auto !important;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>