<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-9 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Company</h4>
          </div>
          <div class="col-3 d-flex justify-content-end">
            <div class="" v-show="accessObject?.is_add == 1">
              <a
                style="padding-left: 20px; padding-right: 20px; cursor: pointer"
                v-on:click="$router.push('/add-company')"
                class="add-btn"
                >Add</a
              >
            </div>
            <div class="ml-2" v-if="role == 'admin'">
              <a
                v-on:click="getCompanyExport()"
                style="cursor: pointer"
                class="add-btn"
                >Export</a
              >
            </div>
            <div class="ml-2">
              <a
                v-on:click="showImportPopup()"
                style="cursor: pointer"
                class="add-btn"
                >Import</a
              >
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="px-0">
              <div class="row mb-3">
                <div class="col-lg-6 col-sm-6">
                  <!-- <form class="form-search form-inline"> -->
                  <Field
                    name="keyword"
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                    style="border: 0px none; width: 100%"
                  />
                  <!-- </form> -->
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label class="label">From Date</label> -->
                  <datepicker
                    v-model="searchObj.from_date"
                    inputFormat="dd/MM/yyyy"
                    placeholder="From Date"
                    class="form-control"
                    style="background-color: white !important; height: 35px"
                    :clearable="false"
                    :upperLimit="
                      searchObj.to_date ? searchObj.to_date : new Date()
                    "
                  >
                  </datepicker>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label class="label">To Date</label> -->
                  <datepicker
                    v-model="searchObj.to_date"
                    inputFormat="dd/MM/yyyy"
                    class="form-control"
                    placeholder="To Date"
                    :clearable="false"
                    style="background-color: white !important; height: 35px"
                    :lowerLimit="
                      searchObj.from_date ? searchObj.from_date : null
                    "
                    :upperLimit="new Date()"
                  >
                  </datepicker>
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Location</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  v-model="searchObj.country_id"
                  :options="cityList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchCityName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Size</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_size"
                  :options="company_sizes"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchCompanySize($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_type"
                  :options="company_types"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-1 col-sm-3 col-5 form-group">
                <label class="label">Limit</label>
                <Field
                  name="limit"
                  type="text"
                  class="form-control"
                  placeholder="Limit"
                  v-model="searchObj.limit"
                  style="border: 1px solid black; width: 100%"
                />
                <!-- </form> -->
              </div>
              <div class="col-lg-3 col-sm-3 col-5 row mt-3">
                <label></label>
                <div class="col-lg-4 col-sm-4 col-6">
                  <button
                    v-on:click="getList(0)"
                    type="button"
                    style="margin-top: 23px"
                    class="apply-btn"
                  >
                    Apply
                  </button>
                </div>
                <div class="col-lg-4 col-sm-4 col-6">
                  <label></label>
                  <button
                    v-on:click="resetFilter()"
                    type="button"
                    style="margin-top: 23px"
                    class="apply-btn"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="card border mb-0 radius-15 ">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Company Logo</th>
                          <th>Company Name</th>
                          <th>Industry</th>
                          <th>Status</th>
                          <th>Publication Status</th>
                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>
                            <img
                              v-if="data.company_logo"
                              :src="
                                data.company_logo
                                  ? data.company_logo
                                  : '/img/user-pic.png'
                              "
                              class="user-img"
                              id="loginuser-image"
                            />
                            <img
                              v-if="!data.company_logo"
                              :src="
                                data.company_logo
                                  ? data.company_logo
                                  : '/img/company.png'
                              "
                              class="user-img"
                              id="loginuser-image"
                            />
                          </td>

                          <td>
                            {{ data.company_name }}
                          </td>
                          <td>
                            {{
                              data.industry ? data.industry.industry_name : "-"
                            }}
                          </td>
                          <td>
                            {{
                              data.is_pending == "Y" ? "Pending" : "Approved"
                            }}
                          </td>
                          <td>
                            {{
                              data.publish == "N" ? "UNPUBLISHED" : "PUBLISHED"
                            }}
                          </td>
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1 ||
                              accessObject?.is_publish == 1
                            "
                          >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              style="cursor: pointer"
                              v-on:click="goToView('/edit-company/' + data.id)"
                              >Edit</a
                            >
                            <a
                              v-if="accessObject?.is_delete == 1"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                            <a
                              v-show="
                                data.publish == 'N' &&
                                accessObject?.is_publish == 1
                              "
                              class="fill-btn cursor-pointer"
                              v-on:click="updatepublishstatus('Y', data.id)"
                              >Publish</a
                            >
                            <a
                              v-show="
                                data.publish == 'Y' &&
                                accessObject?.is_publish == 1
                              "
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="updatepublishstatus('N', data.id)"
                              >Unpublish</a
                            >
                            <a
                              v-if="
                                role == 'admin' || role == 'quality_analyst'
                              "
                              style="cursor: pointer"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="pdfView(data.id)"
                            >
                              <i class="fa fa-download" aria-hidden="true"></i
                            ></a>
                            
                            <!-- <a
                              v-if="
                                role == 'admin' || role == 'quality_analyst'
                              "
                              style="cursor: pointer"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="downloadCompanyPdf(data.id)"
                            >
                              <i class="fa fa-download" aria-hidden="true"></i
                            ></a> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
  <TextModal ref="textModal">
    <!-- <Form ref="practiceForm" @submit="uploadDocument()"> -->
    <div class="modal-header">
      <h5 class="modal-title">Import Company</h5>
      <button
        type="button"
        class="close"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-lg-12 col-sm-12 mb-3">
        <div class="text-center mb-4">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile04"
                v-on:change="onChange($event)"
              />
              <label
                class="custom-file-label"
                style="text-align: left"
                for="inputGroupFile04"
                >{{ docFile ? docFile.name : "Choose File" }}</label
              >
              <!-- <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
                <label class="custom-file-label" for="inputGroupFile04">Choose file</label> -->
            </div>
          </div>
          <p>
            <a href="" v-on:click="downloadTemplate">Download Template </a>
            file and fill with the items information.
          </p>
          <div id="msg"></div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        id="cancle-button"
        class="btn btn-secondary position-relative w-auto px-4"
        data-dismiss="modal"
        v-on:click="$refs.textModal.closeModal"
      >
        Close
      </button>
      <button
        v-on:click="uploadDocument()"
        id="document-button"
        type="button"
        class="btn btn-primary position-relative w-auto mt-0 mb-0 px-4"
      >
        Import
      </button>
    </div>

    <!-- </Form> -->
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
import Multiselect from "@vueform/multiselect";
import datepicker from "vue3-datepicker";
import { Field } from "vee-validate";

export default {
  name: "Package Group",
  components: {
    ErrorComponent,
    DeleteModal,
    Field,
    Multiselect,
    datepicker,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Company"),
      loader: true,
      docFile: undefined,
      role: localStorage.getItem("role"),
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      company_sizes: [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001-10000",
        "10000+",
      ],
      searchObj: {
        from_date: "",
        to_date: "",
        type: "",
        keyword: "",
        industry_id: "",
        company_size: "",
        company_type: "",
        country_id: "",
        limit: "",
      },
      offset: -1,
      list: [],
      cityList: [],
      industryList: [],
      industryKeyword: "",
      cityKeyword: "",
      companySizeKeyword: "",
      companyTypeKeyword: "",
    };
  },
  mounted() {
    this.getList(0);
    this.getindustrylist();
    this.CityList();
  },
  methods: {
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    updatepublishstatus(status, id) {
      var obj = {};
      obj.id = id;
      obj.publish = status;
      this.$api
        .putAPI({
          _action: "/update-company-publish",
          _body: obj,
        })
        .then(() => {
          this.getList(0);
        })
        .catch(() => {});
    },
    searchCompanyType(e) {
      this.companyTypeKeyword = e;
      this.industrylist();
    },
    searchCompanySize(e) {
      this.companySizeKeyword = e;
      this.industrylist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    getindustrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    downloadCompanyPdf(id) {
      if (id) {
        var url = this.$api.getBaseUrl() + "/company/getCompanyPdf?id=" + id;
        window.open(url);
      }
    },
    goToView(url) {
      this.$shareService.setMapValue("company_page_filter", this.searchObj);
      this.$router.push(url);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      if (this.offset != page) {
        this.searchObj.offset = page;
      } else {
        this.searchObj.offset = page;
      }
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }

      if (!this.searchObj.industry_id) {
        this.searchObj.industry_id = "";
      }
      if (!this.searchObj.company_size) {
        this.searchObj.company_size = "";
      }
      if (!this.searchObj.country_id) {
        this.searchObj.country_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (!this.searchObj.limit) {
        this.searchObj.limit = "";
      }
      if (!this.searchObj.from_date) {
        this.searchObj.from_date = "";
      }

      if (!this.searchObj.to_date) {
        this.searchObj.to_date = "";
      }
      if (this.searchObj.from_date) {
        this.searchObj.start_date = moment(this.searchObj.from_date).format(
          "DD-MM-YYYY"
        );
      }
      if (this.searchObj.to_date) {
        this.searchObj.end_date = moment(this.searchObj.to_date).format(
          "DD-MM-YYYY"
        );
      }

      var filter_obj = this.$shareService.getMapValue("company_page_filter")
        ? this.$shareService.getMapValue("company_page_filter")
        : null;
      if (filter_obj) {
        this.searchObj = filter_obj;
        this.$shareService.getMapValueAndRemove("company_page_filter");
      }
      this.$api
        .getAPI({
          _action: "/company/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Company");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(
                  this.searchObj.offset + 1
                );
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No company list available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.keyword = "";
      this.searchObj.industry_id = "";
      this.searchObj.company_size = "";
      this.searchObj.company_type = "";
      this.searchObj.country_id = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Company",
        "Are you sure you want to delete",
        data
      );
    },
    uploadDocument() {
      if (this.docFile) {
        this.$api
          .uploadImageAPI({
            _action: "/company/import/data",
            _key: "file",
            _file: this.docFile,
            _buttonId: "document-button",
          })
          .then((res) => {
            // window.open(this.$api.getBaseUrl() + res.url);
            // // window.open("https://apps.grademybeauty.com/api" + res.url)
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getList(0);
            this.$refs.textModal.closeModal();
          });
      } else {
        this.$refs.textModal.closeModal();
      }
    },
    onChange(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "application/pdf" &&
      //   files[0].type.toLowerCase() != "application/doc"
      // ) {
      //   alert("Invalid file formate, please use pdf file.");
      //   return;
      // }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
      console.log(this.docFile);
    },
    getCompanyExport() {
      var obj = {};

      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      } else {
        obj.from_date = "";
      }

      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      } else {
        obj.to_date = "";
      }
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      } else {
        obj.keyword = "";
      }
      if (this.searchObj.industry_id) {
        obj.industry_id = this.searchObj.industry_id;
      } else {
        obj.industry_id = "";
      }
      if (this.searchObj.company_size) {
        obj.company_size = encodeURIComponent(this.searchObj.company_size);
      } else {
        obj.company_size = "";
      }
      if (this.searchObj.company_type) {
        obj.company_type = this.searchObj.company_type;
      } else {
        obj.company_type = "";
      }
      if (this.searchObj.country_id) {
        obj.country_id = this.searchObj.country_id;
      } else {
        obj.country_id = "";
      }
      if (this.searchObj.type) {
        obj.type = this.searchObj.type;
      } else {
        obj.type = "";
      }
      if (this.searchObj.limit) {
        obj.limit = this.searchObj.limit;
      } else {
        obj.limit = "";
      }
      // var url = this.$api.getBaseUrl() + "/company/export/data";

      var url =
        this.$api.getBaseUrl() +
        "/company/export/data?keyword=" +
        obj.keyword +
        "&start_date=" +
        obj.from_date +
        "&end_date=" +
        obj.to_date +
        "&industry_id=" +
        obj.industry_id +
        "&company_size=" +
        obj.company_size +
        "&company_type=" +
        obj.company_type +
        "&country_id=" +
        obj.country_id +
        "&type=" +
        obj.type +
        "&limit=" +
        obj.limit;
      console.log(url);
      window.open(url);
    },
    showImportPopup() {
      this.docFile = undefined;
      this.$refs.textModal.showModal();
    },
    downloadTemplate() {
      var url = "company-file.csv";
      window.open(this.$api.getBaseUrl().replaceAll("api/admin", "") + url);
    },
    pdfView(company_id) {
      this.$router.push("/preview-pdf/" + company_id);
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/company/" + detail.id);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
